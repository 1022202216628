import dayjs from 'dayjs'
import { colors } from '@bushelpowered/design-system'
import { Badge, Stack, Text } from 'src/components/designsystem'
import { renderOrEmDash } from 'src/components/resource/helpers'

type PaymentStatus =
  | 'UNPAID'
  | 'DEFERRED'
  | 'PAID'
  | 'PARTIAL'
  | 'PENDING'
  | 'UNKNOWN'
  | 'PROCESSED'
  | 'COMPLETED'

const badgeColorMap: Partial<Record<keyof typeof colors, PaymentStatus[]>> = {
  yellow: ['PENDING', 'PARTIAL', 'DEFERRED'],
  red: ['UNPAID'],
  green: ['PAID', 'PROCESSED', 'COMPLETED'],
  gray: ['UNKNOWN'],
}

const getColorScheme = (status: string) =>
  Object.entries(badgeColorMap).find(([_key, badgeColor]) =>
    badgeColor.some((badgeStatus) => badgeStatus.toLowerCase() === status.toLowerCase())
  )?.[0] ?? 'gray'

type PaymentStatusBadgeProps = Readonly<{
  status?: Payment['status'] | null
  timestamp?: Date
}>

export default function PaymentStatusBadge({ status, timestamp }: PaymentStatusBadgeProps) {
  if (!status) return null
  const colorScheme = getColorScheme(status)
  const displayStatus = renderOrEmDash({ value: status })

  return (
    <Stack>
      <div>
        <Badge {...{ colorScheme }} maxW="100%" overflow="hidden" isTruncated title={displayStatus}>
          {displayStatus}
        </Badge>
      </div>
      {timestamp && (
        <Text fontSize="sm" pb={1}>
          {dayjs(timestamp).format('L LT')}
        </Text>
      )}
    </Stack>
  )
}
