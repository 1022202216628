import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { aggregatorService, HTTPError } from 'src/api'

export function fileExtensionToMediaType(
  extension: string
): StaffCustomPageCreateFileRequest['sourceMediaType'] {
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'jpeg'
    case 'png':
      return 'png'
    case 'gif':
      return 'gif'
    case 'webp':
      return 'webp'
    case 'svg':
      return 'svg'
    case 'pdf':
      return 'pdf'
    default:
      throw new Error(`Unsupported file type`)
  }
}

export const STAFF_CUSTOM_PAGES_MUTATION_KEY = ['custom-pages', 'files', 'staff']

export default function useStaffCreateCustomPageFile(
  options?: UseMutationOptions<
    StaffCreateCustomPageFileResponse,
    HTTPError,
    StaffCustomPageCreateFileRequest
  >
) {
  return useMutation({
    mutationKey: STAFF_CUSTOM_PAGES_MUTATION_KEY,
    mutationFn: aggregatorService.staffCreateCustomPageFile,
    meta: {
      errorMessage: 'Unable to upload file page, please try again.',
    },
    ...options,
  })
}
