import { useCallback } from 'react'
import { useConfig } from 'src/data/config'
import { useReportIssueStore } from 'src/components/report-issue/helpers'
import { Button, ChakraLink, Divider, Text, VStack } from 'src/components/designsystem'
import { Company } from 'src/api/centre-service/data-models/company'
import { NavigateToExternalLink } from 'src/components/utils/NavigateToExternalLink'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'
import { formatPhoneNumber } from 'src/utils'

export function Help() {
  const { config, getConfigValue } = useConfig()
  const { company } = config || {} // need this to handle no-company case where there is no config
  const resourceLinks = getConfigValue('resource_links')
  const { open: openReportIssue } = useReportIssueStore()
  const { closeRightSidebar } = useRightSidebarStore()

  const onClickHandler = useCallback(() => {
    closeRightSidebar()
    openReportIssue()
  }, [openReportIssue, closeRightSidebar])

  return (
    <>
      <VStack my={6} spacing={5} alignItems="flex-start">
        <Text
          textStyle="preTitle"
          color="gray.500"
          pl={6}
          data-testid="help-and-support-support-subheader"
        >
          Support
        </Text>
        <Button
          w="full"
          pl={6}
          justifyContent="initial"
          variant="secondary"
          bgColor="initial"
          fontWeight="normal"
          borderRadius="none"
          onClick={onClickHandler}
          data-testid="report-an-issue-button"
        >
          Report an issue
        </Button>
      </VStack>

      {company && (
        <>
          <Divider />
          <VStack my={6} spacing={5} pl={6} alignItems="flex-start">
            <Text
              textStyle="preTitle"
              color="gray.500"
              data-testid="help-and-support-contact-subheader"
            >
              Contact
            </Text>

            <ContactInfo company={company} />
          </VStack>
        </>
      )}

      {resourceLinks && resourceLinks.length > 0 && (
        <>
          <Divider />
          <VStack my={6} spacing={5} pl={6} alignItems="flex-start">
            <Text textStyle="preTitle" color="gray.500">
              Resources
            </Text>
            <VStack spacing={4} alignItems="flex-start">
              {resourceLinks.map((resource) => {
                return (
                  <NavigateToExternalLink key={resource.display_text} href={resource.link}>
                    {resource.display_text}
                  </NavigateToExternalLink>
                )
              })}
            </VStack>
          </VStack>
        </>
      )}
    </>
  )
}

export function ContactInfo({ company }: { company: Company }) {
  return (
    <VStack spacing={0.5} alignItems="flex-start">
      <Text data-testid="help-and-support-contact-company-name">{company?.company_name}</Text>
      {company?.phone && (
        <ChakraLink href={`tel:${company?.phone}`} data-testid="help-and-support-contact-phone">
          {formatPhoneNumber(company?.phone)}
        </ChakraLink>
      )}
      {company?.website && (
        <NavigateToExternalLink
          href={company?.website}
          data-testid="help-and-support-contact-website"
        >
          {company?.website}
        </NavigateToExternalLink>
      )}
      {company?.email && (
        <ChakraLink
          href={`mailto:${company?.email}`}
          isExternal
          color="blue.400"
          data-testid="help-and-support-contact-email"
        >
          {company?.email}
        </ChakraLink>
      )}
    </VStack>
  )
}
