import { useCallback } from 'react'
import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNext,
} from 'src/components/designsystem'

type PublishConfirmationModalProps = Readonly<
  {
    message: string
    onPublishAction: () => void
  } & Omit<React.ComponentProps<typeof ModalNext>, 'children'>
>

/**
 * Modal that will prompt the user to confirm the action of 'publishing',
 * upon confirmation the onPublishAction will be executed
 * @param message - message to be displayed in the modal
 * @param onPublishAction - action to be executed on confirmation
 */
export default function PublishConfirmationModal({
  message,
  onPublishAction,
  onClose,
  ...props
}: PublishConfirmationModalProps) {
  const onPublish = useCallback(() => {
    onPublishAction()
    onClose()
  }, [onClose, onPublishAction])

  return (
    <ModalNext isCentered onClose={onClose} {...props}>
      <ModalHeader>Ready to publish?</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="secondary" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onPublish}>Publish</Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalNext>
  )
}
