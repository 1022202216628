import { Box, Skeleton, Stack } from 'src/components/designsystem'
import SectionHeading from './SectionHeading'

export default function CardSectionSkeleton({
  height = '166px',
  width = '100%',
  withHeading = true,
}: Readonly<{ height?: string; width?: string; withHeading?: boolean }>) {
  return (
    <Stack gap={3}>
      {withHeading && <SectionHeading isLoading>Heading</SectionHeading>}
      <Stack gap={4}>
        <Skeleton aria-label="Loading..." isLoaded={false} rounded="lg">
          <Box w={width} h={height} />
        </Skeleton>
        <Skeleton aria-label="Loading..." isLoaded={false} rounded="lg">
          <Box w={width} h={height} />
        </Skeleton>
        <Skeleton aria-label="Loading..." isLoaded={false} rounded="lg">
          <Box w={width} h={height} />
        </Skeleton>
      </Stack>
    </Stack>
  )
}
