import { useAuth } from 'src/auth'
import {
  Box,
  Button,
  Divider,
  PersonOutlineIcon,
  Text,
  Tooltip,
  VStack,
} from 'src/components/designsystem'
import { NavigateToExternalLink } from 'src/components/utils/NavigateToExternalLink'
import { ENV, formatPhoneNumber } from 'src/utils'
import getClient from 'src/utils/clients/get-client'
import { useNativeAppVersion } from 'src/components/utils/use-native-app-version'

export function Account() {
  const { slug, user } = useAuth()
  const nativeAppVersion = useNativeAppVersion()

  return (
    <Box textAlign="center">
      <VStack my={6} spacing={3}>
        <PersonOutlineIcon color="gray.500" fontSize="64px" />
        <Text>{formatPhoneNumber(user?.phone_numbers?.data?.[0]?.phone)}</Text>
        <UserEmail user={user} />
      </VStack>

      <Button
        as="a"
        href={`${ENV.OIDC_KC_URL}/realms/bushel/account?referrer=${ENV.OIDC_CLIENT_ID}`}
        variant="link"
        target="_blank"
        mt={2}
        mb={10}
      >
        Enable multi factor authentication
      </Button>

      <Divider />
      <SignOutOfThisDevice />
      <Divider />
      <SignOutOfAllDevices />
      <Divider />
      <Text fontSize="sm" py={4}>
        <NavigateToExternalLink
          href={`${ENV.CENTRE_API}/api/v1/legal/terms?app-company=${slug}`}
          mr={2.5}
          data-testid="terms-and-conditions-account-link"
        >
          Terms &amp; Conditions
        </NavigateToExternalLink>
        &bull;
        <NavigateToExternalLink
          href={`${ENV.CENTRE_API}/api/v1/legal/privacy/?app-company=${slug}`}
          ml={2.5}
          data-testid="privacy-policy-account-link"
        >
          Privacy Policy
        </NavigateToExternalLink>
        <br />
        {getClient().isNativeApp && nativeAppVersion}
      </Text>
    </Box>
  )
}

function SignOutOfThisDevice() {
  const { handleNextAuthSignOut } = useAuth()

  return (
    <Button variant="primary" my={6} onClick={handleNextAuthSignOut}>
      Sign out of this device
    </Button>
  )
}

function SignOutOfAllDevices() {
  const { signOutOfAllDevices } = useAuth()

  return (
    <Button variant="primary" my={6} onClick={signOutOfAllDevices}>
      Sign out of all devices
    </Button>
  )
}

function formatEmailLength(email: string) {
  if (email?.length > 35) {
    const username = email.substr(0, email.indexOf('@'))
    const domain = email.substr(email.indexOf('@'), email.length)
    const firstChars = username.slice(0, 3)
    const lastChars = username.slice(username.length - 3, username.length)

    return `${firstChars}...${lastChars}${domain}`
  }
  return email
}

function UserEmail({ user }: { user: Me }) {
  const email = user?.email_addresses?.data?.[0]?.email

  if (!email) return null

  return (
    <Tooltip label={email}>
      <Text>{formatEmailLength(email)}</Text>
    </Tooltip>
  )
}
