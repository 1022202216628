import { Button } from '@bushelpowered/design-system'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useAuth } from 'src/auth'
import BackButton from 'src/components/shared/BackButton'
import { Box, GenericNonIdealState } from 'src/components/designsystem'
import { LoadingState } from 'src/components/resource'
import { useNavigation } from 'src/data'
import { useGetStaffCustomPageById } from 'src/data/queries/custom-pages'
import { CUSTOM_PROGRAMS_PAGE_TITLE } from 'src/features/staff'
import CustomPageForm from './CustomPageForm'

export default function StaffCustomPageFormPage() {
  const router = useRouter()
  const { id } = router.query

  useNavigation({
    pageTitle: `${id ? 'Edit' : 'New'} Custom Page`,
    activeItem: CUSTOM_PROGRAMS_PAGE_TITLE,
    analyticsTitle: CUSTOM_PROGRAMS_PAGE_TITLE,
  })

  const { slug } = useAuth()

  const { data, isLoading, isError } = useGetStaffCustomPageById(id as string)

  if (isLoading) {
    return <LoadingState mt={15} />
  }

  if (isError) {
    return (
      <GenericNonIdealState
        isVerticallyCentered
        illustration="tumbleweed"
        illustrationAltText="Custom Page not found"
        header="Custom Page not found"
        subHeader="We were unable to find this custom page."
        action={
          <Button as={Link} href={`/${slug}/staff/custom-pages`} prefetch={false} variant="primary">
            Go to custom pages
          </Button>
        }
      />
    )
  }

  // MB on mobile to account for bottom action bar. If it has not been published, there are 3 buttons in a column, so we need more room. 2 buttons are side-by-side.
  return (
    <Box mb={[data?.publishedAt ? '80px' : '200px', 0]}>
      <BackButton onClick={router.back} />
      <CustomPageForm customPageDetail={data} />
    </Box>
  )
}
