import Link from 'next/link'
import { useAuth } from 'src/auth'
import { AddIcon, Button, type ButtonProps, HStack, Spacer } from 'src/components/designsystem'

export function NewPageButton(props: Readonly<ButtonProps>) {
  const { slug } = useAuth()
  return (
    <Button
      as={Link}
      variant="outline"
      href={[null, slug, 'staff', 'custom-pages', 'create'].join('/')}
      size={{ base: 'sm', md: 'md' }}
      leftIcon={<AddIcon />}
      {...props}
    >
      New Page
    </Button>
  )
}

export default function Header({ hideNewButton }: Readonly<{ hideNewButton?: boolean }>) {
  return (
    <HStack h={10} mt={[2, null, 4, 8]} pr={{ base: 2, md: 0 }}>
      <Spacer />
      {!hideNewButton && <NewPageButton />}
    </HStack>
  )
}
