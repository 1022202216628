import { HTTPError } from 'ky'
import { paymentsCompanyServiceClient } from './client'

export interface PaymentsCompanyWalletInfo {
  companyId: string
  remoteCompanyId: string
  companyName: string
  companySlug: string
  countryCode: string
  bushelId: string
}

export interface PaymentsInitializeCompanyUserSuccess {
  companyUserId: string
  remoteCompanyId: string
  companyId: string
  elevatorId: string
  userId: string
  accountName: string
  countryCode: string
}

export interface PaymentsInitializeCompanyUserAlreadyExists {
  status: 409
  message: 'Company user already exists on the database'
  code: 21
  errorName: 'COMPANY_USER_ALREADY_EXISTS'
}

export type PaymentsInitializeCompanyUserResponse =
  | PaymentsInitializeCompanyUserSuccess
  | PaymentsInitializeCompanyUserAlreadyExists

export interface PaymentsInitializeCompanySuccess {
  companyId: string
  remoteCompanyId: string
  companyName: string
  companySlug: string
  countryCode: string
  countryName: string
}

export interface PaymentsInitializeCompanyAlreadyExists {
  status: 409
  message: 'Company already exists on the database'
  code: 21
  errorName: 'COMPANY_ALREADY_EXISTS'
}

export type PaymentsInitializeCompanyResponse =
  | PaymentsInitializeCompanySuccess
  | PaymentsInitializeCompanyAlreadyExists

export type InitializeCompanyUserRequest = {
  companyUserId: string
  companyId: string
  elevatorId: string | null
  userId: string
  accountName: string
  countryCode: string
}

export type InitializeCompanyRequest = {
  companyId: string
  companyName: string
  companySlug: string
  countryCode: string
  countryName: string
}

export const paymentsCompanyRequests = {
  paymentsPaymentCompanyWalletInfo: async (slug: string) =>
    paymentsCompanyServiceClient.get(`v1/company/${slug}`).json<PaymentsCompanyWalletInfo>(),
  paymentsInitializeCompanyUser: async (
    body: InitializeCompanyUserRequest
  ): Promise<PaymentsInitializeCompanyUserResponse> => {
    try {
      const response = await paymentsCompanyServiceClient.post(`v1/company/user`, { json: body })
      return response.json<PaymentsInitializeCompanyUserResponse>()
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 409) {
        // We can safely curry the response here because we know it's a 409
        return error.response.payload as PaymentsInitializeCompanyUserAlreadyExists
      }
      throw error
    }
  },
  paymentsInitializeCompany: async (
    body: InitializeCompanyRequest
  ): Promise<PaymentsInitializeCompanyResponse> => {
    try {
      const response = await paymentsCompanyServiceClient.post(`v1/company/information`, {
        json: body,
      })
      return response.json<PaymentsInitializeCompanyResponse>()
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 409) {
        // We can safely curry the response here because we know it's a 409
        return error.response.payload as PaymentsInitializeCompanyAlreadyExists
      }
      throw error
    }
  },
}
