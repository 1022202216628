import { colors } from '@bushelpowered/design-system'
import {
  Style,
  statusBarPluginWrapper,
} from 'src/utils/clients/native/wrappers/status-bar-plugin-wrapper'
import getClient from 'src/utils/clients/get-client'

export const nativeStatusBar = {
  style,
}

async function style() {
  await statusBarPluginWrapper.setStyle({ style: Style.Light })

  if (getClient().isAndroid) {
    await statusBarPluginWrapper.setBackgroundColor({ color: colors.white })
  }
}
