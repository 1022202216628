import { BackArrow, Button, type ButtonProps, forwardRef } from 'src/components/designsystem'

type BackButtonProps = {
  backButtonText?: string
} & ButtonProps

const BackButton = forwardRef<BackButtonProps, typeof Button>(
  ({ backButtonText = 'Back', ...props }, ref) => (
    <Button
      ref={ref}
      variant="link"
      data-testid="back-button"
      fontSize="initial"
      px={2}
      my={7}
      leftIcon={<BackArrow fontSize="md" />}
      {...props}
    >
      {backButtonText}
    </Button>
  )
)

export default BackButton
