import { PropsWithChildren } from 'react'
import { SessionProvider } from 'next-auth/react'
import { AuthContext, AuthProvidersProps } from 'src/auth/auth-context'
import ENV from 'src/utils/env'
import { logger } from 'src/utils/logger'
import { base64DecodeString } from 'src/utils/string/base64'

export const TOKEN_EXPIRY_REFRESH_SECS = 60
export const TOKEN_REFRESH_ERROR_CODE = 'RefreshAccessTokenError'

/* istanbul ignore next: difficult to test */
export function AuthProviders({
  session = undefined,
  authContext,
  children,
}: PropsWithChildren<AuthProvidersProps>) {
  return (
    <AuthContext.Provider value={authContext}>
      <SessionProvider session={session}>{children}</SessionProvider>
    </AuthContext.Provider>
  )
}

export async function logOutKeycloakSession(idToken?: string) {
  if (!idToken) return true

  try {
    // Destroy user's Keycloak session
    // See: https://stackoverflow.com/a/75484849
    // Make request to:
    //   https://id.dev.bushelops.com/auth/realms/bushel/protocol/openid-connect/logout?id_token_hint=$token
    await fetch(
      `${ENV.OIDC_KC_URL}/realms/bushel/protocol/openid-connect/logout?` +
        new URLSearchParams({ id_token_hint: idToken }).toString()
    )

    return true
  } catch (error) {
    logger.error({
      message: 'src/auth/keycloak - failed to perform provider-logout',
      error,
    })
  }

  return false
}

interface SsoBridgeToken {
  id: string
  expiresAt: string
}

export async function getSsoBridgeUrl(token: string, redirectUrl: string): Promise<string> {
  try {
    const ssoBridgeToken = await getSsoBridgeToken(token)
    return `${ENV.OIDC_KC_URL}/realms/bushel/sso-bridge/session?bridgeId=${ssoBridgeToken}&redirect=${redirectUrl}`
  } catch (error) {
    return Promise.reject(new Error(`Error retrieving SSO bridge token.`))
  }
}

async function getSsoBridgeToken(token: string): Promise<string> {
  const url = `${ENV.OIDC_KC_URL}/realms/bushel/sso-bridge/token`
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  })

  const data = (await response.json()) as SsoBridgeToken
  return data.id
}

export async function isForceLogoutResponse(response: Response) {
  if (response.status !== 403) return false

  try {
    response.payload = await (response.bodyUsed ? response.payload : response.json())

    if (response.payload?.error?.code === 'LOG_OUT') {
      return true
    }
  } catch (err) {
    /* ignore and try to fallthrough */
  }

  return false
}

export function parseJwt(token: string) {
  const base64Payload = token?.split('.')?.[1]

  if (!base64Payload) return {}

  return JSON.parse(base64DecodeString(base64Payload))
}

export function getSecondsUntilTokenExpires(tokenExp: number) {
  const nowEpoch = Date.now()
  const expiresEpoch = new Date(tokenExp * 1000).getTime()

  // .now() and .getTime() both return epoch in milliseconds
  return Math.round((expiresEpoch - nowEpoch) / 1000)
}
