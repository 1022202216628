import { useFormContext } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from 'src/components/designsystem'
import {
  type CustomPageFormValues,
  MAX_PARAGRAPH_LENGTH,
  MAX_TITLE_LENGTH,
} from './useStaffCustomPageForm'

export default function ParagraphFormCard({
  index,
  onClear,
}: Readonly<{
  index: number
  onClear?: () => void
}>) {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<CustomPageFormValues>()

  const paragraphError = errors.paragraphs?.[index]

  const paragraphValue = watch(`paragraphs.${index}`)

  return (
    <Card variant="outline">
      <CardBody>
        <Stack gap={6}>
          <FormControl isInvalid={!!paragraphError?.title} isRequired={index === 0}>
            <FormLabel>Title</FormLabel>
            <Input {...register(`paragraphs.${index}.title`)} placeholder="Enter paragraph title" />
            <FormHelperText>{`${paragraphValue.title?.length}/${MAX_TITLE_LENGTH}`}</FormHelperText>
            <FormErrorMessage>{paragraphError?.title?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!paragraphError?.description} isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              {...register(`paragraphs.${index}.description`)}
              placeholder="Enter description of program"
              rows={4}
            />
            <FormHelperText>{`${paragraphValue.description.length}/${MAX_PARAGRAPH_LENGTH}`}</FormHelperText>
            <FormErrorMessage>{paragraphError?.description?.message}</FormErrorMessage>
          </FormControl>
          <Button variant="link" alignSelf="flex-start" ml={4} onClick={onClear}>
            {index === 0 ? 'Clear' : 'Remove'}
          </Button>
        </Stack>
      </CardBody>
    </Card>
  )
}
