import { Heading } from '@bushelpowered/design-system'
import { useController } from 'react-hook-form'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from 'src/components/designsystem'
import SecurityToggle from 'src/components/shared/SecurityToggle'

const MAX_TITLE_LENGTH = 25

export default function SetupSection() {
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'title' })

  return (
    <Stack gap={6}>
      <Heading type="h6">Setup</Heading>
      <FormControl isInvalid={!!error?.message} isRequired>
        <FormLabel>Page Title</FormLabel>
        <Input {...field} placeholder="Enter page title as it will appear in navigation" />
        <FormHelperText>{`${field.value?.length ?? '0'}/${MAX_TITLE_LENGTH}`}</FormHelperText>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
      <SecurityToggle />
    </Stack>
  )
}
