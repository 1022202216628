import { bidlClient } from 'src/api/bidl/client'
import { businessPagesBaseUrl } from './business-locations'

export const staffGetCustomPagesUrl = `${businessPagesBaseUrl}/v1/StaffGetCustomPages`
export const staffGetCustomPageByIdUrl = `${businessPagesBaseUrl}/v1/StaffGetCustomPageById`
export const staffCreateCustomPageUrl = `${businessPagesBaseUrl}/v1/StaffCreateCustomPage`
export const staffUpdateCustomPageUrl = `${businessPagesBaseUrl}/v1/StaffUpdateCustomPage`
export const staffDeleteCustomPageUrl = `${businessPagesBaseUrl}/v1/StaffDeleteCustomPage`
export const staffCreateCustomPageFileUrl = `${businessPagesBaseUrl}/v1/StaffCreateCustomPageFile`

export const getCustomPageByIdUrl = `${businessPagesBaseUrl}/v1/GetCustomPageById`

export const customPagesRequests = {
  // Staff
  listStaffCustomPages: () =>
    bidlClient.post(staffGetCustomPagesUrl, { json: {} }).json<StaffCustomPagesListResponse>(),
  getStaffCustomPageById: (id: string) =>
    bidlClient
      .post(staffGetCustomPageByIdUrl, { json: { id } })
      .json<StaffCustomPageByIdResponse>(),
  staffCreateCustomPage: (data: StaffCustomPageCreateRequest) =>
    bidlClient.post(staffCreateCustomPageUrl, { json: data }).json<StaffCustomPageByIdResponse>(),
  staffUpdateCustomPage: (id: string, data: StaffCustomPageCreateRequest) =>
    bidlClient
      .post(staffUpdateCustomPageUrl, { json: { id, ...data } })
      .json<StaffCustomPageByIdResponse>(),
  staffDeleteCustomPage: (id: string) =>
    bidlClient
      .post(staffDeleteCustomPageUrl, { json: { id } })
      .json<StaffGetQuotesSuccessResponse>(),
  staffCreateCustomPageFile: (data: StaffCustomPageCreateFileRequest) =>
    bidlClient
      .post(staffCreateCustomPageFileUrl, { json: data })
      .json<StaffCreateCustomPageFileResponse>(),

  // Customer
  getCustomPageById: (id: string) =>
    bidlClient.post(getCustomPageByIdUrl, { json: { id } }).json<StaffCustomPageByIdResponse>(),
}
