import type { BushelPaymentFiltersForQuery } from 'src/data/queries/payments/useBushelPayments'
import { paymentsPaymentsServiceClient } from './client'
import buildPaymentSearchParams, {
  PaymentsSearchParams,
} from 'src/api/payments-service/buildPaymentSearchParams'

interface PaymentsRawResponse {
  elements: Payment[]
  pagination: {
    page: number
    size: number
    totalRecords: number
  }
  isLoading: boolean
  isError: boolean
  error: object
  meta: {
    last_updated: string
  }
}

interface DeferredPaymentsRawResponse {
  elements: DeferredPayment[]
  pagination: {
    page: number
    size: number
    totalRecords: number
  }
  isLoading: boolean
  isError: boolean
  error: object
  meta: {
    last_updated: string
  }
}

export interface DeferredPaymentsResponse {
  data: DeferredPayment[]
  meta: {
    pagination: Pagination
  }
}

export interface PaymentsResponse {
  data: Payment[]
  isLoading: boolean
  isError: boolean
  error: object
  meta: {
    last_updated: string
    pagination: Pagination
  }
}

export interface PaymentSummaryResponse {
  totalDebits?: string
  totalCredits?: string
}

export interface ExternalPaymentReponse {
  deferred: {
    totalDebits: string
    totalCredits: string
  }
  periods: ExternalPaymentSummaryRow[]
}

export interface ExternalPaymentSummaryRow {
  period: string
  totalDebits: string
  totalCredits: string
}

export interface PaymentFormBody {
  edit?: boolean
  id?: string
  deferred?: boolean
  deferredDate?: string
  destinationId?: string
  fundingSourceId?: string
  currency?: string
  amount?: string
  memo?: string
  sourceDisplayName?: string
  destinationDisplayName?: string
  invoices?: {
    id: string
    displayId?: string
    amount: number
    memo: string
  }[]
}

export interface MakePaymentResponse {
  paymentId: string
  sourceUserId: string
  targetUserId: string
  date: string
  status: string
  metadata?: {
    invoiceId?: string
  }
}

export interface SettlementPaymentFormBody {
  destinationId: string
  currency: string
  amount: string | number
  memo: string
  settlement: PaymentSettlement
  sourceDisplayName: string
  destinationDisplayName: string
  deferred: boolean // true
  deferredDate?: string // '2023-09-07'
  processDeferredPayment: boolean // true
}

/**
 * TODO: Recommend the alteration of the payments methods to not combine both one time and invoice payments
 * This type is created while recognizing that there is already a type called PaymentFormBody (see above).
 * The goal of this type is to separate the responsibilities of the two payment methods into more recognizable types.
 */
export interface OneTimePaymentFormBody {
  destinationId: string
  currency: string
  amount: string
  memo: string
  sourceDisplayName: string
  destinationDisplayName: string
  deferred?: boolean // true
  deferredDate?: string // '2023-09-25'
  processDeferredPayment?: boolean // true
  fundingSourceId?: string
}

export interface SettlementPaymentResponse extends Omit<MakePaymentResponse, 'metadata'> {
  transferId: string // '1f4ca64e367174f92fcf3282b5644'
}

export const paymentsPaymentsRequests = {
  paymentList: async ({
    page = null,
    size = null,
    filter = null,
    sort = null,
  }: PaymentsSearchParams) => {
    const searchParams = buildPaymentSearchParams({ page, size, filter, sort })
    const rawResponse = await paymentsPaymentsServiceClient
      .get('v1/payments', { searchParams })
      .json<PaymentsRawResponse>()
    return {
      data: rawResponse.elements,
      meta: {
        pagination: {
          total: rawResponse.pagination.totalRecords,
          count: rawResponse.elements.length,
          per_page: rawResponse.pagination.size,
          current_page: page || 1,
          total_pages: Math.ceil(rawResponse.pagination.totalRecords / rawResponse.pagination.size),
          links: {},
        },
      },
    } as PaymentsResponse
  },

  deferredPaymentList: async ({
    page = null,
    size = null,
    filter = null,
    sort = null,
  }: PaymentsSearchParams) => {
    const searchParams = buildPaymentSearchParams({ page, size, filter, sort }, 'v1')
    const rawResponse = await paymentsPaymentsServiceClient
      .get('v1/payments/deferred', { searchParams })
      .json<DeferredPaymentsRawResponse>()

    return {
      data: rawResponse.elements,
      meta: {
        pagination: {
          total: rawResponse.pagination.totalRecords,
          count: rawResponse.elements.length,
          per_page: rawResponse.pagination.size,
          current_page: typeof page === 'object' && page?.page ? page.page : 1,
          total_pages: Math.ceil(rawResponse.pagination.totalRecords / rawResponse.pagination.size),
          links: {},
        },
      },
    } as DeferredPaymentsResponse
  },

  deferredPaymentDetail: ({ id }) => {
    return paymentsPaymentsServiceClient
      .get(`v1/payments/deferred/${id}`)
      .json<DeferredPaymentDetail>()
  },

  updateDeferredPayment: ({ id, body }) => {
    return paymentsPaymentsServiceClient
      .put(`v1/payment/deferred/${id}`, { json: body })
      .json<null>()
  },

  deleteDeferredPayment: ({ id }) => {
    return paymentsPaymentsServiceClient.delete(`v1/payments/deferred/${id}`).json<null>()
  },

  paymentSummary: async (filter, summaryDates?) => {
    const searchParams = buildPaymentSearchParams({ ...filter, summaryDates })

    const rawResponse = await paymentsPaymentsServiceClient
      .get('v1/payments/summary', {
        searchParams,
      })
      .json<PaymentSummaryResponse>()
    return rawResponse
  },

  externalPaymentSummary: (filter, summaryDates?) => {
    const searchParams = buildPaymentSearchParams({ ...filter, summaryDates })

    return paymentsPaymentsServiceClient
      .get('v2/payments/summary', {
        searchParams,
      })
      .json<ExternalPaymentReponse>()
  },

  paymentListCsv: (filter) => {
    const searchParams = buildPaymentSearchParams({ filter })
    const response = paymentsPaymentsServiceClient.get('v1/payments/csv', { searchParams })

    return response
  },

  makePayment: async (paymentData: PaymentFormBody) => {
    if (paymentData.invoices) {
      return paymentsPaymentsServiceClient
        .post('v1/payment/invoice', {
          json: paymentData,
        })
        .json<MakePaymentResponse>()
    }

    return paymentsPaymentsServiceClient
      .post('v1/payment', {
        json: paymentData,
      })
      .json<MakePaymentResponse>()
  },
  makeSettlementPayment: async (settlementData: SettlementPaymentFormBody) =>
    paymentsPaymentsServiceClient
      .post('v1/payment/settlement', { json: settlementData })
      .json<SettlementPaymentResponse>(),
  // TODO: Recommend the splitting of the one time payment and invoice payment methods in the makePayment method above
  makeOneTimePayment: async (paymentData: OneTimePaymentFormBody) =>
    paymentsPaymentsServiceClient
      .post('v1/payment', { json: paymentData })
      .json<MakePaymentResponse>(),
}

export interface APIPaymentFilterOptions {
  filter: string
  entry: string
  amount: {
    greater: number
    less: number
  }
  date: {
    before: string
    after: string
  }
  origin: PaymentOrigin
  commodities: string[]
  locations: string[]
}

export interface HistoricalPaymentV2Response {
  elements: HistoricalPaymentV2[]
  pagination: {
    page: number
    size: number
    totalRecords: number
  }
}

export interface DeferredPaymentV2Response {
  elements: DeferredPaymentV2[]
  pagination: {
    page: number
    size: number
    totalRecords: number
  }
}

export const deferredPaymentsRequests = {
  deferredPaymentV2List: async ({
    page = null,
    size = null,
    filter = null,
    sort = null,
  }: PaymentsSearchParams) => {
    const searchParams = buildPaymentSearchParams({ page, size, filter, sort }, 'v2')

    if (searchParams.get('size')) {
      searchParams.append('page.size', searchParams.get('size') ?? '')
      searchParams.delete('size')
    }
    if (searchParams.get('page')) {
      searchParams.append('page.number', searchParams.get('page') ?? '')
      searchParams.delete('page')
    }

    const rawResponse = await paymentsPaymentsServiceClient
      .get('v2/payments/deferred', { searchParams })
      .json<DeferredPaymentV2Response>()

    const transformedResponse = {
      data: rawResponse.elements,
      meta: {
        pagination: {
          total: rawResponse.pagination.totalRecords,
          count: rawResponse.elements.length,
          per_page: rawResponse.pagination.size,
          current_page: (page as number) || 1,
          total_pages: Math.ceil(rawResponse.pagination.totalRecords / rawResponse.pagination.size),
          links: {},
        },
      },
    }

    return transformedResponse
  },

  bushelDeferredPaymentDetail: async (id: number | string) => {
    const response = await paymentsPaymentsServiceClient
      .get(`v1/payments/bushel/deferred/${id}`)
      .json<BushelDeferredPaymentDetail>()
    return response
  },

  externalDeferredPaymentDetail: async (id: number | string) => {
    const response = await paymentsPaymentsServiceClient
      .get(`v1/payments/external/deferred/${id}`)
      .json<ExternalDeferredPaymentDetail>()
    return response
  },
}

export const historicalPaymentsRequests = {
  historicalPaymentV2List: async ({
    page = null,
    size = null,
    filter = null,
    sort = null,
  }: PaymentsSearchParams) => {
    const searchParams = buildPaymentSearchParams({ page, size, filter, sort })

    const rawResponse = await paymentsPaymentsServiceClient
      .get('v2/payments', { searchParams })
      .json<HistoricalPaymentV2Response>()

    const transformedResponse = {
      data: rawResponse.elements,
      meta: {
        pagination: {
          total: rawResponse.pagination.totalRecords,
          count: rawResponse.elements.length,
          per_page: rawResponse.pagination.size,
          current_page: (page as number) || 1,
          total_pages: Math.ceil(rawResponse.pagination.totalRecords / rawResponse.pagination.size),
          links: {},
        },
      },
    }

    return transformedResponse
  },
  accountsReceivableCsv: (filter?: BushelPaymentFiltersForQuery) =>
    paymentsPaymentsServiceClient.get('v1/payments/accounts_receivable/csv', {
      searchParams: buildPaymentSearchParams({ filter }),
    }),
  bushelHistoricalPaymentDetail: async (id: string | number) => {
    const response = await paymentsPaymentsServiceClient
      .get(`v1/payments/bushel/${id}`)
      .json<BushelHistoricalPaymentDetail>()
    return response
  },
  externalHistoricalPaymentDetail: async (id: string | number) => {
    const response = await paymentsPaymentsServiceClient
      .get(`v1/payments/external/${id}`)
      .json<ExternalHistoricalPaymentDetail>()
    return response
  },
}

export const externalPaymentAssociations = {
  externalAssociatedSettlements: async ({
    origin,
    id,
  }: {
    origin: string
    id: string | number
  }) => {
    const response = await paymentsPaymentsServiceClient
      .get(`v1/payments/${origin.toLowerCase()}/${id}/settlements`)
      .json<ExternalPaymentAssociatedSettlement[]>()

    return response
  },
  externalAssociatedInvoices: async ({ origin, id }: { origin: string; id: string | number }) => {
    const response = await paymentsPaymentsServiceClient
      .get(`v1/payments/${origin.toLowerCase()}/${id}/invoices`)
      .json<ExternalPaymentAssociatedInvoice[]>()
    return response
  },
}

export const paymentsFilterRequests = {
  externalPaymentFilters: () =>
    paymentsPaymentsServiceClient
      .get('v1/payments/external/filters')
      .json<ExternalPaymentFilterOptions>(),
  bushelPaymentFilterOptions: () =>
    paymentsPaymentsServiceClient
      .get('v1/payments/bushel/filters')
      .json<BushelPaymentFilterOptions>(),
}
