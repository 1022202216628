import { bidlClient } from 'src/api/bidl/client'

const iamAccessManageBaseUrl = 'api/iam/access/manage'

export const iamAccessManageRequests = {
  getAllRoles: (tenantId: string, request: GetAllRolesRequest) =>
    bidlClient
      .post(`${iamAccessManageBaseUrl}/v1/GetAllRoles`, {
        json: request,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetAllRolesResponse>(),

  getRoleById: (tenantId: string, request: GetRoleByIdRequest) =>
    bidlClient
      .post(`${iamAccessManageBaseUrl}/v1/GetRoleById`, {
        json: request,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetRoleByIdResponse>(),

  getAllRolePermissions: (tenantId: string, request: GetRoleByIdRequest) =>
    bidlClient
      .post(`${iamAccessManageBaseUrl}/v1/GetAllRolePermissions`, {
        json: request,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetAllRolePermissionsResponse>(),

  createRoleBindings: (tenantId: string, request) =>
    bidlClient
      .post(`${iamAccessManageBaseUrl}/v1/CreateRoleBindings`, {
        json: request,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json(),

  deleteRoleBindings: (tenantId: string, request) =>
    bidlClient
      .post(`${iamAccessManageBaseUrl}/v1/DeleteRoleBindings`, {
        json: request,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json(),
}
