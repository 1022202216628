import { bidlClient } from 'src/api/bidl/client'
import { ProductCategory } from 'src/data/constants/product-category'

export const quotesBaseUrl = 'api/aggregator/quoting'

export const quotesRequests = {
  // Grower
  getAccountsByUser: async () => {
    const response = await bidlClient
      .post(`${quotesBaseUrl}/v1/GetAccountsByUser`, { json: {} })
      .json<
        AggregatorResponse<
          Array<AccountResponse>,
          'api.aggregator.quoting.v1.response#GetAccountsByUserSuccess'
        >
      >()

    return response.data
  },

  getQuotingProducts: async (request: PaginatedAggregatorRequest) =>
    bidlClient
      .post(`${quotesBaseUrl}/v1/GetProducts`, { json: request })
      .json<
        PaginatedAggregatorResponseBase<
          { products: ProductListItemResponse[] },
          'api.aggregator.quoting.v1.response#GetProductsSuccess'
        >
      >(),

  // Staff
  staffCreateFileImport: (body: StaffCreateFileImportRequest) => {
    return bidlClient
      .post(`${quotesBaseUrl}/v1/StaffCreateFileImport`, { json: body })
      .json<
        AggregatorResponse<
          StaffCreateFileImportResponse,
          'api.aggregator.quoting.v1#StaffCreateFileImportSuccess'
        >
      >()
      .then((response) => response.data)
  },
}

export type AccountResponse = {
  id: string
  name: string | null
  sourceId: string
}

export type ProductListItemResponse = {
  id: string
  name: string
  uom: string
  category: ProductCategory
}

export type StaffCreateFileImportRequest = { file: string; name: string }

export type StaffCreateFileImportResponse = {
  createdAt: string
  createdBy: string
  name: string
  processingMessage: string[]
  numberOfProducts: number | null
  id: string
  status: string
}
