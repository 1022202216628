import { Capacitor } from '@capacitor/core'
import { User } from 'configcat-react'
import { Session } from 'next-auth'
import PackageInfo from 'package.json'

/**
 * Returns a new ConfigCat User object with the provided properties.
 * @param slug - The current company slug
 * @param installationId - The installation ID generated by the app on boot
 * @param session - The current session object from NextAuth
 * @param custom - Any additional custom properties to include in the user object
 * @returns A new ConfigCat User object
 */
export default function getConfigCatUser(
  slug: string,
  installationId: string,
  session: Session | null,
  custom: Record<string, any> = {}
) {
  const customUserProps = {
    CompanySlug: slug,
    company_slug: slug, //consistent with Centre to enable shared segments
    AppInstallationId: installationId,
    AppVersion: PackageInfo.version,
    AppPlatform: Capacitor.getPlatform(),
    ...custom,
  }

  const bushelId = session?.user?.bushelId
  // Default to using the installation ID if the user is not logged in
  const userIdentifier = bushelId ?? installationId
  const UserIdSource = bushelId ? 'bushel' : 'installation'

  return new User(userIdentifier, undefined, undefined, {
    UserIdSource,
    ...customUserProps,
  })
}
