import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import type { SortDirection } from 'src/components/designsystem/display-config'
import { base64DecodeString, base64EncodeString } from 'src/utils/string/base64'

export type SortDirectionKeyStore = {
  key: string
  direction: SortDirection
  update: (key: string, direction: SortDirection) => void
  clear: () => void
  handleOnClick: (key: string) => void
}

/**
 * Toggles the sort direction between 'asc', 'desc', and null - in that order
 * @param direction - The current sort direction
 * @returns The new sort direction
 */
const getNextSortDirection = (direction: SortDirection | undefined): SortDirection => {
  switch (direction) {
    case 'ASC':
      return 'DESC'
    case 'DESC':
      return null
    default:
      return 'ASC'
  }
}

export default function useColumnSortDirection() {
  const { asPath, replace } = useRouter()
  const [pathname, unparsedQueryParams] = asPath.split('?')
  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(unparsedQueryParams)),
    [unparsedQueryParams]
  )

  const currentSort = Array.isArray(queryParams.sort) ? undefined : queryParams.sort
  const [field, direction] = currentSort ? decodeFieldAndDirection(currentSort) : []

  const setSort = useCallback(
    async (key: string | null, direction: SortDirection | null) => {
      if (key && direction) {
        const newSort = encodeFieldAndDirection(key, direction)

        if (currentSort !== newSort) {
          await replace({ pathname, query: { ...queryParams, sort: newSort } }, undefined, {
            shallow: true,
          })
        }
      } else if (currentSort) {
        const query = { ...queryParams }
        delete query.sort
        await replace({ pathname, query }, undefined, { shallow: true })
      }
    },
    [replace, queryParams, currentSort]
  )

  return {
    key: field,
    direction,
    clear: () => setSort(null, null),
    handleOnClick: (newField: string | undefined) => {
      const isNewSort = newField !== undefined && newField !== field
      return setSort(newField ?? null, isNewSort ? 'ASC' : getNextSortDirection(direction))
    },
  }
}

function encodeFieldAndDirection(field: string, direction: SortDirection) {
  return base64EncodeString(`${field}:${direction}`)
}

function decodeFieldAndDirection(sort: string) {
  return base64DecodeString(sort).split(':') as [string, SortDirection]
}
