import * as Icons from 'src/components/designsystem/Icons'

// * Variable name duplication here is intentional,
// * the context will decide if the type or const will be used,
// * type will be used at compile time, and const will be used at runtime
export type IconNames = keyof typeof Icons

export const IconNames = Object.keys(Icons)

export type StandardIconName = `${string}StandardIcon` & IconNames

export type ActiveIconName = `${string}ActiveIcon` & IconNames

/**
 * Return the Icon represented by the iconName
 * @param IconNames - a single icon name
 * @returns JSX.Element
 */
export function asIcon(iconName: IconNames) {
  return Icons[iconName] || Icons.AlertCircleIcon
}
