import { useMemo } from 'react'
import { useIsMutating } from '@tanstack/react-query'
import { useAuth } from 'src/auth'
import Link from 'next/link'
import { Hide, Show } from '@chakra-ui/react'
import { useFormState } from 'react-hook-form'
import { Button, Heading } from '@bushelpowered/design-system'
import { ButtonGroup, CardHeader, HStack, Spacer, useDisclosure } from 'src/components/designsystem'
import BottomButtonBar from 'src/components/admin/crm/bottom-button-bar'
import PublishConfirmationModal from 'src/components/shared/form/PublishConfirmationModal'
import { STAFF_CUSTOM_PAGES_MUTATION_KEY } from 'src/data/queries/custom-pages'

export default function FormHeader({
  id,
  isPublished,
  onSave,
  onPublish,
}: Readonly<
  {
    id?: string
  } & React.ComponentProps<typeof QuickActionButtons>
>) {
  return (
    <CardHeader borderBottom="1px solid" borderColor="gray.300">
      <HStack>
        <Heading type="h4">{id ? 'Edit Page' : 'New Page'}</Heading>
        <Spacer />
        <QuickActionButtons isPublished={isPublished} onSave={onSave} onPublish={onPublish} />
      </HStack>
    </CardHeader>
  )
}

function QuickActionButtons({
  isPublished,
  onSave,
  onPublish,
}: Readonly<{
  isPublished: boolean
  onSave: () => void
  onPublish: () => void
}>) {
  const { slug } = useAuth()
  const { isSubmitting } = useFormState()
  const publishModalDisclosure = useDisclosure()
  const runningMutations = useIsMutating({ mutationKey: STAFF_CUSTOM_PAGES_MUTATION_KEY })

  const submitDisabled = isSubmitting || runningMutations > 0

  // Buttons need to have a ml important to override the default ml in ButtonGroup
  const buttons = useMemo(
    () => (
      <>
        <Button
          as={Link}
          variant="secondary"
          minW="102px"
          w={['full', null, 'unset']}
          href={[null, slug, 'staff', 'custom-pages'].join('/')}
          isDisabled={submitDisabled}
          onClick={(e) => e.stopPropagation()}
          ml="0 !important"
        >
          Cancel
        </Button>
        <Button
          minW="102px"
          w={['full', null, 'unset']}
          onClick={(e) => {
            e.stopPropagation()
            onSave()
          }}
          isLoading={submitDisabled}
          ml="0 !important"
        >
          {isPublished ? 'Update' : 'Save'}
        </Button>
        {!isPublished && (
          <Button
            minW="102px"
            w={['full', null, 'unset']}
            onClick={(e) => {
              e.stopPropagation()
              publishModalDisclosure.onOpen()
            }}
            isLoading={submitDisabled}
            ml="0 !important"
          >
            Publish
          </Button>
        )}
      </>
    ),
    [isPublished, onSave, publishModalDisclosure, slug, submitDisabled]
  )

  return (
    <>
      <Show above="sm">
        <ButtonGroup gap={2}>{buttons}</ButtonGroup>
      </Show>
      <Hide above="sm">
        <BottomButtonBar flexDir={isPublished ? undefined : 'column'} gap={2}>
          {buttons}
        </BottomButtonBar>
      </Hide>
      <PublishConfirmationModal
        {...publishModalDisclosure}
        onPublishAction={onPublish}
        message="Publish to create a page in the customer portal."
      />
    </>
  )
}
