import { ReactNode } from 'react'
import {
  Box,
  Center,
  Collapse,
  Flex,
  Heading,
  IconButton,
  InfoIcon,
  Tooltip,
  VStack,
  useDisclosure,
  useBreakpoint,
} from 'src/components/designsystem'
import { ErrorState, LoadingState } from 'src/components/resource'
import ToggleView from 'src/components/resource/ToggleView'
import { HTTPError } from 'src/api'

interface SimpleResourceListItemProps {
  title: string
  children: ReactNode
  isLoaded: boolean
  tooltipLabel?: string
  error?: HTTPError | null
}

export default function SimpleResourceListItem({
  title,
  children,
  isLoaded,
  tooltipLabel,
  error,
}: SimpleResourceListItemProps) {
  const { isOpen: isModalOpen, onToggle: onModalToggle } = useDisclosure()
  const { isDesktop } = useBreakpoint()

  if (error) {
    return (
      <SimpleResourceListItemContainer
        title={title}
        isLoaded={isLoaded}
        tooltipLabel={tooltipLabel}
        onModalToggle={onModalToggle}
        isModalOpen={isModalOpen}
        isDesktop={isDesktop}
      >
        <Box pb={8}>
          <ErrorState
            subHeader={
              error?.response?.payload?.error?.message ||
              'There was an error while retrieving this report. Please refresh the page to try again.'
            }
          />
        </Box>
      </SimpleResourceListItemContainer>
    )
  }

  return (
    <SimpleResourceListItemContainer
      title={title}
      isLoaded={isLoaded}
      tooltipLabel={tooltipLabel}
      onModalToggle={onModalToggle}
      isModalOpen={isModalOpen}
      isDesktop={isDesktop}
    >
      {children}
    </SimpleResourceListItemContainer>
  )
}

function SimpleResourceListItemContainer({
  title,
  children,
  isLoaded,
  tooltipLabel,
  onModalToggle,
  isDesktop,
  isModalOpen,
}) {
  return (
    <VStack w="full" spacing={0} mt={[2, null, 4]} borderRadius={8} bgColor="white" boxShadow="xs">
      <Flex
        borderBottomColor="gray.300"
        borderBottomWidth="1px"
        borderTopLeftRadius="lg"
        borderTopRightRadius="lg"
        w="full"
        align="center"
        cursor="pointer"
        onClick={onModalToggle}
      >
        <Heading type="h4" ml={[2, null, 0]} py={4} px={2} width="100%">
          <ToggleView.Toggle
            aria-label="Toggle Resource List Item"
            isExpanded={isModalOpen}
            mx={3}
          />
          {title}
          {tooltipLabel && isDesktop && (
            <Tooltip
              hasArrow={isDesktop}
              bg="gray.700"
              label={tooltipLabel}
              placement={isDesktop ? 'right-start' : 'bottom'}
            >
              <IconButton
                aria-label="Report Title Info"
                icon={<InfoIcon color="#4A5568" ml={3} width="15px" />}
                variant="unstyled"
                borderRadius="full"
                width="auto"
                minW="auto"
                isRound
              />
            </Tooltip>
          )}
        </Heading>
      </Flex>
      <SimpleResourceListItemBody isLoaded={isLoaded} isModalOpen={isModalOpen}>
        {children}
      </SimpleResourceListItemBody>
    </VStack>
  )
}

function SimpleResourceListItemBody({ children, isLoaded, isModalOpen }) {
  return (
    <Box width="100%">
      <Collapse in={isModalOpen} unmountOnExit>
        <Center>
          <VStack p={[4, null, null, 6]} w="full" spacing={[4, null, null, 6]} mb={2}>
            {isLoaded ? children : <LoadingState />}
          </VStack>
        </Center>
      </Collapse>
    </Box>
  )
}
