import * as api from 'src/api'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getNextPageParam } from 'src/data/queries/utils'
import { useMemo } from 'react'
import { useMerchandiser } from 'src/data/merchandiser'
import { DeliveryTicketFiltersForQuery } from 'src/components/delivery-tickets/DeliveryTicketsFilters'

export function useDeliveryTicketFilterOptions() {
  const { selectedUserIdpId } = useMerchandiser()

  return useQuery({
    queryKey: ['delivery-ticket-filter-options', { selectedUserIdpId }],
    queryFn: () => {
      return api.centre.deliveryTicketFilterOptions()
    },
    staleTime: Infinity,
  })
}

export function useDeliveryTickets({ filter }: { filter?: DeliveryTicketFiltersForQuery } = {}) {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['delivery-ticket-list', { selectedUserIdpId, filter }],
    queryFn: ({ pageParam }) => api.centre.deliveryTickets({ filter, page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve delivery tickets, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => {
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useDeliveryTicketDetail({
  id,
  enabled,
}: {
  id: string | number
  enabled: boolean
}) {
  return useQuery<DeliveryTicketDetail, api.HTTPError>({
    queryKey: ['delivery-ticket-detail', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')
      return api.centre.deliveryTicket(id)
    },
    enabled,
    staleTime: Infinity,
  })
}
