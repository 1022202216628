import { Text } from '@bushelpowered/design-system'
import Link from 'next/link'
import { MenuItem, PencilActiveIcon } from 'src/components/designsystem'

// Path is the part of the url between slug and id
export default function EditMenuItemLink({
  href,
}: Readonly<{
  href: string
}>) {
  return (
    <MenuItem
      icon={<PencilActiveIcon fontSize="xl" />}
      as={Link}
      href={href}
      onClick={(e) => e.stopPropagation()}
      data-testid="edit-more-items-button"
    >
      <Text>Edit</Text>
    </MenuItem>
  )
}
