import { useRef, useState } from 'react'
import { Box } from 'src/components/designsystem'
import { NotificationNonIdealState } from './children/NotificationNonIdealState'
import { LoadingState } from 'src/components/resource'
import { useNotificationHistory } from 'src/data/queries/notifications'
import { NotificationStack } from './children/NotificationStack'
import { BackToTopFooter } from './children/BackToTopFooter'
import { useAuth } from 'src/auth'

interface NotificationHistoryProps {
  groupId: string | null
}

export function NotificationHistory({ groupId }: Readonly<NotificationHistoryProps>) {
  const notificationContainerRef = useRef<HTMLDivElement>(null)
  const [isHidden, setIsHidden] = useState(true)
  const { slug } = useAuth()

  const notificationHistoryQuery = useNotificationHistory({
    groupId,
    slug,
  })
  const historicalNotifications = notificationHistoryQuery?.data?.data

  const scrollToTop = () => {
    if (notificationContainerRef.current) {
      notificationContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  function handleScroll(event) {
    const scrollTop = event.target.scrollTop
    if (scrollTop >= 150) {
      setIsHidden(false)
    } else {
      setIsHidden(true)
    }
  }

  const hasItems = !!historicalNotifications && historicalNotifications?.length > 0
  const isEmptyState = !groupId || (notificationHistoryQuery?.isSuccess && !hasItems)

  if (notificationHistoryQuery?.isLoading) {
    return (
      <Box pt="0.5rem">
        <LoadingState />
      </Box>
    )
  }

  if (notificationHistoryQuery?.error) {
    return (
      <Box py="2rem" px="2.5rem">
        <NotificationNonIdealState
          header="Ope, sorry!"
          illustration="404"
          subHeader="There was an error while retrieving notifications. Please try again."
          isVerticallyCentered
        />
      </Box>
    )
  }

  if (isEmptyState) {
    return (
      <Box py="2rem" px="2.5rem" whiteSpace="pre-wrap">
        <NotificationNonIdealState
          header="Nothing Here Yet"
          illustration="tumbleweed"
          subHeader={`There are no notifications to show. \nIf you farm it, they will come.`}
          isVerticallyCentered
        />
      </Box>
    )
  }

  const handleRefresh = () => notificationHistoryQuery.refetch()

  return (
    <Box
      height="100%"
      overflow="auto"
      ref={notificationContainerRef}
      onScroll={handleScroll}
      data-testid="notification-list"
    >
      <NotificationStack
        hasItems={hasItems}
        data={historicalNotifications ?? []}
        isHidden={isHidden}
        handleRefresh={handleRefresh}
      />
      {!isHidden && <BackToTopFooter data-testid="back-to-top-footer" scrollToTop={scrollToTop} />}
    </Box>
  )
}
