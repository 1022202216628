import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default function formatDateWithLastUpdated(date?: string) {
  const lastUpdatedDate = dayjs(date)

  return `${lastUpdatedDate.format('MM/DD/YY')} (${lastUpdatedDate.fromNow()})`
}
