import { useAuth } from 'src/auth'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from 'src/components/designsystem'
import { PenIcon } from 'src/components/designsystem/icons/PenIcon'
import { PlusIcon } from 'src/components/designsystem/icons/PlusIcon'
import DetailGrid from 'src/components/resource/DetailGrid'
import { ManageFieldNamesModal } from 'src/components/tickets/field-names/ManageFieldNamesModal'
import { useConfig } from 'src/data/config'
import { Ticket } from 'src/types/tickets/Ticket'

export function FieldNamesSection({
  isLoaded,
  ticketDetail,
}: Readonly<{ isLoaded: boolean; ticketDetail?: Ticket }>) {
  const { isStaff } = useAuth()
  const isNotStaff = !isStaff

  const { isFeatureEnabled } = useConfig()
  const isFieldNamesFeatureEnabled = isFeatureEnabled('field_ids')

  if (!isFieldNamesFeatureEnabled) return null

  const fields = ticketDetail?.user_defined_farm_fields
  const hasFields = fields && fields.length > 0
  const fieldIds = fields?.map((field) => field.id)
  const fieldNames = fields?.map((field) => field.name).sort((a, b) => a.localeCompare(b))
  const canManageFields = hasFields && isNotStaff

  return (
    <DetailGrid.ListCard
      heading={
        <HStack width="full">
          <DetailGrid.TopHeading>Field Names</DetailGrid.TopHeading>
          <Spacer />
          {canManageFields && (
            <ManageFieldNamesButton ticketId={ticketDetail.id} selectedFieldIds={fieldIds} />
          )}
        </HStack>
      }
    >
      <CardContents
        isLoaded={isLoaded}
        fieldNames={fieldNames}
        ticketId={ticketDetail?.id}
        isNotStaff={isNotStaff}
      />
    </DetailGrid.ListCard>
  )
}

function CardContents({
  isLoaded,
  fieldNames,
  ticketId,
  isNotStaff,
}: Readonly<{
  isLoaded: boolean
  fieldNames?: string[]
  ticketId?: number
  isNotStaff: boolean
}>) {
  if (!isLoaded || !ticketId) {
    return <FieldNameSkeletons />
  }

  return fieldNames && fieldNames?.length > 0 ? (
    <FieldNameList fieldNames={fieldNames} />
  ) : (
    <AddFieldNameCard ticketId={ticketId} isNotStaff={isNotStaff} />
  )
}

function FieldNameSkeletons() {
  return (
    <Stack spacing={2}>
      <Skeleton height={6} />
      <Skeleton height={6} />
      <Skeleton height={6} />
    </Stack>
  )
}

function ManageFieldNamesButton({
  ticketId,
  selectedFieldIds,
}: Readonly<{ ticketId: number; selectedFieldIds?: number[] }>) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} variant="link" fontSize="initial" leftIcon={<PenIcon />}>
        Manage
      </Button>

      {isOpen && (
        <ManageFieldNamesModal
          ticketId={ticketId}
          selectedFieldIds={selectedFieldIds ?? []}
          onDismiss={onClose}
        />
      )}
    </>
  )
}

function FieldNameList({ fieldNames }: Readonly<{ fieldNames: string[] }>) {
  return (
    <Stack divider={<Divider />} spacing={0}>
      {fieldNames.map((fieldName) => (
        <FieldName fieldName={fieldName} key={fieldName} />
      ))}
    </Stack>
  )
}

function FieldName({ fieldName }: Readonly<{ fieldName: string }>) {
  return (
    <Box paddingY={2}>
      <Text>{fieldName}</Text>
    </Box>
  )
}

function AddFieldNameCard({
  ticketId,
  isNotStaff,
}: Readonly<{ ticketId: number; isNotStaff: boolean }>) {
  // The box could be extracted to its own component. https://bushel.atlassian.net/browse/AGB-7300
  return (
    <Stack borderRadius={6} borderWidth="1px" padding={4} spacing={4}>
      <Text>A field name is not currently assigned to this ticket.</Text>
      {isNotStaff && (
        <Flex justifyContent="center">
          <AddFieldNameButton ticketId={ticketId} />
        </Flex>
      )}
    </Stack>
  )
}

function AddFieldNameButton({
  ticketId,
}: Readonly<{
  ticketId: number
}>) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        paddingTop={0}
        paddingBottom={0}
        leftIcon={<PlusIcon />}
      >
        Add field name
      </Button>

      {isOpen && (
        <ManageFieldNamesModal ticketId={ticketId} selectedFieldIds={[]} onDismiss={onClose} />
      )}
    </>
  )
}
