import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PersonOutlineIcon,
  Tooltip,
} from 'src/components/designsystem'
import { useRef } from 'react'
import { Account } from 'src/components/account/Account'
import { AccountPlaceholder } from 'src/components/account/AccountPlaceholder'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'

interface MyAccountHeaderButtonProps {
  isAuthenticated: boolean
  isDesktop: boolean
}

export default function MyAccountHeaderButton({
  isAuthenticated,
  isDesktop,
}: Readonly<MyAccountHeaderButtonProps>) {
  const accountButtonRef = useRef<HTMLButtonElement>(null)
  const { toggleRightSidebar } = useRightSidebarStore()

  return (
    <Flex>
      {isDesktop && (
        <Popover id="ma-pop" placement="bottom-end" returnFocusOnClose={false}>
          <Tooltip label="My Account" hasArrow>
            <Box display="inline-block">
              <PopoverTrigger>
                <IconButton
                  icon={<PersonOutlineIcon />}
                  aria-label="My Account"
                  data-testid="my-account-icon"
                  variant="menuIcon"
                  color="gray.500"
                  fontSize="2xl"
                  isRound
                  boxSize={8}
                />
              </PopoverTrigger>
            </Box>
          </Tooltip>

          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={0} w={80}>
              {isAuthenticated ? <Account /> : <AccountPlaceholder />}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}

      {!isDesktop && (
        <IconButton
          ref={accountButtonRef}
          variant="menuIcon"
          color="gray.500"
          fontSize="2xl"
          isRound
          boxSize={8}
          aria-label="My Account"
          data-testid="my-account-icon"
          icon={<PersonOutlineIcon />}
          onClick={() => toggleRightSidebar('account')}
        />
      )}
    </Flex>
  )
}
