import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { useMerchandiser } from 'src/data/merchandiser'
import {
  DEFAULT_PAGE_SIZE,
  flattenPages,
  getAggregatorNextPageParamFactory,
} from 'src/data/queries/utils'

const businessLocationsOptions = ({
  pageSize = DEFAULT_PAGE_SIZE,
  selectedUserIdpId,
  ...request
}: {
  selectedUserIdpId?: string
  pageSize?: number
} & BusinessLocationsRequest) =>
  infiniteQueryOptions({
    queryKey: ['business-locations', { selectedUserIdpId, pageSize, ...request }],
    queryFn: ({ pageParam: token }) =>
      aggregatorService.getBusinessLocations({
        pagination: { token, pageSize },
        ...request,
      }),
    select: flattenPages,
    initialPageParam: null,
    getNextPageParam: getAggregatorNextPageParamFactory(pageSize),
    meta: {
      errorMessage:
        'Unable to retrieve business locations list, please refresh the page to try again.',
    },
  })

type BusinessLocationsOptions = ReturnType<typeof businessLocationsOptions>

export function useBusinessLocations({
  request,
  ...options
}: Partial<BusinessLocationsOptions> & { request?: BusinessLocationsRequest }) {
  const { selectedUserIdpId } = useMerchandiser()
  return useInfiniteQuery({
    ...businessLocationsOptions({ selectedUserIdpId, ...request }),
    ...options,
  })
}
