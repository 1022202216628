import { useCallback, useEffect, useState, useRef } from 'react'

import {
  Button,
  CircleXIcon,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  NumberInput,
  NumberInputField,
} from 'src/components/designsystem'
import { RangeFilterOptionItem, BaseFilterProps } from 'src/utils/filters'

import { OverflowStack } from './Filters'

const checkCurrencyFormat = (val) => {
  return /^(\d*)(\.\d{0,2})?$/.test(val) || !val
}

export type RangeFilterProps = Omit<BaseFilterProps<RangeFilterOptionItem>, 'values'> & {
  min?: number
  max?: number
  value: RangeFilterOptionItem | undefined
}

export function RangeFilter({ min, max, value, onChange }: RangeFilterProps) {
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined)

  const [lowValue, setLowValue] = useState<number | undefined>(value?.low)
  const [highValue, setHighValue] = useState<number | undefined>(value?.high)
  const [userInput, setUserInput] = useState<boolean>(false)

  const checkInvalidity = useCallback(
    (val) => {
      if (!max || !min) return false
      return val > max || val < min || !checkCurrencyFormat(val)
    },
    [max, min]
  )
  const isLowInvalid = checkInvalidity(lowValue)
  const isHighInvalid = checkInvalidity(highValue)

  const handleHighChange = useCallback(
    (value) => {
      if (!checkInvalidity(value)) {
        setHighValue(value)
        setUserInput(true)
      }
    },
    [setHighValue, checkInvalidity, setUserInput]
  )

  const handleLowChange = useCallback(
    (value) => {
      if (!checkInvalidity(value)) {
        setLowValue(value)
        setUserInput(true)
      }
    },
    [setLowValue, checkInvalidity, setUserInput]
  )

  const resetLowValue = useCallback(() => handleLowChange(null), [handleLowChange])
  const resetHighValue = useCallback(() => handleHighChange(null), [handleHighChange])

  useEffect(() => {
    clearTimeout(timerRef.current) // clear previous timeout from typing
    timerRef.current = setTimeout(() => {
      timerRef.current = undefined

      if (userInput) {
        if (value?.low !== lowValue || value?.high !== highValue) {
          onChange({ low: lowValue ?? undefined, high: highValue ?? undefined })
          setUserInput(false)
        }
      } else {
        // was probably from a clear, so set the input fields to match the current applied (or cleared) values
        setLowValue(value?.low ?? undefined)
        setHighValue(value?.high ?? undefined)
      }
    }, 500)

    return () => clearTimeout(timerRef.current)
  }, [value, lowValue, highValue, userInput, onChange])

  return (
    <OverflowStack direction="column" alignItems="flex-start" spacing={4} padding={4}>
      <InputGroup size="sm">
        <InputLeftElement>$</InputLeftElement>
        <NumberInput
          w="100%"
          isInvalid={isLowInvalid}
          value={lowValue !== null ? lowValue : ''}
          min={min}
          max={max}
          onChange={handleLowChange}
          isValidCharacter={(char: string) => /^[0-9.]$/.test(char)}
        >
          <NumberInputField px={8} placeholder="Low" />
        </NumberInput>

        {!!value?.low && (
          <InputRightElement width="2rem">
            <Button
              mr={1}
              h="1.75rem"
              minW=".75rem"
              size="sm"
              variant="ghost"
              onClick={resetLowValue}
            >
              <CircleXIcon mr={1} />
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <InputGroup size="sm">
        <InputLeftElement>$</InputLeftElement>
        <NumberInput
          w="100%"
          isInvalid={isHighInvalid}
          value={highValue !== null ? highValue : ''}
          min={min}
          max={max}
          onChange={handleHighChange}
          isValidCharacter={(char: string) => /^[0-9.]$/.test(char)}
        >
          <NumberInputField px={8} placeholder="High" />
        </NumberInput>

        {!!value?.high && (
          <InputRightElement width="2rem">
            <Button
              mr={1}
              h="1.75rem"
              minW=".75rem"
              size="sm"
              variant="ghost"
              onClick={resetHighValue}
            >
              <CircleXIcon mr={1} />
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </OverflowStack>
  )
}
