import { ReactNode, useEffect, useMemo } from 'react'
import {
  Box,
  Heading,
  HStack,
  ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useBreakpoint,
} from 'src/components/designsystem'
import BackButton from 'src/components/shared/BackButton'
import { useHeaderHeightStore } from 'src/components/layout/Header'
import getClient from 'src/utils/clients/get-client'
import { BackButtonBehavior } from 'src/components/resource/BackButtonBehavior'

interface MobileFullscreenModalProps {
  primaryTitle: string
  secondaryTitle?: JSX.Element
  closeOnDesktop?: boolean
  onClose: () => void
  children: ReactNode
  contentMb?: string | number | (string | number)[] // Used to add margin-bottom to the content, for example, to add a gap for BottomActionBar
}

export function MobileFullscreenModal({
  primaryTitle,
  secondaryTitle,
  closeOnDesktop = true,
  onClose,
  children,
  contentMb,
}: MobileFullscreenModalProps) {
  const { isDesktop } = useBreakpoint()
  const { height: headerHeight } = useHeaderHeightStore()
  const isAndroid = getClient().isAndroid

  const backStackItems = useMemo(() => [{ callback: onClose }], [onClose])

  useEffect(() => {
    // Ensure `onClose()` is called when this is a non-desktop modal
    if (closeOnDesktop && isDesktop) onClose()
  }, [closeOnDesktop, isDesktop, onClose])

  if (closeOnDesktop && isDesktop) return null

  return (
    <>
      {isAndroid && <BackButtonBehavior backStackItems={backStackItems} />}
      <ChakraModal
        isOpen
        size="full"
        motionPreset="none"
        scrollBehavior="outside"
        trapFocus={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        {/* z-index & white background are applied to the modal overlay in mobile view to hide underlying content. See: JIRA MWS-525 */}
        <ModalOverlay bg="white" zIndex="detail-modal" />

        <ModalContent
          position="absolute"
          minHeight="unset"
          top={headerHeight}
          bottom={0}
          my={0}
          pt={0}
          px={2}
          pb="calc(16px + env(safe-area-inset-bottom))"
          overflow="auto"
          bg="gray.50"
          zIndex="detail-modal"
          containerProps={{ zIndex: 'detail-modal' }} // below header and normal modal's overlay
          mb={contentMb}
        >
          <Box w="100%" pl={3}>
            <BackButton onClick={onClose} />
          </Box>

          <ModalHeader
            p={0}
            boxShadow="xs"
            bg="white"
            borderRadius="lg"
            borderBottomRightRadius={0}
            borderBottomLeftRadius={0}
          >
            <HStack
              w="100%"
              alignItems="center"
              justifyContent="space-between"
              px={{ base: 4, sm: 6, md: 8 }}
              py={{ base: 4, md: 5 }}
            >
              <Heading
                aria-label="Id"
                type="h5"
                fontSize="xl"
                mr="auto"
                px={[2, 0]}
                data-testid="detail-primary-header"
              >
                {primaryTitle}
              </Heading>

              {secondaryTitle}
            </HStack>
          </ModalHeader>

          <ModalBody
            pt={0}
            px={[2, null, 8, 12]}
            pb={12}
            bg="white"
            boxShadow="xs"
            borderRadius="lg"
            borderTopRightRadius={0}
            borderTopLeftRadius={0}
          >
            {children}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  )
}
