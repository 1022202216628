import { createIcon } from '@chakra-ui/react'

export const CustomPagesStandardIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentcolor',
  },
  displayName: 'CustomPagesStandardIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d="M17.324 8.232a.75.75 0 1 0-1.148-.964l-5.745 6.84-2.124-2.36a.75.75 0 0 0-1.114 1.004l2.7 3a.75.75 0 0 0 1.131-.02l6.3-7.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.27 3.83 1.52-2.79c.22-.41.72-.57 1.14-.37l2.87 1.36c.12.06.25.06.37 0l.02-.01L15.06.66c.42-.2.92-.04 1.14.37l1.52 2.79c.06.11.17.19.3.22l3.12.58c.45.08.76.51.7.97l-.41 3.15c-.02.13.03.26.11.35l2.18 2.31c.32.34.32.86 0 1.19l-2.18 2.31c-.09.09-.13.22-.11.35l.41 3.15c.06.46-.25.88-.7.97l-3.12.58a.4.4 0 0 0-.3.22l-1.52 2.79c-.22.41-.72.57-1.14.37l-2.87-1.36a.403.403 0 0 0-.37 0l-2.87 1.36c-.42.2-.92.04-1.14-.37l-1.52-2.79a.455.455 0 0 0-.3-.22l-3.12-.58a.87.87 0 0 1-.7-.97l.41-3.15a.438.438 0 0 0-.11-.35l-2.2-2.3a.858.858 0 0 1 0-1.19L2.45 9.1c.09-.09.13-.22.11-.35L2.15 5.6c-.06-.46.25-.88.7-.97l3.12-.58a.4.4 0 0 0 .3-.22Zm2.553-1.55 2.325 1.101a1.902 1.902 0 0 0 1.693-.01l.006-.002 2.32-1.1 1.236 2.27c.283.519.77.845 1.28.963l.031.007 2.562.476-.332 2.55c-.09.635.155 1.192.475 1.552l.015.016 1.785 1.892-1.76 1.866a1.906 1.906 0 0 0-.515 1.594l.332 2.55-2.515.468a1.9 1.9 0 0 0-1.366.994l-1.228 2.254-2.325-1.102a1.902 1.902 0 0 0-1.674 0l-2.325 1.102-1.236-2.27a1.955 1.955 0 0 0-1.28-.963l-.031-.007-2.562-.476.332-2.55a1.939 1.939 0 0 0-.475-1.552l-.018-.02-1.8-1.88 1.758-1.863c.428-.441.594-1.038.515-1.594l-.332-2.55 2.516-.468a1.9 1.9 0 0 0 1.365-.995l1.228-2.254Z"
      />
    </>
  ),
})

export const CustomPagesActiveIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentcolor',
  },
  displayName: 'CustomPagesActiveIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.06.66-2.87 1.36-.02.01c-.12.06-.25.06-.37 0L8.93.67a.866.866 0 0 0-1.14.37L6.27 3.83a.4.4 0 0 1-.3.22l-3.12.58c-.45.09-.76.51-.7.97l.41 3.15c.02.13-.02.26-.11.35L.27 11.41c-.32.33-.32.85 0 1.19l2.2 2.3c.08.09.13.22.11.35l-.41 3.15c-.06.46.25.89.7.97l3.12.58c.13.03.24.11.3.22l1.52 2.79c.22.41.72.57 1.14.37l2.87-1.36c.12-.06.25-.06.37 0l2.87 1.36c.42.2.92.04 1.14-.37l1.52-2.79a.4.4 0 0 1 .3-.22l3.12-.58c.45-.09.76-.51.7-.97l-.41-3.15c-.02-.13.02-.26.11-.35l2.18-2.31c.32-.33.32-.85 0-1.19l-2.18-2.31a.438.438 0 0 1-.11-.35l.41-3.15a.87.87 0 0 0-.7-.97l-3.12-.58a.455.455 0 0 1-.3-.22L16.2 1.03a.866.866 0 0 0-1.14-.37Zm2.264 7.572a.75.75 0 1 0-1.148-.964l-5.745 6.84-2.124-2.36a.75.75 0 0 0-1.114 1.004l2.7 3a.75.75 0 0 0 1.131-.02l6.3-7.5Z"
    />
  ),
})

export const PersonWithMagnifyingGlassIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentcolor',
  },
  displayName: 'PersonWithMagnifyingGlassIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.463 4.975c.197 2.685 2.245 4.869 4.57 4.869 2.32 0 4.368-2.184 4.566-4.87.101-1.349-.32-2.605-1.191-3.541C12.551.51 11.351 0 10.033 0 8.706 0 7.502.505 6.649 1.424c-.866.927-1.287 2.188-1.186 3.55Zm2.215-2.593c.584-.629 1.423-.976 2.355-.976.923 0 1.758.347 2.338.985.602.646.892 1.529.822 2.483-.14 1.933-1.591 3.568-3.164 3.568-1.574 0-3.024-1.635-3.164-3.568-.07-.967.22-1.855.813-2.492Z"
      />
      <path d="M1.235 19.2a1.37 1.37 0 0 0 1.064.487h11.583a5.113 5.113 0 0 1-.084-1.406H2.308a.223.223 0 0 1-.005-.114c.681-3.784 4.487-5.51 7.73-5.51 1.978 0 4.165.641 5.725 2 .387-.3.82-.545 1.285-.725a8.42 8.42 0 0 0-1.244-1.008c-1.617-1.076-3.665-1.67-5.766-1.67-2.1 0-4.148.594-5.766 1.67-1.819 1.209-2.98 2.94-3.348 4.997-.084.47.03.936.316 1.279Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.597 21.856a3.694 3.694 0 0 1-4.367-5.915 3.692 3.692 0 0 1 5.468 4.897l1.808 1.944a.75.75 0 1 1-1.098 1.022l-1.811-1.948Zm-.443-1.53c.902-.808.98-2.193.171-3.095a2.191 2.191 0 1 0-3.266 2.923 2.192 2.192 0 0 0 3.095.172Z"
      />
    </>
  ),
})
