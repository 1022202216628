import { Badge } from '@bushelpowered/design-system'
import { DetailGrid, SkeleKeyValue } from 'src/components/resource'
import { Box, Divider } from 'src/components/designsystem'
import { SecurityToggleTooltip } from 'src/components/shared/SecurityToggle'
import formatDateWithLastUpdated from 'src/utils/string/format-date-with-last-updated'
import CardSectionSkeleton from './CardSectionSkeleton'
import { HStack, Stack } from '@chakra-ui/react'

export default function MetaSection({
  isLoading,
  updatedAt,
  updatedBy,
  publishedAt,
  isPublic,
}: Readonly<
  { isLoading: boolean } & Partial<
    Pick<StaffCustomPageDetails, 'updatedAt' | 'updatedBy' | 'publishedAt' | 'isPublic'>
  >
>) {
  if (isLoading) {
    return (
      <Box px={4}>
        <CardSectionSkeleton height="28px" withHeading={false} />
      </Box>
    )
  }

  return (
    <DetailGrid templateColumns="1fr">
      <DetailGrid.List px={4} spacing={1}>
        <SkeleKeyValue isLoaded={!isLoading} label="Security">
          <HStack align="center">
            <span>{isPublic ? 'Public ' : 'Private '}</span>
            <SecurityToggleTooltip offset={undefined} />
          </HStack>
        </SkeleKeyValue>
        <Divider />
        <SkeleKeyValue isLoaded={!isLoading} label="Last updated">
          <Stack direction={['column', null, 'row']} spacing={1}>
            <span>{formatDateWithLastUpdated(updatedAt)}</span>
            {updatedBy && <span>by {updatedBy}</span>}
          </Stack>
        </SkeleKeyValue>
        <Divider />
        <SkeleKeyValue isLoaded={!isLoading} label="Status">
          <Badge colorScheme={publishedAt ? 'green' : 'yellow'}>
            {publishedAt ? 'PUBLISHED' : 'DRAFT'}
          </Badge>
        </SkeleKeyValue>
      </DetailGrid.List>
    </DetailGrid>
  )
}
