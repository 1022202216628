import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  Button,
  CircleAddIcon,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
} from 'src/components/designsystem'
import FileFormCard from './FileFormCard'
import {
  type CustomPageFormValues,
  emptyFile,
  MAX_FILES,
  MAX_TITLE_LENGTH,
} from './useStaffCustomPageForm'

export default function FilesSection() {
  const files = useFieldArray<CustomPageFormValues, 'files'>({ name: 'files' })
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<CustomPageFormValues>()
  const filesTitle = watch('filesTitle')

  return (
    <Stack gap={6}>
      <Stack spacing={0}>
        <Text textStyle="preTitle">Files (Optional)</Text>
        <Text>Files will display alphabetically.</Text>
      </Stack>
      <FormControl isInvalid={!!errors?.filesTitle}>
        <FormLabel>Section Title</FormLabel>
        <Input {...register('filesTitle')} placeholder="Enter paragraph title" />
        <FormHelperText>{`${filesTitle?.length ?? '0'}/${MAX_TITLE_LENGTH}`}</FormHelperText>
        <FormErrorMessage>{errors?.filesTitle?.message}</FormErrorMessage>
      </FormControl>
      {files.fields.map((item, index) => (
        <FileFormCard
          key={item.id}
          index={index}
          onClear={() => (index === 0 ? files.update(index, emptyFile) : files.remove(index))}
        />
      ))}
      <Button
        leftIcon={<CircleAddIcon />}
        variant="link"
        ml={4}
        alignSelf="flex-start"
        isDisabled={files.fields.length >= MAX_FILES}
        onClick={() => files.append(emptyFile)}
      >
        Add file
      </Button>
    </Stack>
  )
}
