import { useMemo } from 'react'
import { Box, Button, ButtonGroup } from 'src/components/designsystem'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

type ToggleButtonProps = Readonly<{
  value: boolean
  labels: [string, string]
  onChange: (value: boolean) => void
}>

/**
 * Displays a toggle button group with two options, defined by the the order of the two labels provided.
 */
export default function ToggleButtonGroup({
  value,
  onChange,
  labels: [firstLabel, secondLabel],
}: ToggleButtonProps) {
  const [firstToggleStyles, secondToggleStyles] = useMemo(
    () => [getToggleStyles(value), getToggleStyles(!value)],
    [value]
  )

  return (
    <ButtonGroup
      p="3px"
      spacing={0}
      rounded={4}
      borderWidth={1}
      variant="outline"
      bgColor="gray.100"
      borderColor="blue.400"
    >
      <Button {...firstToggleStyles} onClick={() => onChange(true)} aria-selected={value}>
        <Box
          as="span"
          textStyle="small-bold"
          data-testid={`${labelToTestId(firstLabel)}-toggle-button`}
        >
          {firstLabel}
        </Box>
      </Button>
      <Button {...secondToggleStyles} onClick={() => onChange(false)} aria-selected={!value}>
        <Box
          as="span"
          textStyle="small-bold"
          data-testid={`${labelToTestId(secondLabel)}-toggle-button`}
        >
          {secondLabel}
        </Box>
      </Button>
    </ButtonGroup>
  )
}

function getToggleStyles(isActive: boolean) {
  const baseStyles = { variant: 'ghost', rounded: 2 }

  if (isActive) {
    return {
      ...baseStyles,
      color: 'white',
      bgColor: 'blue.400',
      _hover: { bg: 'blue.500' },
      _focus: { bg: 'blue.500' },
      _active: { bg: 'blue.600' },
      _pressed: { bg: 'blue.600' },
    }
  }

  return {
    ...baseStyles,
    color: 'blue.500',
    _active: { bg: 'blue.400', color: 'white' },
  }
}
