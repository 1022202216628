import { queryKeyFactory } from 'src/data/queries/queryKeyFactory'

const featureName = 'custom-pages'

export const customPagesQueryKeys = {
  listStaff: () => queryKeyFactory(featureName, 'list', 'staff'),
  list: () => queryKeyFactory(featureName, 'list'),
  getByIdStaff: (id: string | undefined) =>
    queryKeyFactory(featureName, 'get', 'staff', { id: id ?? '' }),
  getById: (id: string | undefined) => queryKeyFactory(featureName, 'get', { id: id ?? '' }),
}
