import { extendTheme } from '@chakra-ui/react'
import { Montserrat, Roboto } from 'next/font/google'
import { Theme } from '@bushelpowered/design-system'
import getClient from 'src/utils/clients/get-client'

const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  display: 'swap',
  subsets: ['latin'],
})

const montserrat = Montserrat({
  weight: '700',
  display: 'swap',
  subsets: ['latin'],
})

const fonts = {
  body: roboto.style?.fontFamily ?? 'Roboto, sans-serif',
  heading: montserrat.style?.fontFamily ?? 'Montserrat, sans-serif',
  mono: 'Menlo, monospace',
}

const mergedTheme = extendTheme(Theme, {
  fonts,
  styles: {
    global: {
      body: {
        color: 'gray.700',
        userSelect: getClient().isNativeApp ? 'none' : '',
      },
      a: {
        WebkitTouchCallout: 'none',
      },
    },
  },
  textStyles: {
    h1: { fontWeight: 'bold', fontFamily: fonts.heading },
    h2: { fontWeight: 'bold', fontFamily: fonts.heading },
    h3: { fontWeight: 'bold', fontFamily: fonts.heading },
    h4: { fontWeight: 'bold', fontFamily: fonts.heading },
    h5: { fontWeight: 'bold', fontFamily: fonts.heading },
    h6: { fontWeight: 'bold', fontFamily: fonts.heading },
  },
})

export default mergedTheme
