import { ChangeEvent, useCallback, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import Image from 'next/image'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CloudUploadIcon,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Text,
} from 'src/components/designsystem'
import {
  fileExtensionToMediaType,
  useStaffCreateCustomPageFile,
} from 'src/data/queries/custom-pages'
import { type CustomPageFormValues } from './useStaffCustomPageForm'

const ALLOWED_IMAGE_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
] // If more file types are added, update the file formats message

export default function HeaderImageFormCard() {
  const { setError, clearErrors, watch } = useFormContext<CustomPageFormValues>()
  const {
    field,
    fieldState: { error },
  } = useController<CustomPageFormValues, 'headerImageId'>({ name: 'headerImageId' })

  const [imageUrl, setImageUrl] = useState<string | undefined>(watch('headerImageFileLink')) // Pull the default value from the form

  const { mutate, isPending, isError, reset, variables } = useStaffCreateCustomPageFile({
    onSuccess: (data) => {
      field.onChange(data.id)
    },
    onError: () => {
      setError('headerImageId', {
        type: 'manual',
        message: 'Failed to upload',
      })
    },
  })

  const onReset = useCallback(() => {
    field.onChange(undefined)
    clearErrors('headerImageId')
    setImageUrl(undefined)
    reset()
  }, [field, reset, setError])

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      event.target.value = ''
      if (!file) return

      onReset()

      const fileNameParts = file.name.split('.')
      if (fileNameParts.length === 0) {
        throw new Error('Invalid filename')
      }

      // The filename could have periods, so only use the last part
      const mediaType = fileExtensionToMediaType(fileNameParts[fileNameParts.length - 1])

      if (!ALLOWED_IMAGE_FILE_TYPES.includes(file?.type)) {
        setError('headerImageId', {
          type: 'manual',
          message: 'Your file must be an image',
        })
        return
      }

      const reader = new FileReader()
      reader.onloadend = async () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1] // Remove the data URL prefix
          setImageUrl(`data:${file?.type};charset=utf-8;base64,${base64String}`)
          mutate({
            file: base64String,
            fileName: file.name,
            fileType: 'custom_page_header_image',
            sourceMediaType: mediaType,
          })
        }
      }
      reader.readAsDataURL(file)
    },
    [mutate, onReset, setError]
  )

  const isInvalid = !!error?.message || isError

  return (
    <FormControl isInvalid={isInvalid}>
      <Input type="hidden" {...field} />
      <Input
        type="file"
        accept={ALLOWED_IMAGE_FILE_TYPES.join(',')}
        display="none"
        id="file-input"
        onChange={handleFileChange}
      />
      <Card
        variant="outline"
        boxShadow={0}
        bg="gray.50"
        borderColor={isInvalid ? 'red.500' : 'gray.200'}
        borderWidth="2px"
        borderStyle={isInvalid ? 'solid' : 'dashed'}
      >
        <CardBody
          px={4}
          py={{ base: 6, sm: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDir={{ base: 'column-reverse', sm: 'row' }}
          minH={{ base: 'unset', sm: '96px' }}
          gap={4}
        >
          <ButtonGroup>
            <Button
              as="label"
              htmlFor="file-input"
              cursor="pointer"
              isLoading={isPending}
              pointerEvents={isPending ? 'none' : undefined}
            >
              Browse files
            </Button>
            {imageUrl && (
              <Button variant="secondary" onClick={onReset} isLoading={isPending}>
                Remove
              </Button>
            )}
          </ButtonGroup>
          {imageUrl ? (
            <Box w="331px" h="80px" position="relative">
              <Image src={imageUrl} alt="Image preview" fill style={{ objectFit: 'contain' }} />
            </Box>
          ) : (
            <Stack direction={{ base: 'column', sm: 'row' }} alignItems="center" justify="flex-end">
              <CloudUploadIcon />
              <Text textStyle="small" textAlign="center" maxW="250px" minW="fit-content">
                File formats: jpg, png, gif, webp, svg Preferred dimensions:1288 x 260px
              </Text>
            </Stack>
          )}
        </CardBody>
      </Card>
      <FormErrorMessage>
        <HStack gap={1}>
          <Text>{error?.message}</Text>
          {isError && (
            <Button variant="link" onClick={() => mutate(variables)} size="sm">
              Retry
            </Button>
          )}
        </HStack>
      </FormErrorMessage>
    </FormControl>
  )
}
