import { useCallback } from 'react'
import { getLocalStorageItemByKey, setLocalStorageItem } from 'src/utils/local-storage'
import { useAuth } from 'src/auth'

export function useFavorites(feature = 'futures') {
  const { slug } = useAuth()
  const futuresStorageKey = `bwp-${feature}-favorites`

  const getFavorites = useCallback(() => {
    const favorites = getLocalStorageItemByKey(futuresStorageKey, undefined)
    return favorites?.[slug] ?? []
  }, [futuresStorageKey, slug])

  const setFavorites = useCallback(
    (favorites: (string | number)[]) => {
      const currentFavorites = getFavorites()
      setLocalStorageItem(futuresStorageKey, {
        ...currentFavorites,
        [slug]: favorites,
      })
    },
    [futuresStorageKey, getFavorites, slug]
  )

  return {
    setFavorites,
    getFavorites,
  }
}
