import { memo, useEffect, useRef, useState } from 'react'
import { onlineManager, focusManager, useQueryClient } from '@tanstack/react-query'
import { Session } from 'next-auth'
import {
  TOKEN_EXPIRY_REFRESH_SECS,
  TOKEN_REFRESH_ERROR_CODE,
  getSecondsUntilTokenExpires,
  parseJwt,
} from 'src/auth/keycloak'
import { logger, updateAllAuthTokens } from 'src/utils'
import { useAuth } from 'src/auth/index'
import {
  type AppStateListener,
  getNativeAppClient,
} from 'src/utils/clients/native/native-app-client'
import getClient from 'src/utils/clients/get-client'

/**
 * This component manages the refreshing of the short-lived access token.
 * It is written as a component vs a custom hook so it can be easily inserted in the `Layout` below the `SessionProvider`.
 *
 * The token is automatically refreshed when window visibility changes.
 * However, if the window remains focused, nothing automatically triggers an access token refesh.
 * Therefore, we need to enact a process to monitor and force-refresh the token ahead of its expiry.
 *
 *  This happens as the result of two effects:
 *   - The "watcher" effect starts an interval to check the token periodically. If the token is
 *     is within the `CHECK_INTERVAL_SECS` threshold, it will trigger a token refresh.
 *   - The "updater" effect just watches for the current `useSession()` access token value to change.
 *     When a new token value is detected, it updates the API clients.
 *
 * Because we want these two effects to be separate, the access token value is shadowed in a ref, which
 * is set in the "updater" effect, then referenced in the "watcher" effect. This lets us avoid putting `accessToken`
 * as a dependency of the "watcher" effect, which would complicate the "purity" of the running interval.
 */

const CHECK_INTERVAL_SECS = 15

const LOG_TAG = 'token-watcher'

export const TokenWatcher = memo(function TokenWatcher() {
  const {
    loading,
    authenticated,
    session,
    token: accessToken,
    refreshSession,
    handleNextAuthSignOut,
  } = useAuth()
  const [isAppActive, setIsAppActive] = useState<boolean>(true)
  const isAndroid = getClient().isAndroid
  const isInactiveAndroid = isAndroid && !isAppActive

  useAppStateOnlineListener(refreshSession, setIsAppActive)
  useAuthStatusChanged({ loading, authenticated, handleNextAuthSignOut })
  useTokenChanged({ accessToken, refreshSession, isInactiveAndroid })
  useCheckSessionError({ session: session!, handleNextAuthSignOut })

  return null
})

function useAppStateOnlineListener(
  refreshSession: ReturnType<typeof useAuth>['refreshSession'],
  setIsAppActive: (isActive: boolean) => void
) {
  const nativeAppClient = getNativeAppClient()
  const queryClient = useQueryClient()

  const listener = useRef<AppStateListener>()
  useEffect(() => {
    ;(async () => {
      if (nativeAppClient) {
        listener.current = await nativeAppClient.addAppStateListener(async (currentState) => {
          const isActive = currentState === 'foreground'
          logger.debug({
            message: `[${LOG_TAG}] current app and online state`,
            context: {
              currentState,
              currentOnlineState: onlineManager.isOnline() ? 'online' : 'offline',
              desiredOnlineState: isActive ? 'online' : 'offline',
            },
          })

          if (isActive) {
            await refreshSession()
            await queryClient.cancelQueries() // Any queries queued up most likely have an old token, cancel to have them retry with the new token
          }
          focusManager.setFocused(isActive)
          onlineManager.setOnline(isActive)
          setIsAppActive(isActive)
        })
      }
    })()

    return () => {
      if (listener.current) {
        logger.debug({
          message: `[${LOG_TAG}] TokenWatcher unmounted, removing app state listener`,
          context: { isOnline: onlineManager.isOnline() },
        })
        listener.current.remove()
      }
    }
  }, [nativeAppClient, queryClient, refreshSession, setIsAppActive])
}

function useCheckSessionError({
  session,
  handleNextAuthSignOut,
}: {
  session: Session
  handleNextAuthSignOut: () => Promise<void>
}) {
  const sessionError = session?.error

  useEffect(() => {
    if (!sessionError) return

    if (sessionError === TOKEN_REFRESH_ERROR_CODE) {
      handleNextAuthSignOut()
    }
  }, [sessionError, handleNextAuthSignOut])
}

function useAuthStatusChanged({
  loading,
  authenticated,
  handleNextAuthSignOut,
}: {
  loading: boolean
  authenticated: boolean
  handleNextAuthSignOut: ReturnType<typeof useAuth>['handleNextAuthSignOut']
}) {
  const authenticatedRef = useRef(authenticated)

  useEffect(() => {
    if (loading) {
      logger.debug({
        message: `[${LOG_TAG}] effect | skipping, auth is loading`,
        context: { loading, authenticated, authenticatedRef_current: authenticatedRef.current },
      })
      return
    }

    logger.debug({
      message: `[${LOG_TAG}] effect`,
      context: { loading, authenticated, authenticatedRef_current: authenticatedRef.current },
    })

    if (authenticated && !authenticatedRef.current) {
      logger.debug({
        message: '[token-watcher] effect | auth changed to TRUE',
        context: { authenticated, authenticatedRef_current: authenticatedRef.current },
      })
    }
    if (!authenticated && authenticatedRef.current) {
      logger.debug({
        message: `[${LOG_TAG}] effect | auth changed to FALSE`,
        context: { authenticated, authenticatedRef_current: authenticatedRef.current },
      })
      handleNextAuthSignOut()
    }

    if (authenticatedRef.current !== authenticated) {
      authenticatedRef.current = authenticated
    }
  }, [loading, authenticated, handleNextAuthSignOut])
}

function useTokenChanged({
  accessToken,
  refreshSession,
  isInactiveAndroid,
}: {
  accessToken: string
  refreshSession: ReturnType<typeof useAuth>['refreshSession']
  isInactiveAndroid: boolean
}) {
  const tokenRef = useRef(accessToken)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  // "WATCHER" | Checks token expiry on an interval
  useEffect(() => {
    function clear() {
      if (!intervalRef.current) return
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }

    intervalRef.current = setInterval(() => {
      if (!tokenRef.current) {
        // No token, bail
        return
      }

      const tokenExp = parseJwt(tokenRef.current)?.exp

      if (!tokenExp) {
        // Token is bunk or malformed, bail
        logger.warn({
          message: `[${LOG_TAG}] failed to parse \`exp\` from token`,
          context: { token: tokenRef.current },
        })
        return
      }

      const expiresInSecs = getSecondsUntilTokenExpires(tokenExp)

      logger.debug({
        message: '[token-watcher] check',
        context: { tokenExp, expiresInSecs, isInactiveAndroid },
      })

      if (expiresInSecs < TOKEN_EXPIRY_REFRESH_SECS && !isInactiveAndroid) {
        logger.debug({
          message: `[${LOG_TAG}] triggering session refresh`,
          context: { expiresInSecs },
        })
        // Token expires in < the check interval, refresh session
        refreshSession()
      }
    }, CHECK_INTERVAL_SECS * 1000)

    return () => clear()
  }, [refreshSession, isInactiveAndroid])

  // "UPDATER" | Handles the actual token changing
  useEffect(() => {
    if (!accessToken) return
    if (tokenRef.current === accessToken) return

    logger.debug({
      message: `[${LOG_TAG}] refreshed access token`,
      context: { tokenSnip: accessToken.slice(-20) },
    })

    tokenRef.current = accessToken
    updateAllAuthTokens(accessToken)
  }, [accessToken])
}
