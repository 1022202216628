import { useAuth } from 'src/auth'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'
import { ClientRedirect, ENV, getUserRedirectUri } from 'src/utils'

export default function StaffPageCheck({ children }) {
  const { isStaff, slug, user } = useAuth()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)

  // Need to manually check ENV.MOCK_MERCH_MODE due to MSW2 issue preventing server side context hydration
  if ((isStaff || ENV.MOCK_MERCH_MODE) && isStaffFlagEnabled) return children

  // TODO: This should be a server-side redirect
  return <ClientRedirect path={getUserRedirectUri({ slug, user, isStaffFlagEnabled })} />
}
