import { useCallback, useMemo } from 'react'
import { Box, Button, Stack, Text, useDisclosure, useBreakpoint } from 'src/components/designsystem'
import DisplayConfig, {
  deriveLabel,
  DisplayConfigItem,
} from 'src/components/designsystem/display-config'
import { showDivider } from 'src/components/resource'
import { trackEvent } from 'src/utils/analytics'
import { FloatingModalWithBackButtonBehavior } from './FloatingModalWithBackButtonBehavior'

export interface ModalTableProps<Item> {
  rowItems: Item[]
  displayConfig: DisplayConfigItem<Item>[]
  analyticsCategory: string
  analyticsAction: string
  rowItemTitle?: string
  previewAmount?: number
  setItem: (item: Item) => void
  DesktopActionButtonComponent?: React.FunctionComponent<{ onClick: () => void }>
  MobileActionButtonComponent?: React.FunctionComponent<{ onClick: () => void }>
}

export default function ModalTable<Item>({
  rowItems,
  displayConfig,
  analyticsCategory,
  analyticsAction,
  rowItemTitle = 'Items',
  previewAmount = 5,
  setItem,
  DesktopActionButtonComponent,
  MobileActionButtonComponent,
}: ModalTableProps<Item>) {
  const { isOpen, onClose, onToggle } = useDisclosure()

  const collapsedRowItems = [...rowItems]
  const previewRowItems = collapsedRowItems.splice(0, previewAmount)

  const hasSetItem = !!setItem

  const desktopTemplateColumns = useMemo(
    () => generateTemplateColumns(displayConfig, hasSetItem),
    [displayConfig, hasSetItem]
  )

  return (
    <Box width="100%">
      <DesktopTableHeader
        displayConfig={displayConfig}
        desktopTemplateColumns={desktopTemplateColumns}
      />

      <Box
        borderRadius="0 0 0.75rem 0.75rem"
        width="100%"
        border={['none', null, '1px solid']}
        // Workaround. chakra's being weird and won't apply a non-responsive version of these props
        borderTop={['none', 'none', 'none', 'none']}
        borderColor={['gray.300', 'gray.300', 'gray.300', 'gray.300']}
      >
        <TableRows
          rowItems={previewRowItems}
          displayConfig={displayConfig}
          setItem={setItem}
          desktopTemplateColumns={desktopTemplateColumns}
          DesktopActionButtonComponent={DesktopActionButtonComponent}
          MobileActionButtonComponent={MobileActionButtonComponent}
        />
      </Box>

      {rowItems.length > previewAmount && (
        <ShowHideButton
          {...{
            isOpen,
            analyticsCategory,
            analyticsAction,
            onToggle,
          }}
        />
      )}
      <FloatingModalWithBackButtonBehavior
        isOpen={isOpen}
        primaryTitle={rowItemTitle}
        onClose={onClose}
        backAction={onClose}
      >
        <DesktopTableHeader
          displayConfig={displayConfig}
          desktopTemplateColumns={desktopTemplateColumns}
        />
        <Box
          borderRadius="0 0 0.75rem 0.75rem"
          width="100%"
          border={['none', null, '1px solid']}
          // Workaround. chakra's being weird and won't apply a non-responsive version of these props
          borderTop={['none', 'none', 'none', 'none']}
          borderColor={['gray.300', 'gray.300', 'gray.300', 'gray.300']}
        >
          <TableRows
            rowItems={rowItems}
            displayConfig={displayConfig}
            setItem={setItem}
            desktopTemplateColumns={desktopTemplateColumns}
            DesktopActionButtonComponent={DesktopActionButtonComponent}
            MobileActionButtonComponent={MobileActionButtonComponent}
          />
        </Box>
      </FloatingModalWithBackButtonBehavior>
    </Box>
  )
}

// Need custom label component because tablet labels should follow desktop design instead of mobile design.
export function CustomDisplayConfigLabelComponent({ config, item }) {
  return (
    <Text display={['flex', null, 'none']} mr={6}>
      {deriveLabel({ config, item })}
    </Text>
  )
}

// Need custom render component because tablet cells should follow desktop design instead of mobile design.
export function CustomDisplayConfigRenderComponent({ children }) {
  return (
    <Text textStyle="body-bold" textAlign={['right', null, 'left']}>
      {children}
    </Text>
  )
}

function generateTemplateColumns<Item>(
  displayConfig: DisplayConfigItem<Item>[],
  hasSetItem: boolean
): string {
  const templateColumns = displayConfig.map((item) => item.columnWidth ?? '1fr').join(' ')
  return hasSetItem ? `${templateColumns} 78px` : templateColumns
}

interface DesktopTableHeaderProps<Item> {
  displayConfig: DisplayConfigItem<Item>[]
  desktopTemplateColumns: string
}

function DesktopTableHeader<Item>({
  displayConfig,
  desktopTemplateColumns,
}: DesktopTableHeaderProps<Item>) {
  return (
    <Box
      bg="gray.50"
      borderRadius="0.75rem 0.75rem 0 0"
      border="1px solid"
      borderColor="gray.300"
      px="4"
      py="3"
      width="100%"
      display={['none', null, 'block']}
    >
      <DisplayConfig.HeaderRow
        columnGap={2}
        fontSize="xs"
        fontWeight={700}
        templateColumns={desktopTemplateColumns}
        textAlign="left"
        w="100%"
      >
        {displayConfig.map((config) => (
          <Text
            key={config.label}
            textStyle="preTitle"
            textAlign={config.textAlign}
            pr={[0, null, 2, null, 8]}
          >
            {config.label}
          </Text>
        ))}
      </DisplayConfig.HeaderRow>
    </Box>
  )
}

interface TableRowsProps<Item> {
  rowItems: Item[]
  displayConfig: DisplayConfigItem<Item>[]
  setItem: (item: Item) => void
  desktopTemplateColumns: string
  DesktopActionButtonComponent?: React.FunctionComponent<{ onClick: () => void }>
  MobileActionButtonComponent?: React.FunctionComponent<{ onClick: () => void }>
}

function TableRows<Item>({
  rowItems,
  displayConfig,
  setItem,
  desktopTemplateColumns,
  DesktopActionButtonComponent,
  MobileActionButtonComponent,
}: TableRowsProps<Item>) {
  const { breakpoint, isDesktop } = useBreakpoint()
  const configItemLength = displayConfig.length

  const showDividerFn = useCallback(
    ({ index }) =>
      breakpoint !== 'md' ? showDivider({ index, breakpoint, configItemLength }) : false,
    [breakpoint, configItemLength]
  )

  return (
    <Stack spacing={[2, null, 0]}>
      {rowItems.map((rowItem, rowItemIndex) => {
        const isNotLast = rowItemIndex < rowItems.length - 1

        return (
          <Box
            key={`rowItem-${rowItemIndex}`}
            py={[4, null, 0]}
            // This is the mobile card full border
            border={['1px solid', null, 'none']}
            // This is the tablet / desktop table row bottom border
            borderBottom={isNotLast ? [null, null, '1px solid'] : undefined}
            // Workaround, again
            borderColor={['gray.300', 'gray.300', 'gray.300', 'gray.300']}
            borderRadius={['lg', null, 0, 0]}
          >
            <DisplayConfig.BodyRow
              w="100%"
              px={4}
              templateColumns={['1fr', null, desktopTemplateColumns]}
              rowGap={[1, null, 2]}
              columnGap={['4%', null, 2]}
              alignItems="center"
              alignContent="center"
            >
              <DisplayConfig.RowRenderer
                displayConfig={displayConfig}
                item={rowItem}
                showDivider={showDividerFn}
              />

              {!!DesktopActionButtonComponent && (isDesktop || breakpoint === 'md') && (
                <DesktopActionButtonComponent onClick={() => setItem(rowItem)} />
              )}
            </DisplayConfig.BodyRow>

            {!!MobileActionButtonComponent && !isDesktop && breakpoint !== 'md' && (
              <MobileActionButtonComponent onClick={() => setItem(rowItem)} />
            )}
          </Box>
        )
      })}
    </Stack>
  )
}

interface ShowHideButtonProps {
  isOpen: boolean
  analyticsCategory: string
  analyticsAction: string
  onToggle: () => void
}

function ShowHideButton({
  isOpen,
  analyticsCategory,
  analyticsAction,
  onToggle,
}: ShowHideButtonProps) {
  return (
    <Box fontWeight={700} pt={4} textAlign="center" width="100%">
      <Button
        aria-label={isOpen ? 'Hide' : 'Show All'}
        variant="ghost"
        onClick={() => {
          trackEvent(analyticsCategory, `${analyticsAction} ${isOpen ? 'Collapse' : 'Expand'}`)
          onToggle()
        }}
      >
        {isOpen ? 'Hide' : 'Show All'}
      </Button>
    </Box>
  )
}
