import { AlertCircleIcon, CollapsableDetailTable, Tooltip } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate, useStackedDetailModal } from 'src/components/resource'

const DISPLAY_CONFIG: DisplayConfigItem<ContractTicketApplication>[] = [
  {
    label: 'Scale Ticket',
    key: 'remote_ticket_id',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: () => true, // Don't want to ever em-dash this value
    renderValue: ({ data }) => {
      if (data.ticket_id) return data.remote_ticket_id ?? undefined

      return (
        <>
          {data.remote_ticket_id}
          <Tooltip label="Ticket unavailable" hasArrow placement="top">
            <AlertCircleIcon color="red.500" fontSize="lg" ml={1} mb={0.5} />
          </Tooltip>
        </>
      )
    },
  },
  {
    label: 'Application Date',
    key: 'application_date',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.ticket_id ? null : data.application_date),
    renderValue: ({ data }) => (
      <FormattedDate date={data.application_date ?? undefined} localize={false} />
    ),
  },
  {
    label: 'Gross Qty',
    key: 'gross_quantity',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.ticket_id ? null : data.gross_quantity),
    renderValue: ({ data }) => `${data.gross_quantity} ${data.quantity_uom}`,
  },
  {
    label: 'Net Qty',
    key: 'net_quantity',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.ticket_id ? null : data.net_quantity),
    renderValue: ({ data }) => `${data.net_quantity} ${data.quantity_uom}`,
  },
]

export interface AssociatedTicketsProps {
  ticketApps: ContractTicketApplication[]
}

export default function AssociatedTickets({ ticketApps }: AssociatedTicketsProps) {
  const { setItem } = useStackedDetailModal()

  return (
    <>
      <DetailGrid.Title>Associated Scale Tickets</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={ticketApps}
        rowItemTitle="Tickets"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Contracts"
        analyticsAction="Associated Scale Tickets List"
        setItem={(item) => setItem({ type: 'ticket', item })}
        shouldShowViewButton={(rowItem) => !!rowItem.ticket_id}
      />
    </>
  )
}
