const numberFormatter = new Intl.NumberFormat()

/**
 * Formats a number into a localized string:
 *
 * Examples:
 *   `1234` -\> `1,234`
 *   `1234.98700` -\> `1,234.987`
 * @param num - number to format
 */
export function formatNumber(num: number) {
  return numberFormatter.format(num)
}

export const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting', //this makes negative values display with parentheses
})

/**
 * Standardized currency formatter for USD
 * @param amount - String or number to format
 * @returns String formatted as a currency
 * @example
 * `1234` -\> `$1,234.00`
 */
export function formatCurrency(amount: string | number) {
  return dollarFormatter.format(typeof amount === 'string' ? convertStringToNumber(amount) : amount)
}

function convertStringToNumber(value: string): number {
  // If the value uses accounting format (parenthesis to represent negative), replace those
  // with a minus sign so that it can be parsed.
  if (/\(.*\)/.test(value)) {
    value = '-' + value.replaceAll(/[()]/g, '')
  }

  // remove everything from string except digits and periods
  return parseFloat(value.replaceAll(/[^-\d.]/g, ''))
}
