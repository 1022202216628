import { useState, useCallback } from 'react'

export interface UseDetailModalReturnType<ItemT> {
  item: ItemT | null
  setItem: (item: ItemT) => void
  onClose: () => void
}

export function useDetailModal<ItemT>(): UseDetailModalReturnType<ItemT> {
  const [item, setItem] = useState<ItemT | null>(null)

  return {
    item,
    setItem: useCallback((item: ItemT) => setItem(item), []),
    onClose: useCallback(() => setItem(null), []),
  }
}
