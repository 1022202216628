import { datadogRum } from '@datadog/browser-rum-slim'
import {
  ConfigCatProvider as ConfigCatProviderBase,
  createConsoleLogger,
  LogLevel,
  PollingMode,
  useConfigCatClient,
} from 'configcat-react'
import { useEffect, useMemo } from 'react'
import { useAuth } from 'src/auth'
import { ConfigCatFlags, getConfigCatUser, useConfigCatUser } from 'src/utils/config-cat'
import { ConfigCatSSRProvider } from 'src/utils/config-cat/ssr'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

const { installationId: installationIdClient } = getWindowNextDataProps() as unknown as {
  installationId: string
}

type ConfigCatProviderProps = Readonly<
  React.PropsWithChildren<{
    flags: ConfigCatFlags
    installationId: string | undefined
  }>
>

/**
 * Provides ConfigCat base feature flag implementation, wrapped in a custom context
 * for using the SSR flag evaluations
 */
export default function ConfigCatProviders({
  flags,
  installationId,
  children,
}: ConfigCatProviderProps) {
  const { session, slug } = useAuth()
  const defaultUser = useMemo(
    () => getConfigCatUser(slug, installationId ?? installationIdClient, session),
    [slug, installationId, session]
  )

  return (
    <ConfigCatSSRProvider flags={flags}>
      <ConfigCatProviderBase
        sdkKey={ENV.CONFIGCAT_SDK_KEY}
        pollingMode={PollingMode.AutoPoll}
        options={{
          defaultUser,
          baseUrl: ENV.CONFIGCAT_BASE_URL,
          logger: createConsoleLogger(LogLevel.Error),
          setupHooks: (hooks) =>
            hooks.on('flagEvaluated', ({ key, value }) =>
              datadogRum.addFeatureFlagEvaluation(key, value)
            ),
        }}
      >
        <ConfigCatClientUserSync>{children}</ConfigCatClientUserSync>
      </ConfigCatProviderBase>
    </ConfigCatSSRProvider>
  )
}

/**
 * Custom component to sync the authenticated user with ConfigCat
 * This is intentionally a component since useConfigCatClient relies on the context
 * from ConfigCatProvider, and must be a child of it.
 */
function ConfigCatClientUserSync({ children }: Readonly<React.PropsWithChildren>) {
  const client = useConfigCatClient()
  const user = useConfigCatUser()

  useEffect(() => {
    if (user) {
      client.setDefaultUser(user)
    }
  }, [client, user])

  return <>{children}</>
}
