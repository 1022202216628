import { useBreakpoint as useChakraBreakpoint } from '@chakra-ui/react'
import React, { createContext, useContext, useEffect, useRef } from 'react'
import { create, useStore } from 'zustand'

type UseBreakpointState = {
  breakpoint: ChakraBreakpoint
  isDesktop: boolean
  isMobile: boolean
}

export const AppBreakpointContext = createContext<UseBreakpointState | null>(null)

type AppBreakpointProviderProps = Readonly<
  React.PropsWithChildren<{ initialBreakpoint: ChakraBreakpoint }>
>

type BreakpointStore = UseBreakpointState & {
  setBreakpoint: (breakpoint: ChakraBreakpoint) => void
}

const createBreakpointStore = (initialBreakpoint: ChakraBreakpoint) =>
  create<BreakpointStore>()((set) => ({
    ...deriveBreakpointState(initialBreakpoint),
    setBreakpoint: (breakpoint) => set(() => deriveBreakpointState(breakpoint)),
  }))

/**
 * App level provider that determines information about the current breakpoint.
 */
export function AppBreakpointProvider({ children, initialBreakpoint }: AppBreakpointProviderProps) {
  const store = useRef(createBreakpointStore(initialBreakpoint)).current
  const { setBreakpoint, ...value } = useStore(store)
  const isInitialSet = useRef(false)
  const breakpoint = useChakraBreakpoint({ ssr: true })

  /**
   * Updates the store sent downstream to consumers of `useBreakpoint` only when the
   * breakpoint 'meaningfully' changes. Otherwise, too frequent of updates can
   * cause unnecessary re-renders and side-effects in consuming components.
   */
  useEffect(() => {
    // SSR renders will have no breakpoint
    if (!breakpoint) return

    // Skip updating the store if the initial breakpoint is the same as incoming breakpoint from Chakra
    if (!isInitialSet.current && breakpoint === initialBreakpoint) {
      isInitialSet.current = true
      return
    }

    // Finally, update the store with the new breakpoint and prevent further updates until the next breakpoint change
    isInitialSet.current = true
    setBreakpoint(breakpoint)
  }, [breakpoint, initialBreakpoint, setBreakpoint])

  return <AppBreakpointContext.Provider value={value}>{children}</AppBreakpointContext.Provider>
}

export default function useBreakpoint(): UseBreakpointState {
  const context = useContext(AppBreakpointContext)
  if (!context) {
    throw new Error('useBreakpoint must be used within a AppBreakpointProvider')
  }
  return context
}

const DESKTOP_BREAKPOINTS: ChakraBreakpoint[] = ['lg', 'xl', '2xl']
const MOBILE_BREAKPOINTS: ChakraBreakpoint[] = ['base', 'xs', 'sm']

export function deriveBreakpointState(breakpoint: ChakraBreakpoint): UseBreakpointState {
  return {
    breakpoint,
    isDesktop: DESKTOP_BREAKPOINTS.includes(breakpoint),
    isMobile: MOBILE_BREAKPOINTS.includes(breakpoint),
  }
}
