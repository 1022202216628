import { FormProvider } from 'react-hook-form'
import { Card, CardBody, SimpleGrid, Stack, Text } from 'src/components/designsystem'
import formatDateWithLastUpdated from 'src/utils/string/format-date-with-last-updated'
import ContentSection from './ContentSection'
import FormHeader from './FormHeader'
import FilesSection from './FilesSection'
import SetupSection from './SetupSection'
import useStaffCustomPageForm from './useStaffCustomPageForm'

export default function CustomPageForm({
  customPageDetail,
}: Readonly<{ customPageDetail?: StaffCustomPageDetails }>) {
  const { form, isPublished, onSave, onPublish } = useStaffCustomPageForm(customPageDetail)

  return (
    <FormProvider {...form}>
      <form noValidate>
        <Stack>
          <Card>
            <FormHeader
              id={customPageDetail?.id}
              isPublished={isPublished}
              onSave={onSave}
              onPublish={onPublish}
            />
            <CardBody py={6} px={{ base: 4, sm: 6 }}>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
                <Stack gap={6}>
                  <SetupSection />
                  <ContentSection />
                </Stack>
                <Stack gap={6}>
                  <FilesSection />
                </Stack>
              </SimpleGrid>
            </CardBody>
          </Card>
          {customPageDetail && (
            <Text fontStyle="italic">
              {`Last updated: ${formatDateWithLastUpdated(customPageDetail.updatedAt)}`}
              {customPageDetail.updatedBy ? ` by ${customPageDetail.updatedBy}` : ''}
            </Text>
          )}
        </Stack>
      </form>
    </FormProvider>
  )
}
