import { Spinner, Stack, VStack } from '@chakra-ui/react'
import {
  GenericNonIdealState,
  NoAccountsForm,
  NoAccountsFormProps,
  NonIdealStateProps,
} from 'src/components/designsystem/non-ideal-states'

export const LoadingState = ({ ...props }) => (
  <VStack alignItems="center" {...props}>
    <Spinner />
  </VStack>
)

export function ErrorState({
  header = 'Ope, sorry!',
  subHeader = 'There was an error',
  action,
  ...props
}: Partial<NonIdealStateProps>) {
  return (
    <GenericNonIdealState
      illustration="error-coffee"
      header={header}
      subHeader={subHeader}
      action={action}
      {...props}
    />
  )
}

export function PageNotFoundState({
  header = 'Ope, sorry!',
  subHeader = 'Sorry, looks like you hit a dead end. Go back home.',
  action,
  ...props
}: Partial<NonIdealStateProps>) {
  return (
    <GenericNonIdealState
      isVerticallyCentered
      illustration="not-found"
      header={header}
      subHeader={subHeader}
      action={action}
      {...props}
    />
  )
}

export function NoAccountForm({
  header = 'No account found',
  subHeader = 'Please try to login again or contact your company to connect your account.',
  message,
}: Readonly<Partial<NoAccountsFormProps>>) {
  return (
    <NoAccountsForm
      isVerticallyCentered
      illustration="binoculars"
      illustrationAltText="No accounts found image"
      header={header}
      subHeader={subHeader}
      message={message}
    />
  )
}

export function NoAccountState({
  header = 'No account found',
  subHeader = 'Please try to login again or contact your company to connect your account.',
  action,
}: Partial<NonIdealStateProps>) {
  return (
    <GenericNonIdealState
      isVerticallyCentered
      illustration="binoculars"
      header={header}
      subHeader={subHeader}
      action={action}
    />
  )
}

export function MerchandiserAccessLandingState({
  header = 'Select a customer',
  subHeader = 'Select a customer to get started with this feature. Not sure where to start? Try searching above or hit the button below.',
  action,
  ...props
}: Partial<NonIdealStateProps>) {
  return (
    <GenericNonIdealState
      isVerticallyCentered
      illustration="search-user"
      header={header}
      subHeader={subHeader}
      action={action}
      illustrationWidth={['260px', null, '350px']}
      {...props}
    />
  )
}

export function WalletNotFoundState({
  header = 'Make & receive payments with Bushel Wallet™',
  subHeader = 'Set up your Wallet account today to make and receive payments.',
  action,
  ...props
}: Readonly<Partial<NonIdealStateProps>>) {
  return (
    <GenericNonIdealState
      isVerticallyCentered
      illustration="pig"
      header={header}
      subHeader={subHeader}
      action={action}
      illustrationWidth={['230px', null, '270px']}
      {...props}
    />
  )
}

interface NoResultsStateProps {
  feature: string
  isFiltered: boolean
  action: string | JSX.Element | null
  isVerticallyCentered?: boolean
}

export function NoResultsState({
  feature,
  isFiltered = false,
  action,
  isVerticallyCentered = true,
}: NoResultsStateProps) {
  return (
    <GenericNonIdealState
      isVerticallyCentered={isVerticallyCentered}
      illustration="tumbleweed"
      header={isFiltered ? 'No results found' : 'Nothing here yet'}
      subHeader={
        isFiltered
          ? "Try a different set of filters to find what you're looking for."
          : `There are no ${feature.toLowerCase()} to show, once you have some they will appear here.`
      }
      action={action}
    />
  )
}

export function NoContentState({
  header,
  action,
  subHeader,
}: Readonly<{
  header: string
  action: React.ReactNode
  subHeader?: string
}>) {
  return (
    <Stack flex={1} justify="center">
      <GenericNonIdealState
        illustration="no-content-yet"
        header={header}
        subHeader={subHeader}
        action={action}
      />
    </Stack>
  )
}
