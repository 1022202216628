export const getLocalStorageItemByKey = (key: string, defaultValue: any = null) => {
  if (typeof window !== 'undefined') {
    const stored = window.localStorage.getItem(key)
    if (!stored) return defaultValue

    return JSON.parse(stored)
  }
}

export const setLocalStorageItem = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorageKey = (key: string) => {
  window.localStorage.removeItem(key)
}
