import { PropsWithChildren } from 'react'
import type { AuthzPermission } from 'src/api/bidl/iam/authz-permissions'
import { useHasStaffPermission } from 'src/data/useHasStaffPermission'
import { LoadingState } from 'src/components/resource'
import { GenericNonIdealState } from 'src/components/designsystem'

type StaffPermissionCheckProps = PropsWithChildren<{
  permission: AuthzPermission
}>

export default function StaffPermissionCheck({ permission, children }: StaffPermissionCheckProps) {
  const { hasPermission, isLoading } = useHasStaffPermission(permission)

  if (isLoading) {
    return <LoadingState mt={16} />
  }

  if (!hasPermission) {
    return (
      <GenericNonIdealState
        isVerticallyCentered
        illustration="tumbleweed"
        illustrationAltText="Request Access"
        header="Request Access"
        subHeader="Please reach out to your administrator for access."
      />
    )
  }

  return <>{children}</>
}
