import { useMemo } from 'react'
import {
  AUTHZ_PERMISSIONS_TO_CENTRE_ROLES,
  type AuthzPermission,
} from 'src/api/bidl/iam/authz-permissions'
import { useAuth } from 'src/auth'
import { useHasAuthzPermission } from 'src/data/queries/authorization'
import { useFeatureFlagSSR } from 'src/utils/config-cat/ssr'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'

export function useHasStaffPermission(permission: AuthzPermission) {
  const { hasPermission: hasRole } = useAuth()
  const isAuthzPermissionsFlagEnabled = useFeatureFlagSSR(
    CONFIGCAT_FLAGS.isAuthzPermissionsEnabled,
    false
  )

  const {
    data: hasAuthzPermission,
    isPending: isLoading,
    isError,
  } = useHasAuthzPermission(permission, { enabled: isAuthzPermissionsFlagEnabled })

  const hasPermission = useMemo(() => {
    if (isAuthzPermissionsFlagEnabled) {
      return hasAuthzPermission
    } else {
      if (!Object.keys(AUTHZ_PERMISSIONS_TO_CENTRE_ROLES).includes(permission)) {
        throw new Error(`Permission ${permission} not found in mapper for fallback`)
      }
      return hasRole(AUTHZ_PERMISSIONS_TO_CENTRE_ROLES[permission])
    }
  }, [isAuthzPermissionsFlagEnabled, hasAuthzPermission, hasRole, permission])

  return { hasPermission, isLoading: isAuthzPermissionsFlagEnabled ? isLoading : false, isError }
}
