import { Button } from '@bushelpowered/design-system'
import {
  ButtonGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNext,
  useDisclosure,
  useToast,
} from 'src/components/designsystem'
import { useStaffDeleteCustomPage } from 'src/data/queries/custom-pages'

export default function StaffCustomPageDeletionConfirmationModal({
  id,
  title,
  isOpen,
  onClose,
}: Pick<ReturnType<typeof useDisclosure>, 'isOpen' | 'onClose'> & {
  id: string
  title: string
}) {
  const toast = useToast()
  const { mutate, isPending } = useStaffDeleteCustomPage(id, {
    onSuccess: () => {
      onClose()
      toast({
        title: 'Success',
        description: `'${title}' has been deleted.`,
      })
    },
  })
  return (
    <ModalNext isCentered isOpen={isOpen} onClose={isPending ? () => {} : onClose}>
      <ModalHeader>Delete?</ModalHeader>
      <ModalBody>Are you sure you want to delete this page?</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="secondary" isDisabled={isPending} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => mutate()} isLoading={isPending} variant="destructive">
            Delete
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalNext>
  )
}
