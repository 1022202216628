import { Suspense, useMemo, useState } from 'react'
import { useSession } from 'next-auth/react'
import { useConfig } from 'src/data/config'
import { useGlobalFlagr } from 'src/utils/flagr'
import LazyReactJson from './LazyReactJson'
import {
  destroyCookie,
  DEV_CENTRE_API_COOKIE,
  ENV,
  isServer,
  parseCookies,
  setCookie,
} from 'src/utils'
import { SLUG_COOKIE_NAME } from 'src/app/helpers'
import { useMerchandiser } from 'src/data/merchandiser'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Input,
  Link,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { useBreakpoint } from 'src/components/designsystem'
import getClient from 'src/utils/clients/get-client'
import { getSlug } from 'src/utils/get-slug'
import useOnce from 'src/utils/hooks/use-once'
import { appPluginWrapper } from 'src/utils/clients/native/wrappers/app-plugin-wrapper'
import { useAggregatorConfig } from 'src/data/queries/config'
import { DebugIconButton } from './DebugIconButton'
import { useFeatureFlagsSSR } from 'src/utils/config-cat/ssr'

function SimpleAccordionItem({ title, children }) {
  return (
    <AccordionItem>
      <Heading>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Heading>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  )
}

const JsonAccordionItem = ({ title, src }) => (
  <Suspense>
    <SimpleAccordionItem title={title}>
      <LazyReactJson {...{ src }} collapsed={1} />
    </SimpleAccordionItem>
  </Suspense>
)

function CentreAPITargetOptions({ initValue }) {
  return (
    <SimpleAccordionItem title="Centre API Target">
      <Select
        defaultValue={initValue ?? 'default'}
        onChange={(event) => {
          if (event.target.value === 'default') {
            destroyCookie(DEV_CENTRE_API_COOKIE)
          } else {
            setCookie(undefined, DEV_CENTRE_API_COOKIE, event.target.value)
          }
          window.location.reload()
        }}
      >
        <option value="default">default (env)</option>
        <option value="http://localhost:8923">localhost:8923</option>
        <option value="https://centre.dev.bushelops.com">Dev</option>
        <option value="https://bushel-platform-centre-r0.dev.bushelops.com">R0</option>
        <option value="https://bushel-platform-centre-r1.dev.bushelops.com">R1</option>
        <option value="https://bushel-platform-centre-r2.dev.bushelops.com">R2</option>
        <option value="https://bushel-platform-centre-r3.dev.bushelops.com">R3</option>
        <option value="https://bushel-platform-centre-r4.dev.bushelops.com">R4</option>
        <option value="https://bushel-platform-centre-r5.dev.bushelops.com">R5</option>
        <option value="https://bushel-platform-centre-r6.dev.bushelops.com">R6</option>
      </Select>
    </SimpleAccordionItem>
  )
}

function AcadiaWebPortalTargetOptions() {
  const slug = getSlug()
  const isAndroid = getClient().isAndroid

  return (
    <SimpleAccordionItem title="Web Portal Target">
      Change which Portal the app points at while maintaining the company slug. Does not persist
      when app is closed.
      {isAndroid && <Text fontWeight="bold">Currently not functional on Android.</Text>}
      <CurrentSlugAndUrl />
      {!isAndroid && (
        <HStack pt={4}>
          <Link href={`https://portal.dev.bushelops.com/${slug}`}>
            <Button>Dev</Button>
          </Link>
          <Link href={`https://bushel-web-portal-yeet.dev.bushelops.com/${slug}`}>
            <Button>Yeet</Button>
          </Link>
          <Link href={`https://bushel-web-portal-qa.dev.bushelops.com/${slug}`}>
            <Button>QA</Button>
          </Link>
          <Link href={`https://bushel-web-portal-qa2.dev.bushelops.com/${slug}`}>
            <Button>QA2</Button>
          </Link>
          <Link href={`https://bushel-web-portal-acadia.dev.bushelops.com/${slug}`}>
            <Button>Acadia</Button>
          </Link>
        </HStack>
      )}
    </SimpleAccordionItem>
  )
}

export function DebugInformation() {
  const theme = useTheme()
  const appConfig = useConfig()
  const { data: aggregatorConfigData, error } = useAggregatorConfig()
  const aggregatorConfig = useMemo(
    () =>
      aggregatorConfigData
        ? Object.fromEntries(aggregatorConfigData?.map(({ key, value }) => [key, value]))
        : undefined,
    [aggregatorConfigData]
  )
  const { flags } = useGlobalFlagr()
  const session = useSession()
  const merchandiser = useMerchandiser()
  const shouldShowAdditionalDebugInfo =
    ENV.BUSHEL_ENVIRONMENT &&
    ['yeet', 'dev', 'acadia', 'qa', 'qa2', 'local'].includes(ENV.BUSHEL_ENVIRONMENT)
  const isMockMode = ENV.MOCK_MODE
  const { breakpoint, isDesktop, isMobile } = useBreakpoint()
  const { flags: configCatFlagsSSR } = useFeatureFlagsSSR()

  const [nativeAppVersion, setNativeAppVersion] = useState<string>()
  const isNative = getClient().isNativeApp

  useOnce(() => {
    if (isNative) {
      appPluginWrapper.getInfo().then((info) => setNativeAppVersion(info.version)) // Chaining .then as we can't use await in useEffect
    }
  })

  const sizeAndPlatform = {
    ...{ breakpoint, isDesktop, isMobile },
    platform: getClient().platform,
    webAppVersion: ENV.APP_VERSION ?? 'unknown',
    webBuildId: ENV.BUILD_ID ?? 'unknown',
    nativeAppVersion,
  }

  return (
    <Accordion allowMultiple>
      {shouldShowAdditionalDebugInfo && !isMockMode && (
        <>
          {isNative && <AcadiaWebPortalTargetOptions />}
          <CentreAPITargetOptions initValue={appConfig.cookies['bushel-web-debug-centre-target']} />
          <SlugSwapAccordionItem />
        </>
      )}
      <JsonAccordionItem title="Size and Platform" src={sizeAndPlatform} />
      <JsonAccordionItem title="Flagr Flags" src={flags} />
      <JsonAccordionItem title="ConfigCat Flags" src={configCatFlagsSSR} />
      <JsonAccordionItem title="Company Config" src={appConfig.config?.config} />
      <JsonAccordionItem title="Company Features" src={appConfig.config?.features} />
      {aggregatorConfig && !error ? (
        <JsonAccordionItem title="Aggregator Config" src={aggregatorConfig} />
      ) : (
        <SimpleAccordionItem title="Aggregator Config">
          {error?.message ?? 'Please log in to see aggregator config'}
        </SimpleAccordionItem>
      )}
      <JsonAccordionItem title="Full Theme" src={theme} />
      {!isServer() && window.__NEXT_DATA__ && (
        <>
          <JsonAccordionItem title="Local Next.js Data" src={window.__NEXT_DATA__} />
          <JsonAccordionItem title="Merchandiser Access" src={merchandiser} />
        </>
      )}
      <JsonAccordionItem
        title="NextAuth Session"
        src={{ status: session.status, data: session.data }}
      />
    </Accordion>
  )
}

function SlugSwapAccordionItem() {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      destroyCookie(SLUG_COOKIE_NAME)
      window.location.replace(`/${event.target.value}/`)
    }
  }

  return (
    <SimpleAccordionItem title="Slug Swap">
      The Slug Swap feature (available only in Dev and QA) will update the portal url to the slug
      you enter. You may need to log out and log in again with a user for the new company you
      entered.
      <CurrentSlugAndUrl />
      <Input
        mt={4}
        placeholder="Please type slug and press Enter"
        size="sm"
        onKeyDown={handleKeyDown}
      />
    </SimpleAccordionItem>
  )
}

function CurrentSlugAndUrl() {
  return (
    <>
      <Box pt={3}>Current Slug: {getSlug()}</Box>
      <Box pt={3}>Current URL: </Box>
      <Box fontSize="sm">{window.location.href}</Box>
    </>
  )
}

export function SimpleDebugDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cookies = parseCookies()
  return (
    <>
      <DebugIconButton position="absolute" right={8} top={4} onClick={() => onOpen()} />
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <Accordion allowMultiple>
            <CentreAPITargetOptions initValue={cookies['bushel-web-debug-centre-target']} />
            <SlugSwapAccordionItem />
          </Accordion>
        </DrawerContent>
      </Drawer>
    </>
  )
}
