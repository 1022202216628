import { useRouter } from 'next/router'
import { AUTHZ_PERMISSIONS } from 'src/api/bidl/iam/authz-permissions'
import StaffUserSearchInput from 'src/components/admin/staff-user-search/StaffUserSearchInput'
import CustomerSearchBarButton from 'src/components/layout/header-buttons/CustomerSearchBarButton'
import { useAppData } from 'src/data'
import { useMerchandiser } from 'src/data/merchandiser'
import { useHasStaffPermission } from 'src/data/useHasStaffPermission'

export default function CustomerSearchBar() {
  const {
    isNotAuthenticatedOrErrored,
    isDirectReceivableCustomerSearchEnabled,
    isMerchandiserCustomerSearchEnabled,
  } = useCustomerSearchBar()

  if (isNotAuthenticatedOrErrored) return null

  if (isDirectReceivableCustomerSearchEnabled) {
    return <StaffUserSearchInput size="md" inputProps={{ autoFocus: undefined }} />
  }

  if (isMerchandiserCustomerSearchEnabled) {
    return <MerchandiserCustomerSearchButton />
  }

  return null
}

export function useCustomerSearchBar() {
  const { pathname } = useRouter()
  const { auth } = useAppData()

  const { hasPermission: hasUserViewPermission } = useHasStaffPermission(
    AUTHZ_PERMISSIONS.USER_VIEW
  )

  const isNotAuthenticatedOrErrored = !auth.authenticated || !!auth?.user?.error_message

  const isDirectReceivableCustomerSearchEnabled =
    hasUserViewPermission && pathname.startsWith('/[company]/staff/payments/receivable')

  const isMerchandiserCustomerSearchEnabled =
    hasUserViewPermission && !pathname.startsWith('/[company]/staff')

  const isCustomerSearchBarEnabled =
    isDirectReceivableCustomerSearchEnabled || isMerchandiserCustomerSearchEnabled

  return {
    isNotAuthenticatedOrErrored,
    isDirectReceivableCustomerSearchEnabled,
    isMerchandiserCustomerSearchEnabled,
    isCustomerSearchBarEnabled,
  }
}

function MerchandiserCustomerSearchButton() {
  const { openSearch } = useMerchandiser()
  return <CustomerSearchBarButton onClick={openSearch} />
}
