import { createIcon } from '@chakra-ui/react'

export const QuotesActiveIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentcolor',
  },
  displayName: 'QuotesActiveIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 8.25V21.5a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-19a1 1 0 0 0-1-1h-9.25v5A1.75 1.75 0 0 1 8.5 8.25h-5Zm6.697 2.833a.727.727 0 0 1 .33-.083c.06 0 .121.005.182.017a.753.753 0 0 1 .421.298.594.594 0 0 1 .037.497.69.69 0 0 1-.311.398c-.33.22-.592.453-.787.696-.269.332-.421.735-.458 1.21 0 .044.018.083.055.116.037.022.08.033.128.033h.018c.415 0 .77.122 1.062.365.293.232.44.547.44.945 0 .43-.14.779-.422 1.044-.28.254-.64.381-1.08.381-.598 0-1.05-.199-1.354-.597-.305-.397-.458-.961-.458-1.69 0-1.547.732-2.757 2.197-3.63Zm4.686 0a.727.727 0 0 1 .33-.083c.06 0 .122.005.183.017a.753.753 0 0 1 .42.298.594.594 0 0 1 .037.497.69.69 0 0 1-.31.398c-.33.22-.593.453-.788.696-.268.332-.42.735-.458 1.21 0 .044.019.083.055.116.037.022.08.033.128.033h.019c.415 0 .769.122 1.062.365.293.232.439.547.439.945 0 .43-.14.779-.421 1.044-.28.254-.64.381-1.08.381-.598 0-1.05-.199-1.355-.597-.305-.397-.457-.961-.457-1.69 0-1.547.732-2.757 2.196-3.63Z"
      />
      <path d="m5.31 6.75 3.44-3.44V6.5a.25.25 0 0 1-.25.25H5.31Z" />
    </>
  ),
})

export const QuotesStandardIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentcolor',
  },
  displayName: 'QuotesStandardIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d="M10.197 11.083a.727.727 0 0 1 .33-.083c.06 0 .121.005.182.017a.753.753 0 0 1 .421.298.594.594 0 0 1 .037.497.69.69 0 0 1-.311.398c-.33.22-.592.453-.787.696-.269.332-.421.735-.458 1.21 0 .044.018.083.055.116.037.022.08.033.128.033h.018c.415 0 .77.122 1.062.365.293.232.44.547.44.945 0 .43-.14.779-.422 1.044-.28.254-.64.381-1.08.381-.598 0-1.05-.199-1.354-.597-.305-.397-.458-.961-.458-1.69 0-1.547.732-2.757 2.197-3.63ZM14.883 11.083a.727.727 0 0 1 .33-.083c.06 0 .122.005.183.017a.753.753 0 0 1 .42.298.594.594 0 0 1 .037.497.69.69 0 0 1-.31.398c-.33.22-.593.453-.788.696-.268.332-.42.735-.458 1.21 0 .044.019.083.055.116.037.022.08.033.128.033h.019c.415 0 .769.122 1.062.365.293.232.439.547.439.945 0 .43-.14.779-.421 1.044-.28.254-.64.381-1.08.381-.598 0-1.05-.199-1.355-.597-.305-.397-.457-.961-.457-1.69 0-1.547.732-2.757 2.196-3.63Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.5 1.5-6 6v14a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-19a1 1 0 0 0-1-1h-10Zm-.75 5V4.371L6.371 6.75H8.5a.25.25 0 0 0 .25-.25ZM5 8.25V21h14V3h-8.75v3.5A1.75 1.75 0 0 1 8.5 8.25H5Z"
      />
    </>
  ),
})
