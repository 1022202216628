type KeyObjectDataType = string | number | boolean | undefined | null

/**
 * Constructs a query key for react-query in a structured format.
 * Inspiration https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 *
 * @param featureName - The kebab-case name of the feature (tickets, custom-pages, etc.)
 * @param operation - What kebab-case operation the query is doing (list, get, fetch, delete, etc.)
 * @param additionalKeys - Any additional keys to add to the query key. additionalKeys can only be nested 1 level deep
 * @returns The constructed query key.
 * @example
 *
 * ```json
 * {
 *   filters: {
 *     name: 'a', // Ok
 *     status: ['open'] // Arrays Ok too
 *
 *     // This additional layer is not allowed
 *     dates: {
 *       open: true,
 *       closed: false
 *     }
 *   }
 * }
 * ```
 */
export function queryKeyFactory<
  T extends number | string | Record<string, KeyObjectDataType | KeyObjectDataType[]>,
>(
  featureName: string,
  operation: string,
  ...additionalKeys: (string | Record<string, T>)[]
): (string | object)[] {
  return additionalKeys ? [featureName, operation, ...additionalKeys] : [featureName, operation]
}
