import { Fragment, useRef, useState } from 'react'
import { ResponsiveValue } from '@chakra-ui/react'
import { Box, Flex, Spacer, Text, useBreakpoint, useToken } from 'src/components/designsystem'
import { useConfig } from 'src/data/config'
import { SidebarFooter } from './SidebarFooter'
import { SidebarItem } from './SidebarItem'
import { SidebarGroup, SidebarItem as SidebarItemType } from 'src/components/layout/sidebar/helpers'
import { FLAG_KEYS, useGlobalFlagr } from 'src/utils/flagr'
import getClient from 'src/utils/clients/get-client'
import useSwipeableSidebar from './useSwipeableSidebar'
import SwipeableBackdrop from './SwipeableBackdrop'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'
import { useIsFeatureEnabledCheck } from 'src/utils'
import { useFeatureFlag } from 'configcat-react'
import { useAuth } from 'src/auth'
import { BushelWalletButtonHub } from 'src/components/hub/BushelWalletButtonHub'
import { usePrefetchedPermissions } from 'src/data/queries/authorization'
import { AUTHZ_PERMISSIONS_TO_CENTRE_ROLES } from 'src/api/bidl/iam/authz-permissions'
import { useFeatureFlagsSSR } from 'src/utils/config-cat/ssr'

export interface SidebarProps {
  /** true if the sidebar is pinned open (does not involve hover) */
  isPinned: boolean
  setIsPinned: (isPinned: boolean) => void
  /** the Label value for the currently active item */
  activeItem: string | undefined
  /** Array of sidebar items */
  groups: SidebarGroup[]
  /** return the new activeItem */
  onChange?: (activeItem: string) => void
  /* if this is a staff route, show different style */
  isStaffPage?: boolean
}

const WIDTH_FULLY_OPEN = 60
const WIDTH_DESKTOP_MINIMIZED = 14

export function Sidebar({
  isPinned,
  setIsPinned,
  activeItem,
  groups,
  onChange,
  isStaffPage,
}: SidebarProps) {
  const { authenticated, hasPermission } = useAuth()
  const { value: isBushelWalletButtonEnabled } = useFeatureFlag(
    CONFIGCAT_FLAGS.isBushelWalletButtonEnabled,
    false
  )

  const [isHovered, setIsHovered] = useState(false)
  const { isDesktop } = useBreakpoint()
  const transitionSlow = useToken('transition', 'duration.slow')
  const backdropRef = useRef<HTMLDivElement | null>(null)
  const sidebarRef = useRef<HTMLDivElement | null>(null)

  const { flags } = useGlobalFlagr()
  const { config } = useConfig()
  const isFeatureEnabledCheck = useIsFeatureEnabledCheck()
  const alternativeFeatureNames = config?.config?.alternative_feature_names

  const { getFlagValue } = useFeatureFlagsSSR()
  const isAuthzPermissionsFlagEnabled = getFlagValue(
    CONFIGCAT_FLAGS.isAuthzPermissionsEnabled,
    false
  )

  const { data: listOfPrefetchedPermissions } = usePrefetchedPermissions({
    enabled: isAuthzPermissionsFlagEnabled,
  })

  const enabledGroups = groups
    .map((group) => ({
      ...group,
      items: group.items
        .filter(
          (item) =>
            item.featureFlag !== undefined
              ? Object.values(FLAG_KEYS).includes(item.featureFlag) && flags?.[item.featureFlag]
              : true // If the feature flag was omitted, assume it doesn't need one
        )
        .filter((item) => {
          if (!item?.configCatFeatureFlag) return true // Early return if no ConfigCat flag is set

          return getFlagValue(item.configCatFeatureFlag, false)
        })
        .filter((item) => {
          if (!item.featureName) {
            return typeof item.isEnabled === 'function' ? item.isEnabled() : true
          }

          return isFeatureEnabledCheck(item.featureName, item.isEnabled)
        })
        .filter((item) => {
          if (!item?.staffUserPermission) return true

          if (isAuthzPermissionsFlagEnabled) {
            return listOfPrefetchedPermissions?.includes(item.staffUserPermission)
          } else {
            return hasPermission(AUTHZ_PERMISSIONS_TO_CENTRE_ROLES[item.staffUserPermission])
          }
        }),
    }))
    .filter((group) => group.items?.length > 0)

  const isFloating = getClient().isNativeApp || !isDesktop
  const isFullyOpen = isPinned || isFloating
  const isFullyOpenOrIsHovered = isFullyOpen || isHovered

  useSwipeableSidebar({
    sidebarRef,
    backdropRef,
    isPinned,
    setIsPinned,
    isEnabled: isFloating,
  })

  const isBushelWalletButtonVisible = authenticated && isBushelWalletButtonEnabled && !isStaffPage

  return (
    <>
      <SidebarPusher {...{ transitionSlow, isPinned, isFloating }} />

      {isFloating && <SwipeableBackdrop backdropRef={backdropRef} />}

      <Flex
        key={`${isFloating}`} // resets sidebar styling when switching from floating to non-floating
        ref={sidebarRef}
        bgColor={isStaffPage ? 'gray.700' : 'white'}
        borderRight="1px"
        borderColor="gray.300"
        flexDir="column"
        height="full"
        overflow={isPinned ? 'auto' : 'hidden'}
        position="absolute"
        pt={6}
        // this allows children to use '_groupHover' style properties
        role="group"
        // * these lower breakpoints are only needed if the user is on a
        // * very small device with a mouse, and hovers on the very edge
        width={
          isFullyOpen
            ? WIDTH_FULLY_OPEN
            : {
                base: 0,
                lg: WIDTH_DESKTOP_MINIMIZED,
              }
        }
        _hover={{
          w: { base: isFullyOpen ? WIDTH_FULLY_OPEN : 0, lg: WIDTH_FULLY_OPEN },
          overflow: 'auto',
        }}
        transitionDuration={!isFloating ? transitionSlow : undefined}
        transitionTimingFunction={!isFloating ? 'ease-out' : undefined}
        transform={isFloating ? 'translateX(-240px)' : undefined}
        zIndex={isDesktop && getClient().isWebBrowser ? 'sticky' : 'popover'}
        data-testid="sidebar"
        aria-hidden={isFloating && !isPinned ? 'true' : undefined}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isBushelWalletButtonVisible && (
          <Box display="flex" justifyContent="center" w="100%" pb={4}>
            <BushelWalletButtonHub
              isExpanded={isFullyOpenOrIsHovered}
              showBorder={isFullyOpenOrIsHovered}
            />
          </Box>
        )}

        {enabledGroups
          ?.map((group) => ({
            ...group,
            items: group.items.map((item) => ({
              ...item,
              // update the active icon
              isActive: activeItem === item.ariaLabel,
              // add the onClick handlers
              onClick: (item: SidebarItemType) => onChange && onChange(item.ariaLabel),
              ariaLabel:
                !Array.isArray(item.featureName) &&
                item.featureName &&
                alternativeFeatureNames?.[item.featureName]
                  ? (alternativeFeatureNames[item.featureName] ?? item.ariaLabel)
                  : item.ariaLabel,
            })),
          }))
          .map((group) => {
            return (
              <Fragment key={`label-${group.label}`}>
                {group.label && (
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    flexShrink={0}
                    w="full"
                    position="relative"
                    h={12}
                  >
                    <Text
                      pl={4}
                      pt={1}
                      position="absolute"
                      textTransform="uppercase"
                      fontSize={{
                        base: 'xs',
                        lg: isFullyOpen ? undefined : 0,
                      }}
                      transitionDuration={transitionSlow}
                      transitionTimingFunction="ease-in"
                      fontWeight="bold"
                      textColor={isStaffPage ? 'gray.300' : 'gray.500'}
                      w="max-content"
                      alignSelf="start"
                      _groupHover={{ fontSize: 'xs' }}
                      whiteSpace="nowrap"
                      data-testid={`menu-${group.label}`}
                    >
                      {group.label}
                    </Text>
                    <Box
                      key={`active-line-${group.label}`}
                      ml={4}
                      position="relative"
                      transitionDuration={transitionSlow}
                      transitionTimingFunction="ease-in"
                      opacity={isFullyOpen ? 0 : undefined}
                      fontSize={{
                        base: 0,
                        lg: isFullyOpen ? undefined : 0,
                      }}
                      _groupHover={{ opacity: 0 }}
                      w={6}
                      h="1px"
                      bgColor="gray.500"
                    />
                  </Flex>
                )}
                {group.items.map((item) => (
                  <SidebarItem
                    key={item.path}
                    isPinned={isFullyOpen}
                    isStaffPage={isStaffPage ?? false}
                    {...item}
                  />
                ))}
              </Fragment>
            )
          })}

        <Spacer />

        <SidebarFooter isPinned={isFullyOpen} />
      </Flex>
    </>
  )
}

function SidebarPusher({
  transitionSlow,
  isPinned,
  isFloating,
}: {
  transitionSlow: ResponsiveValue<string>
  isPinned: boolean
  isFloating: boolean
}) {
  return (
    <Flex
      transitionDuration={transitionSlow}
      transitionTimingFunction="ease-out"
      width={
        isPinned && !isFloating
          ? WIDTH_FULLY_OPEN
          : {
              base: 0,
              lg: WIDTH_DESKTOP_MINIMIZED,
            }
      }
    />
  )
}
