import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import * as api from 'src/api'
import useInvalidatePaymentQueries from 'src/components/payments/useInvalidatePaymentQueries'
import { useStaffUserSearch } from 'src/data/queries'
import { useDebounce } from 'use-debounce'
import { create } from 'zustand'

type StaffUserSearchState = {
  searchQuery: string
  onChangeSearchQuery: (searchQuery: string) => void
  selectedUser: SearchUser | null
  onSelectUser: (user: SearchUser | null) => void
}

export const useStaffUserSearchStore = create<StaffUserSearchState>()((set) => ({
  searchQuery: '',
  onChangeSearchQuery: (searchQuery) => {
    set({ searchQuery, selectedUser: null })
  },
  selectedUser: null,
  onSelectUser: (user) => {
    set({ selectedUser: user })

    // If no user is selected, clear the impersonation headers and exit the scope
    if (!user) {
      api.clearCentreAppHeaderImpersonation()
      return
    }

    if (user.userId) {
      api.updateCentreAppHeader('X-Impersonate-Id', String(user.userId))
      // This slightly differs from the merchandiser handlers. We do not need to impersonate the
      // user in payments service, as the currently logged in user is the one who will be paying
    }
  },
}))

export default function useStaffUserSearchPage({ clearOnRouteChange = true } = {}) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const store = useStaffUserSearchStore()
  const [debouncedSearch] = useDebounce(store.searchQuery, 500)
  const userSearchQuery = useStaffUserSearch(
    { query: debouncedSearch },
    { enabled: !!debouncedSearch.length }
  )
  const { onSelectUser: storeOnSelectUser, onChangeSearchQuery: storeOnChangeSearchQuery } = store
  const { isError, isLoading, data, isSuccess } = userSearchQuery

  const invalidatePaymentsQueries = useInvalidatePaymentQueries()

  const onClearUser = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['settlement-list'] })
    invalidatePaymentsQueries()
    storeOnSelectUser(null)
  }, [invalidatePaymentsQueries, queryClient, storeOnSelectUser])

  const onSelectUser = useCallback(
    (user: SearchUser | null) => {
      // This is a hack to emit an event that Layout.tsx is listening for that will scroll the page to the top.
      router.events.emit('routeChangeComplete')
      storeOnSelectUser(user)
    },
    [router.events, storeOnSelectUser]
  )

  const onClear = useCallback(() => {
    storeOnChangeSearchQuery('')
    onClearUser()
  }, [onClearUser, storeOnChangeSearchQuery])

  // One last ditch effort to make sure the staffUserModalStore is cleared by listening for route events.
  useEffect(() => {
    if (!clearOnRouteChange) return
    const clearSelectedUser = () => {
      onSelectUser(null)
      storeOnChangeSearchQuery('')
    }
    router.events.on('routeChangeStart', clearSelectedUser)
    return () => router.events.off('routeChangeStart', clearSelectedUser)
  }, [clearOnRouteChange, onSelectUser, router.events, storeOnChangeSearchQuery])

  const isSearching = isLoading && debouncedSearch.length > 0
  const isIdle = !isSuccess && !debouncedSearch.length

  const users = useMemo(
    () => data?.data.filter((user) => user.accounts.some((account) => account.bushel_id)) ?? [],
    [data?.data]
  )
  const isEmpty = isSuccess && !users.length

  return useMemo(
    () => ({
      ...store,
      users,
      isSearching,
      isIdle,
      isLoading,
      isError,
      isEmpty,
      onClear,
      onClearUser,
      onSelectUser,
      userSearchQuery,
    }),
    [
      store,
      users,
      isSearching,
      isIdle,
      isLoading,
      isError,
      isEmpty,
      onClear,
      onClearUser,
      onSelectUser,
      userSearchQuery,
    ]
  )
}
