import { ReactNode } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  FilterButton,
  FilterFooter,
  useDisclosure,
} from 'src/components/designsystem'

export interface FilterMenuProps {
  label: string
  title: string
  isSelected: boolean
  footerOnSet?: () => void
  footerOnClear: () => void
  setButtonLabel?: string
  children?: ReactNode
}

export function FilterMenu({
  label,
  title,
  isSelected,
  footerOnSet,
  footerOnClear,
  setButtonLabel,
  children,
}: FilterMenuProps) {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
      <PopoverTrigger>
        <FilterButton variant={isSelected ? 'selected' : 'unselected'} title={title}>
          {label}
        </FilterButton>
      </PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverBody minWidth="322px" p={0}>
            {children}
            <FilterFooter
              onSet={() => {
                footerOnSet?.()
                onClose()
              }}
              onClear={() => footerOnClear && footerOnClear()}
              setButtonLabel={setButtonLabel}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
