import { useNavigation } from 'src/data'
import { NoContentState, PageHeadingTitle, ResourceListPage } from 'src/components/resource'
import { trackEvent } from 'src/utils/analytics'
import {
  CUSTOM_PAGES_FEATURE_NAME,
  StaffCustomPageDeletionConfirmationModal,
} from 'src/features/staff'
import Header, { NewPageButton } from './Header'
import { ListItem, ExpandedListItemModal } from './list-item'
import useStaffCustomPagesList from './useStaffCustomPagesList'

export const CUSTOM_PROGRAMS_PAGE_TITLE = 'Custom Pages'

export default function StaffCustomPagesListPage() {
  useNavigation({
    pageTitle: CUSTOM_PROGRAMS_PAGE_TITLE,
    activeItem: CUSTOM_PROGRAMS_PAGE_TITLE,
    analyticsTitle: CUSTOM_PROGRAMS_PAGE_TITLE,
  })

  const {
    query,
    columnConfig,
    detailModal,
    actionMenuConfig,
    deletionModalDisclosure,
    itemToDelete,
    onDelete,
  } = useStaffCustomPagesList()

  if (query.data?.length === 0) {
    return (
      <>
        <PageHeadingTitle title={CUSTOM_PROGRAMS_PAGE_TITLE}>
          {CUSTOM_PROGRAMS_PAGE_TITLE}
        </PageHeadingTitle>
        <NoContentState
          header="Add a custom page"
          subHeader="Share content specific to your business. Select the ‘New Page’ button to begin. Each page will appear to your customers as a unique navigation item."
          action={<NewPageButton variant="primary" />}
        />
      </>
    )
  }

  return (
    <>
      <ResourceListPage
        text={{
          title: CUSTOM_PROGRAMS_PAGE_TITLE,
          feature: CUSTOM_PROGRAMS_PAGE_TITLE,
          errorList: 'Unable to retrieve custom pages, please refresh the page to try again.',
        }}
        actionMenuConfig={actionMenuConfig}
        columnConfig={columnConfig}
        detailModal={
          detailModal.item && (
            <ExpandedListItemModal
              actionMenuConfig={actionMenuConfig}
              item={detailModal.item}
              onDelete={onDelete}
              onClose={() => {
                detailModal.onClose()
                trackEvent(`${CUSTOM_PROGRAMS_PAGE_TITLE} Management`, 'Mobile Detail Close')
              }}
            />
          )
        }
        featureName={CUSTOM_PAGES_FEATURE_NAME}
        header={<Header hideNewButton={query.isLoading} />}
        itemQuery={query}
        items={query.data ?? []}
        isExpandable
        isFiltered={false}
        renderRowItem={(item) => (
          <ListItem
            key={item.id}
            actionMenuConfig={actionMenuConfig}
            analyticsTitle={CUSTOM_PROGRAMS_PAGE_TITLE}
            columnConfig={columnConfig}
            detailPrimaryTitle={item.title}
            item={item}
            onDelete={onDelete}
            setDetailItem={detailModal.setItem}
          />
        )}
      />
      {itemToDelete && (
        <StaffCustomPageDeletionConfirmationModal
          {...deletionModalDisclosure}
          id={itemToDelete.id}
          title={itemToDelete.title}
        />
      )}
    </>
  )
}
