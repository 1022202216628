import { syncWithNotify } from 'src/app/native-app-setup/sync-with-notify'
import { Optional } from 'src/utils/types/optional'
import { AppStateListener, getNativeAppClient } from 'src/utils/clients/native/native-app-client'

let listener: Optional<AppStateListener> = undefined

export async function startSyncingWithNotifyWhenAppEntersForeground(userGroupId: Optional<string>) {
  removeOldListenerIfNeeded()
  await addNewListener(userGroupId)
}

function removeOldListenerIfNeeded() {
  listener?.remove()
}

async function addNewListener(userGroupId: Optional<string>) {
  const nativeAppClient = getNativeAppClient()!

  listener = await nativeAppClient.addAppStateListener((appState) => {
    if (appState === 'foreground') {
      syncWithNotify(userGroupId)
    }
  })
}
