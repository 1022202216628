import { Radio, RadioGroup, OverflowStack } from 'src/components/designsystem'
import { BaseFilterProps, FilterOptionItem } from 'src/utils/filters'

export interface RadioFilterProps<FOI = FilterOptionItem[]> extends BaseFilterProps<FOI> {
  options: FOI
  isScrollable?: boolean
}

// just a radio button UI for the checkbox filter
export function RadioFilter({
  filterId,
  onChange,
  options,
  values,
  isScrollable,
}: RadioFilterProps) {
  return (
    <RadioGroup
      key={`${filterId.toString()}-${values && values.length > 0 ? values[0].id : null}`}
      onChange={(value) => onChange(options.filter((obj) => obj.id === value))}
      value={values && values.length > 0 ? values[0].id : undefined}
    >
      <OverflowStack p={4} isScrollable={isScrollable}>
        {options?.map((option) => {
          return (
            <Radio key={`${option.id}`} value={option.id} width="full">
              {option.name}
            </Radio>
          )
        })}
      </OverflowStack>
    </RadioGroup>
  )
}
