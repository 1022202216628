import {
  APIContractV3FilterOptions,
  ContractFiltersForQuery,
} from 'src/components/contracts/ContractFilters'
import { centreClient } from './client'

interface ContractResponseV3 {
  data: ContractV3[]
  meta: { pagination: Pagination }
}

export const contractsRequests = {
  contractsV3: async ({
    filter,
    page,
  }: { filter?: ContractFiltersForQuery; page?: number } = {}) => {
    let options = {}

    if (page) {
      options = { ...options, searchParams: { page } }
    }

    if (filter && Object.keys(filter).length > 0) {
      options = { ...options, json: { filter } }
    } else {
      options = { ...options, json: { filter: {} } }
    }

    return centreClient.post('api/v1/contracts', options).json<ContractResponseV3>()
  },

  contractV3: async (id: number | string) => {
    const response = await centreClient
      .get(`api/v1/contracts/${id}?include-ticket-applications=false`)
      .json<{ data: ContractDetailV3 }>()

    return response.data
  },

  pricingLine: async (id: number | string) => {
    const response = await centreClient
      .get(`api/v1/pricing-lines/${id}`)
      .json<{ data: ContractPricingLineDetail }>()

    return response.data
  },

  contractFilterOptionsV3: async () => {
    const response = await centreClient
      .get('api/v1/filters/contracts')
      .json<{ data: APIContractV3FilterOptions }>()

    return response.data
  },

  contractV3AssociatedSettlements: async (id: number) => {
    const response = await centreClient
      .get(`api/v1/contracts/${id}/associated-settlements`)
      .json<{ data: AssociatedSettlement[] }>()

    return response.data
  },

  contractV3AssociatedTickets: async (id: number) => {
    const response = await centreClient
      .get(`api/v1/contracts/${id}/associated-tickets`)
      .json<{ data: ContractAssociatedTicket[] }>()

    return response.data
  },

  contractsV3Export: async (filter: { [key: string]: any } = {}) => {
    return centreClient.post('api/v1/contracts/export', {
      json: { filter },
    })
  },
}
