import { create } from 'zustand'

type SidebarFeature = 'debug' | 'notifications' | 'help' | 'account'

export interface RightSidebarStore {
  isOpen: boolean
  feature: SidebarFeature
  openRightSidebar: (feature: SidebarFeature) => void
  closeRightSidebar: () => void
  toggleRightSidebar: (feature: SidebarFeature) => void
  checkIfNotificationHistoryIsOpen: () => boolean
}

export const useRightSidebarStore = create<RightSidebarStore>()((set, get) => ({
  isOpen: false,
  feature: 'notifications' as SidebarFeature,

  openRightSidebar: (newFeature: SidebarFeature) => set({ isOpen: true, feature: newFeature }),

  closeRightSidebar: () => set({ isOpen: false }),

  toggleRightSidebar: (newFeature: SidebarFeature) => {
    const { isOpen, feature: currentFeature, openRightSidebar, closeRightSidebar } = get()
    const featuresAreTheSame = currentFeature === newFeature

    if (isOpen && featuresAreTheSame) {
      closeRightSidebar()
    } else {
      openRightSidebar(newFeature)
    }
  },

  checkIfNotificationHistoryIsOpen: () => {
    const { isOpen, feature } = get()
    return isOpen && feature === 'notifications'
  },
}))
