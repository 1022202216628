import { Box, Skeleton, Stack } from 'src/components/designsystem'
import { SectionSubHeading } from './SectionHeading'

type HeaderImageSectionProps = Readonly<
  { isLoading: boolean } & Pick<StaffCustomPageDetails, 'headerImageFileLink'>
>

export default function HeaderImageSection({
  isLoading,
  headerImageFileLink,
}: HeaderImageSectionProps) {
  if (!isLoading && !headerImageFileLink) {
    return null
  }

  return (
    <Stack gap={3}>
      {!isLoading && <SectionSubHeading>HEADER IMAGE</SectionSubHeading>}
      <Skeleton
        aria-label={isLoading ? 'Loading...' : undefined}
        isLoaded={!isLoading}
        rounded="lg"
      >
        <Box
          w="100%"
          h="166px"
          data-testid="header-image"
          backgroundImage={headerImageFileLink}
          backgroundPosition="center"
        />
      </Skeleton>
    </Stack>
  )
}
