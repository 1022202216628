import { chakra, Box, Flex } from '@chakra-ui/react'
import { MonthHeader, MonthGrid, Month, Days, Weekdays, dayStyles } from './hooks'

interface MonthsProps {
  months: Month[]
}

export default function Months({ months }: MonthsProps) {
  const isMultiMonth = months.length > 1

  return (
    <Flex px={5} pb={4}>
      {months.map((month, index) => {
        return (
          <Box key={month.key} mr={isMultiMonth && index === 0 ? 2 : undefined}>
            {isMultiMonth && (
              <Box textAlign="center" color="gray.500">
                <MonthHeader month={month} format="MMMM YYYY" />
              </Box>
            )}

            <MonthGrid month={month}>
              <Weekdays>
                {(weekday) => (
                  <Box
                    as="span"
                    key={weekday}
                    fontWeight={700}
                    color="gray.700"
                    sx={{ placeSelf: 'center' }}
                  >
                    {weekday.slice(0, 1)}
                  </Box>
                )}
              </Weekdays>

              <Days>
                {(day) => {
                  const {
                    props: { key, ...dayProps },
                  } = day
                  return (
                    <chakra.div
                      sx={{
                        ...dayStyles(day, {
                          backgroundColor: 'blue.50',
                          weekdayColor: 'gray.700',
                          weekendColor: 'gray.700',
                          todayColor: 'blue.400',
                        }),
                      }}
                    >
                      <chakra.button key={key} {...dayProps} disabled={day.isDisabled}>
                        {day.number}
                      </chakra.button>
                    </chakra.div>
                  )
                }}
              </Days>
            </MonthGrid>
          </Box>
        )
      })}
    </Flex>
  )
}
