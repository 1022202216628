import * as NonIdealStates from 'src/components/resource/NonIdealStates'
import { useCompany } from 'src/data/use-company'

export function NoAccountsState({ message }) {
  const company = useCompany()
  const companyName = company?.company_name

  const accountFormMessage: string = `Your account could not be found. Please enter your name and location and ${companyName} will contact you.`

  return <NonIdealStates.NoAccountForm subHeader={accountFormMessage} message={message} />
}
