import { NoAccountsState } from 'src/components/resource/NoAccountsState'
import { useAuth } from 'src/auth'

export function NoAccountsPageCheck({ children }) {
  const { user } = useAuth()

  return user?.error_message ? <HasNoAccounts message={user?.error_message} /> : children
}

function HasNoAccounts({ message }: { message: string }) {
  return <NoAccountsState message={message} />
}
