import { bidlClient } from 'src/api/bidl/client'

const aggregatorIamBase = 'api/aggregator/iam'

export const iamRequests = {
  inviteStaffUser: (body: InviteStaffUserRequest) =>
    bidlClient
      .post(`${aggregatorIamBase}/v1/InviteStaffUser`, {
        json: body,
      })
      .json<InviteStaffUserResponse>(),

  getStaffUserRoleBindings: (id: string) =>
    bidlClient
      .post(`${aggregatorIamBase}/v1/GetStaffUserRoleBindings`, {
        json: { id },
      })
      .json<StaffUserRoleBinding>(),

  removeStaffUser: (userId: string) =>
    bidlClient
      .post(`${aggregatorIamBase}/v1/RemoveStaffUser`, {
        json: {
          userId,
        },
      })
      .json(),
}
