import { ReactNode } from 'react'
import { ChakraModal, ModalContent, ModalBody } from 'src/components/designsystem'
import { useHeaderHeightStore } from 'src/components/layout/Header'

interface ModalProps {
  onClose?: () => void
  isOpen?: boolean
  children: ReactNode
}

// NOTE: This does not yet handle desktop sizes correctly
export default function Modal({ onClose, isOpen = true, children }: Readonly<ModalProps>) {
  const { height: headerHeight } = useHeaderHeightStore()

  const containerProps = {
    zIndex: 'detail-modal',
  }

  return (
    <ChakraModal
      isOpen={isOpen}
      size="full"
      onClose={() => {
        onClose?.()
      }}
      scrollBehavior="inside"
    >
      {/* z-index & white background are applied to the modal overlay in mobile view to hide underlying content. See: JIRA MWS-525 */}

      <ModalContent
        boxShadow="none"
        bg={['white', 'gray.50']}
        containerProps={containerProps}
        width="100%"
        top={headerHeight}
        position="absolute"
        bottom={0}
        overflow="auto"
        minHeight="unset"
        maxHeight="unset"
      >
        <ModalBody overflow="hidden" py={0}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
