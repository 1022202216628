import { useMemo } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getNextPageParam } from 'src/data/queries/utils'
import { useMerchandiser } from 'src/data/merchandiser'
import * as api from 'src/api'
import {
  APIContractV3FilterOptions,
  ContractV3FiltersForQuery,
} from 'src/components/contracts/ContractFilters'

export function useContractFilterOptionsV3() {
  const { selectedUserIdpId } = useMerchandiser()

  return useQuery<APIContractV3FilterOptions>({
    queryKey: ['contract-filter-options-v3', { selectedUserIdpId }],
    queryFn: () => api.centre.contractFilterOptionsV3(),
    staleTime: Infinity,
  })
}

export function useContractsV3({
  filter,
}: {
  filter?: ContractV3FiltersForQuery
} = {}) {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['contract-list-v3', { selectedUserIdpId, filter }],
    queryFn: ({ pageParam }) => api.centre.contractsV3({ filter, page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve contracts, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo<ContractV3[]>(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useContractDetailV3({
  id,
  enabled = true,
}: {
  id: string | number
  enabled?: boolean
}) {
  return useQuery<ContractDetailV3, api.HTTPError>({
    queryKey: ['contract-detail-v3', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')
      return api.centre.contractV3(id)
    },
    enabled,
    staleTime: Infinity,
  })
}

export function usePricingLineDetail({
  id,
  enabled = true,
}: {
  id: string | number
  enabled?: boolean
}) {
  return useQuery<ContractPricingLineDetail, api.HTTPError>({
    queryKey: ['contract-v3-pricing-line-detail', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')

      return api.centre.pricingLine(id)
    },
    enabled,
    staleTime: Infinity,
  })
}

export function useContractAssociatedSettlements({ id }: { id?: number }) {
  return useQuery<AssociatedSettlement[], api.HTTPError>({
    queryKey: ['contract-v3-associated-settlement', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')

      return api.centre.contractV3AssociatedSettlements(id)
    },
    staleTime: Infinity,
  })
}

export function useContractAssociatedTickets({ id }: { id?: number }) {
  return useQuery<ContractAssociatedTicket[], api.HTTPError>({
    queryKey: ['contract-v3-associated-tickets', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')

      return api.centre.contractV3AssociatedTickets(id)
    },
    enabled: id !== undefined,
    staleTime: Infinity,
  })
}
