import { ReactNode } from 'react'
import { ButtonGroupProps } from '@chakra-ui/react'
import { ButtonGroup } from 'src/components/designsystem'

export default function BottomButtonBar({
  children,
  ...props
}: Readonly<{ children: ReactNode } & ButtonGroupProps>) {
  return (
    <ButtonGroup
      zIndex={1}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      pt={4}
      px={6}
      pb={8}
      bgColor="white"
      boxShadow="lg"
      {...props}
    >
      {children}
    </ButtonGroup>
  )
}
