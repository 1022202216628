import { ReactElement, useMemo } from 'react'
import { Button, Flex, Modal, VStack, useRadioGroup } from 'src/components/designsystem'
import { RadioCard } from 'src/components/tickets/RadioCard'
import { FileExportOption, FileType, useFileExport, UseFileExportReturnType } from 'src/data'
import { trackEvent } from 'src/utils/analytics'
import {
  ContractFiltersForQuery,
  ContractV3FiltersForQuery,
} from 'src/components/contracts/ContractFilters'
import { useContractsExportConfig } from 'src/data/queries'
import * as api from 'src/api'
import { useConfig } from 'src/data/config'

export function useContractsExportsAndReports({
  slug,
  filterForQuery,
  shouldCheckContractsReportConfig = false,
}: {
  slug: string
  filterForQuery: ContractFiltersForQuery | ContractV3FiltersForQuery
  shouldCheckContractsReportConfig?: boolean
}) {
  const contractsConfigQuery = useContractsExportConfig({
    slug,
    enabled: shouldCheckContractsReportConfig,
  })

  const { contracts } = useConfig()
  const contractsVersion = contracts.contractsVersion()

  const contractsReportIsEnabled = contractsConfigQuery?.data?.enabled ?? false
  const contractsReportTitle = contractsConfigQuery?.data?.title

  const dataExport = useFileExport({
    options: useMemo(() => {
      return [
        {
          key: 'csv',
          title: 'CSV',
          message: 'Contracts will export as a .csv',
          handler: () =>
            contractsVersion === '3.0.0'
              ? api.centre.contractsV3Export(filterForQuery)
              : api.centre.contractsExport(filterForQuery),
          fileType: FileType.CSV,
          analyticsAction: 'Reports/CSV',
        },
        ...(contractsReportIsEnabled
          ? [
              {
                key: 'contracts-pdf',
                title: contractsReportTitle,
                message: 'Contracts will export as a .pdf',
                handler: () => api.reportingService.contractsPdfReport(filterForQuery),
                fileType: FileType.PDF,
                analyticsAction: 'Reports/PDF',
              },
            ]
          : []),
      ] as FileExportOption[]
    }, [contractsReportIsEnabled, contractsReportTitle, contractsVersion, filterForQuery]),
  })

  return {
    dataExport,
    contractsConfigQueryIsSuccess: contractsConfigQuery.isSuccess,
  }
}

export function ExportModal({
  isOpen,
  isLoading,
  exportIsDisabled,
  body,
  onClose,
  onExport,
}: Readonly<{
  body: ReactElement
  isOpen: boolean
  exportIsDisabled: boolean
  isLoading: boolean
  onClose: () => void
  onExport: () => void
}>) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      title="Export"
      body={body}
      onClose={onClose}
      footer={
        <Flex w={['100%', '50%']}>
          <Button variant="secondary" mr={3} isDisabled={isLoading} onClick={onClose} w="100%">
            Cancel
          </Button>
          <Button
            aria-label="Export"
            data-testid="contracts-export-button"
            variant="primary"
            isDisabled={isLoading || exportIsDisabled}
            isLoading={isLoading}
            onClick={onExport}
            w="100%"
          >
            Export
          </Button>
        </Flex>
      }
    />
  )
}

export function ContractsExportModal({
  dataExport,
}: Readonly<{
  dataExport: UseFileExportReturnType
}>) {
  const multipleOptionsEnabled = dataExport.options.length > 1

  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'exportType',
    defaultValue: 'csv',
  })

  return (
    <ExportModal
      body={
        multipleOptionsEnabled ? (
          <VStack {...getRootProps()}>
            {dataExport.options.map(({ title, key, message }) => {
              return (
                <RadioCard
                  radioProps={{ ...getRadioProps({ value: key }) }}
                  title={title}
                  key={key}
                  message={message}
                />
              )
            })}
          </VStack>
        ) : (
          <>Contracts will export as a .csv</>
        )
      }
      isOpen={dataExport.isOpen}
      onClose={dataExport.onClose}
      isLoading={dataExport.fileExportMutation.isPending}
      exportIsDisabled={value === '' && multipleOptionsEnabled}
      onExport={() => {
        const selectedOption = dataExport.options.find((option) => value === option.key)

        if (selectedOption) {
          trackEvent('Contracts', selectedOption.analyticsAction)

          dataExport.fileExportMutation.mutate({
            exportFileHandler: selectedOption.handler,
            fileType: selectedOption.fileType,
          })
        }
      }}
    />
  )
}

export default ContractsExportModal
