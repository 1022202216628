import { nativePushNotifications } from 'src/utils/clients/native/native-push-notifications'
import { getAnonymousGroupId } from 'src/utils/push-notifications/group-id/anonymous-group-id'
import { getUserGroupId, setUserGroupId } from 'src/utils/push-notifications/group-id/user-group-id'
import { registerWithNotify } from 'src/utils/push-notifications/register-with-notify'
import { unregisterFromNotify } from 'src/utils/push-notifications/unregister-from-notify'

export async function syncWithNotify(userGroupId?: string) {
  const isUserLoggedIn = userGroupId !== undefined

  if (isUserLoggedIn) {
    await setUserGroupId(userGroupId)
    await syncForLoggedIn(userGroupId)
  } else {
    await syncForLoggedOut()
  }
}

async function syncForLoggedIn(userGroupId: string) {
  const [pushIsEnabled, anonymousGroupId] = await Promise.all([
    nativePushNotifications.enabled(),
    getAnonymousGroupId(),
  ])

  if (pushIsEnabled) {
    await registerWithNotify(userGroupId)
  } else {
    await unregisterFromNotify(userGroupId)
  }

  if (userGroupId && anonymousGroupId) {
    await unregisterFromNotify(anonymousGroupId)
  }
}

async function syncForLoggedOut() {
  const [pushIsEnabled, anonymousGroupId, userGroupId] = await Promise.all([
    nativePushNotifications.enabled(),
    getAnonymousGroupId(),
    getUserGroupId(),
  ])

  if (anonymousGroupId) {
    if (pushIsEnabled) {
      await registerWithNotify(anonymousGroupId)
    } else {
      await unregisterFromNotify(anonymousGroupId)
    }
  }

  if (userGroupId) {
    await unregisterFromNotify(userGroupId)
  }
}
