import { MutableRefObject, UIEvent, useCallback, useEffect, useState } from 'react'

/**
 * Hook that determines if a click was outside one or more elements.
 * Handles both `touchstart` and `mousedown` for desktop/mobile.
 * If click is contained in any of the supplied refs, the handler is not called.
 *
 * @param refs -
 * @param handler -
 * @returns undefined
 */
export function useOnClickOutside(refs: MutableRefObject<HTMLElement>[], handler: () => void) {
  const isTouch = typeof window !== 'undefined' && typeof window.ontouchstart !== 'undefined'
  const eventName = isTouch ? 'touchstart' : 'mousedown'

  useEffect(() => {
    const listener = (event) => {
      const containedInOne = refs.some(
        (extraRef) => extraRef.current && extraRef.current.contains(event.target)
      )

      if (containedInOne) return

      handler()
    }

    document.addEventListener(eventName, listener)

    return () => {
      document.removeEventListener(eventName, listener)
    }
  }, [refs, handler, eventName])
}

/**
 * Hook that tracks an element's Scroll Top attribute
 * Example: https://codesandbox.io/s/add-a-border-on-scroll-forked-0jd164?file=/src/index.js
 */
export function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0)

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => setScrollTop(event.currentTarget.scrollTop),
    []
  )
  return [scrollTop, { onScroll }] as const
}
