import { Text, DonutBite, DonutChart } from 'src/components/designsystem'

export interface ContractQuantitiesChartProps {
  contract: ContractDetail | null | undefined
}

export function ContractQuantitiesChart({ contract }: ContractQuantitiesChartProps) {
  if (!contract) return null

  const contracted = contract.quantity_contracted_numeric || 0
  const canceled = contract.quantity_canceled_numeric || 0
  const remaining = contract.quantity_remaining_numeric || 0
  const submitted = contract.quantity_submitted_numeric || 0

  const bites: DonutBite[] = []
  const isClosed = contract.status === 'Closed'
  const filledThreshold = contracted * 0.001

  if (remaining <= 0) {
    bites.push({
      name: `${submitted}/${contracted - canceled} ${contract.quantity_uom}`,
      value: 1,
      color: 'green',
    })
  } else if (isClosed || remaining <= filledThreshold) {
    bites.push({
      name: `${submitted}/${contracted - canceled} ${contract.quantity_uom}`,
      value: submitted + canceled,
      color: 'green',
    })
    bites.push({
      name: `Qty Remaining: ${contract.quantity_remaining}`,
      value: remaining,
      color: 'lightgray',
    })
  } else {
    if (canceled > 0) {
      bites.push({
        name: `Qty Canceled: ${contract.quantity_canceled}`,
        value: canceled,
        color: 'red',
      })
    }
    if (submitted > 0) {
      bites.push({
        name: `Qty Delivered: ${contract.quantity_submitted}`,
        value: submitted,
        color: 'yellow',
      })
    }
    if (remaining > 0) {
      bites.push({
        name: `Qty Remaining: ${contract.quantity_remaining}`,
        value: remaining,
        color: 'lightgray',
      })
    }
  }

  const donutChartData = { showInnerCheck: isClosed, bites }

  return (
    <>
      <DonutChart {...{ data: donutChartData, reduceMotion: false, size: 225 }} />
      <Text>
        {(submitted + canceled).toLocaleString()}/{contract.quantity_contracted}
      </Text>
    </>
  )
}
