import { centreClient } from './client'

export const microserviceRequests = {
  getUserNotificationConfig: async (userId: string) => {
    return centreClient.get(`api/internal/microservices/user-notification-config/${userId}`).json<{
      data: UserNotificationConfig
    }>()
  },

  updateUserNotificationConfig: async ({
    id,
    esign_completed,
  }: UpdateUserNotificationConfigRequest) => {
    return centreClient.post(`api/internal/microservices/user-notification-config/${id}`, {
      json: {
        esign_completed,
      },
    })
  },
}
