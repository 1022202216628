import { useCallback } from 'react'
import {
  createStandaloneToast,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react'
import { theme } from 'src/themes'

const { toast: standaloneToast } = createStandaloneToast({ theme })

export const useToast = () => {
  const toast = useChakraToast()

  const handleToast = ({
    title,
    description,
    status = 'success',
    position = 'bottom',
    duration = status ? 5000 : 10000,
    isClosable = true,
    ...rest
  }: UseToastOptions) => {
    toast({
      title,
      description,
      status,
      position,
      duration,
      isClosable,
      ...rest,
    })
  }
  return useCallback(handleToast, [toast])
}

export { standaloneToast }
