import * as yup from 'yup'

export const customPageFormSchema = yup.object({
  files: yup.array(
    yup.object({
      id: yup.string().notRequired(),
      fileLink: yup.string(),
      fileName: yup.string(),
      name: yup
        .string()
        .test(
          'name-required-if-id',
          'Please provide a name',
          (value, context) => !context.parent.id || !!value
        ), // Display name
    })
  ),
  filesTitle: yup.string().optional().max(50, 'Files section title cannot exceed 50 characters'),
  headerImageFileLink: yup.string(),
  headerImageId: yup.string().notRequired().uuid('Header image upload failed'),
  isPublic: yup.boolean().required('Please select public or private'),
  paragraphs: yup.array(
    yup.object({
      title: yup
        .string()
        .max(50, 'Paragraph title cannot exceed 50 characters')
        .test(
          'first-is-required',
          'Please provide a paragraph title',
          (value, context) => !!value || !context.path.startsWith('paragraphs[0]') // Return true if the paragraph is not the first one
        ),
      description: yup
        .string()
        .required('Please provide a description')
        .max(1000, 'Paragraph description cannot exceed 1000 characters'),
    })
  ),
  title: yup
    .string()
    .required('Please provide a page title')
    .max(25, 'Page title cannot exceed 25 characters'),
})
