import { useMemo } from 'react'
import { Button } from '@bushelpowered/design-system'
import Link from 'next/link'
import { Hide, Show } from '@chakra-ui/react'
import { useAuth } from 'src/auth'
import BottomButtonBar from 'src/components/admin/crm/bottom-button-bar'
import { Box, ButtonGroup, HStack } from 'src/components/designsystem'
import ResourceListItemActionMenu, {
  type ResourceListItemActionMenuProps,
} from 'src/components/resource/ResourceListItemActionMenu'

export default function HeaderActionButtons({
  item,
  actionMenuConfig,
  onDelete,
}: Readonly<
  ResourceListItemActionMenuProps<StaffCustomPageItem> & {
    onDelete: (item: StaffCustomPageItem) => void
  }
>) {
  return (
    <HStack spacing={4} alignItems="center">
      <QuickActionButtons item={item} onDelete={onDelete} />
      <Box boxSize="24px">
        <ResourceListItemActionMenu item={item} actionMenuConfig={actionMenuConfig} />
      </Box>
    </HStack>
  )
}

function QuickActionButtons({
  item,
  onDelete,
}: Readonly<{ item: StaffCustomPageItem; onDelete: (item: StaffCustomPageItem) => void }>) {
  const { slug } = useAuth()

  const buttons = useMemo(
    () => (
      <>
        <Button
          minW="102px"
          variant="outlineDestructive"
          w={['full', null, 'unset']}
          onClick={(e) => {
            e.stopPropagation()
            onDelete(item)
          }}
        >
          Delete
        </Button>
        <Button
          as={Link}
          minW="102px"
          w={['full', null, 'unset']}
          href={[null, slug, 'staff', 'custom-pages', item.id].join('/')}
          onClick={(e) => e.stopPropagation()}
        >
          Edit
        </Button>
      </>
    ),
    [item, onDelete, slug]
  )

  return (
    <>
      <Show above="sm">
        <ButtonGroup spacing={4} w="full">
          {buttons}
        </ButtonGroup>
      </Show>
      <Hide above="sm">
        <BottomButtonBar>{buttons}</BottomButtonBar>
      </Hide>
    </>
  )
}
