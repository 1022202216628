import { Heading } from '@bushelpowered/design-system'
import { Skeleton, Text } from 'src/components/designsystem'

const sectionHeadingPadding = { base: 4, md: 0 }

export default function SectionHeading({
  isLoading,
  children,
}: Readonly<{ children: React.ReactNode; isLoading: boolean }>) {
  return (
    <Skeleton
      aria-label={isLoading ? 'Loading...' : undefined}
      isLoaded={!isLoading}
      w={isLoading ? '179px' : undefined}
      rounded="lg"
      px={sectionHeadingPadding}
    >
      <Heading type="h6">{children}</Heading>
    </Skeleton>
  )
}

export function SectionSubHeading({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <Text textStyle="preTitle" px={sectionHeadingPadding} textTransform="uppercase">
      {children}
    </Text>
  )
}
