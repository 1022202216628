import { useEffect, useRef, useState } from 'react'
import { Box } from 'src/components/designsystem'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryTheme,
  VictoryTooltip,
} from 'victory'
import { Flyout, ChartFlyoutProps } from 'src/components/designsystem/charts/utils'

export default function SingleBar({ chartData, flyoutModal, height }: SingleBarChartProps) {
  const parentRef = useRef<HTMLDivElement>(null)
  const [parentWidth, setParentWidth] = useState<number | undefined>(0)

  useEffect(() => {
    // Get initial size
    setParentWidth(parentRef?.current?.offsetWidth)

    // Watch for any other window size changes
    addEventListener('resize', () => {
      setParentWidth(0)
      setTimeout(() => {
        setParentWidth(parentRef?.current?.offsetWidth)
      }, 1) // Ensure always executes after parent width is 0
    })
  }, [])

  return (
    <Box width="100%" ref={parentRef}>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 50, y: 100 }}
        padding={{
          left: 40,
          bottom: 100,
          top: 40,
        }}
        width={parentWidth}
        height={height}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          tickLabelComponent={
            <VictoryLabel
              style={{
                fontSize: '12px',
              }}
              angle={-45}
              textAnchor="end"
            />
          }
        />
        <VictoryAxis
          dependentAxis
          tickLabelComponent={
            <VictoryLabel
              style={{
                fontSize: '12px',
                fontWeight: '600',
              }}
            />
          }
        />
        <VictoryBar
          key={chartData.label}
          data-testid={chartData.label}
          events={
            chartData.hoverColor
              ? [
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: ['data'],
                            mutation: (props) => {
                              return {
                                ...props,
                                style: Object.assign({}, props.style, {
                                  fill: chartData.hoverColor,
                                }),
                              }
                            },
                          },
                          {
                            target: 'labels',
                            mutation: () => ({ active: true }),
                          },
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: ['data'],
                            mutation: (props) => {
                              return {
                                ...props,
                                style: Object.assign({}, props.style, {
                                  fill: chartData.color,
                                }),
                              }
                            },
                          },
                          {
                            target: 'labels',
                            mutation: () => ({ active: false }),
                          },
                        ]
                      },
                    },
                  },
                ]
              : undefined
          }
          labels={(_data) => null}
          y={(datum) => Math.max(datum.y, 20)}
          cornerRadius={6}
          data={chartData.data}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={
                flyoutModal ? (
                  <SingleBarFlyout chartData={chartData} ModalBody={flyoutModal} />
                ) : undefined
              }
            />
          }
          style={{
            data: {
              fill: chartData.color,
            },
          }}
        />
      </VictoryChart>
    </Box>
  )
}
function SingleBarFlyout(props: Partial<ChartFlyoutProps>) {
  const { chartData, ModalBody } = props
  if (!props.datum || !chartData) return null
  const orientArrowRight = !!props.datum._x && props.datum._x > chartData.data.length / 2

  return (
    <Flyout
      width="285"
      height="335"
      orientArrowRight={orientArrowRight}
      yAxisValue={(props.y ?? 0) - 100}
      xAxisValue={orientArrowRight ? (props.x ?? 0) - 250 : (props.x ?? 0) - 30}
      arrowTopMargin="45px"
    >
      <ModalBody {...props} />
    </Flyout>
  )
}
