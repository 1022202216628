import ky from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

const { token, slug, installationId, version } = getWindowNextDataProps()

type BidlClientFactoryArgs = {
  slug?: string
  token?: string | null
  installationId?: string
  version?: string
}

export const bidlClientFactory = ({
  slug,
  token,
  installationId,
  version,
}: BidlClientFactoryArgs) =>
  ky.create({
    prefixUrl: ENV.BIDL_API,
    retry: 0,
    timeout: 120000,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'App-Company': slug ?? undefined,
      'App-Name': ENV.APP_URL,
      'App-Version': version ?? undefined,
      'App-Installation-Id': installationId ?? undefined,
    },

    hooks: {
      beforeRequest: [handleRefreshTokenIfExpired],
      afterResponse: [
        async (request, _options, response) => {
          if (!response.ok) {
            try {
              const body = await response.clone().text()

              console.error('src/api - api request failed', {
                url: response.url,
                status: response.status,
                requestUrl: request.url,
                body,
              })

              response.payload = JSON.parse(body)
            } catch (err) {
              console.error('src/api - api request failed', {
                url: response.url,
                status: response.status,
                message: err.message,
              })
            }
          }

          return response
        },
      ],
    },
  })

export let bidlClient = bidlClientFactory({
  slug,
  token,
  installationId: installationId as string,
  version: version as string,
})

export const updateBidlClient = (config = {}) => {
  bidlClient = bidlClient.extend(config)
}

export const updateBidlClientHeader = (key: string, value: string) => {
  updateBidlClient({
    headers: { [key]: value },
  })
}

export const updateBidlToken = (token: string | null | undefined) =>
  updateBidlClient({ headers: { Authorization: token ? `Bearer ${token}` : undefined } })
