import { Card, CardBody, Divider, Stack } from 'src/components/designsystem'
import { DetailGrid, SkeleKeyValue } from 'src/components/resource'
import CardSectionSkeleton from './CardSectionSkeleton'
import { sectionCardBodyPadding } from './ParagraphsSection'
import { SectionSubHeading } from './SectionHeading'

type FileListSectionProps = Readonly<
  { isLoading: boolean } & Partial<Pick<StaffCustomPageDetails, 'files'>>
>

export default function FileListSection({ isLoading, files }: FileListSectionProps) {
  if (isLoading) {
    return <CardSectionSkeleton height="122px" />
  }

  if (!files || files?.length === 0) {
    return null
  }

  return (
    <Stack gap={3}>
      <SectionSubHeading>FILES</SectionSubHeading>
      <Stack gap={2}>
        {files?.map((file) => (
          <Card key={file.id} variant="outline" boxShadow="none">
            <CardBody px={sectionCardBodyPadding} py={2}>
              <DetailGrid templateColumns="1fr">
                <DetailGrid.List spacing={1}>
                  <SkeleKeyValue isLoaded={!isLoading} label="File name">
                    {file.name}
                  </SkeleKeyValue>
                  <Divider />
                  <SkeleKeyValue isLoaded={!isLoading} label="File">
                    {file.fileName}
                  </SkeleKeyValue>
                </DetailGrid.List>
              </DetailGrid>
            </CardBody>
          </Card>
        ))}
      </Stack>
    </Stack>
  )
}
