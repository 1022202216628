import { useRef } from 'react'
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  QuestionCircleIcon,
  Tooltip,
} from 'src/components/designsystem'
import { Help } from 'src/components/help/Help'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'

interface HelpAndSupportHeaderButtonProps {
  isDesktop: boolean
}

export default function HelpAndSupportHeaderButton({
  isDesktop,
}: Readonly<HelpAndSupportHeaderButtonProps>) {
  const helpButtonRef = useRef<HTMLButtonElement>(null)
  const { toggleRightSidebar, closeRightSidebar } = useRightSidebarStore()

  return (
    <Flex>
      {isDesktop && (
        <Popover id="hs-pop" placement="bottom-end" returnFocusOnClose={false}>
          <>
            <Tooltip label="Help and Support" hasArrow>
              <Box display="inline-block">
                <PopoverTrigger>
                  <IconButton
                    onClick={closeRightSidebar}
                    color="gray.500"
                    fontSize="2xl"
                    icon={<QuestionCircleIcon />}
                    aria-label="Help and Support"
                    data-testid="help-and-support-icon"
                    variant="menuIcon"
                    isRound
                    boxSize={8}
                  />
                </PopoverTrigger>
              </Box>
            </Tooltip>

            <PopoverContent>
              <PopoverArrow />
              <PopoverBody p={0} w={80}>
                <Help />
              </PopoverBody>
            </PopoverContent>
          </>
        </Popover>
      )}

      {!isDesktop && (
        <IconButton
          variant="menuIcon"
          fontSize="2xl"
          color="gray.500"
          ref={helpButtonRef}
          isRound
          boxSize={8}
          aria-label="Help and Support"
          data-testid="help-and-support-icon"
          icon={<QuestionCircleIcon />}
          onClick={() => toggleRightSidebar('help')}
        />
      )}
    </Flex>
  )
}
