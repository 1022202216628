import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getNextPageParam } from 'src/data/queries/utils'
import { useMerchandiser } from 'src/data/merchandiser'
import * as api from 'src/api'
import { useMemo } from 'react'

export function usePrepaids() {
  const { selectedUserIdpId } = useMerchandiser()
  const query = useInfiniteQuery({
    queryKey: ['prepaid-contracts', { selectedUserIdpId }],
    queryFn: ({ pageParam }) => api.centre.prepaidContracts({ page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve prepaids, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function usePrepaidDetail({ id, enabled }: { id: string | number; enabled: boolean }) {
  return useQuery<PrepaidDetail, api.HTTPError>({
    queryKey: ['prepaid-contract-detail', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')
      return api.centre.prepaid(id)
    },
    enabled,
    staleTime: Infinity,
  })
}
