import { create } from 'zustand'
import { useShallow } from 'zustand/shallow'

export type BackStackItem = {
  callback: () => void
}

interface BackStackItemState {
  stack: BackStackItem[]
  push: (backStackItem: BackStackItem) => void
  pop: () => void
}

export const useBackStackStore = create<BackStackItemState>()((set, get) => ({
  stack: [] as BackStackItem[],
  push: (backStackItem: BackStackItem) => {
    const { stack } = get()
    set({ stack: [...stack, backStackItem] })
  },
  pop: () => {
    const { stack } = get()
    set({ stack: stack.slice(0, -1) })
  },
}))

export function useBackStack() {
  return useBackStackStore(
    useShallow((state) => ({
      ...state,
      currentItem: state.stack.length > 0 ? state.stack.slice(-1)[0] : null,
    }))
  )
}
