import {
  ChakraTab,
  ChakraTabs,
  ChakraTabIndicator,
  ChakraTabList,
  ChakraTabPanels,
  ChakraTabPanel,
} from 'src/components/designsystem'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

function TabButton({ title }: { title: string }) {
  return (
    <ChakraTab
      fontWeight="bold"
      color="gray.500"
      _selected={{ color: 'blue.400', borderBottom: '2px solid', borderBottomColor: 'blue.400' }}
      data-testid={`${labelToTestId(title)}-tab-button`}
    >
      {title}
    </ChakraTab>
  )
}

export {
  ChakraTabs as Tabs,
  ChakraTabList as TabList,
  TabButton as TabButton,
  ChakraTabIndicator as TabIndicator,
  ChakraTabPanels as TabPanels,
  ChakraTabPanel as TabPanel,
}
