import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { HTTPError } from 'src/api'
import { notifyService } from 'src/api/notify-service/notify-service'
import { NotificationHistoryResponse } from 'src/api/notify-service/models/notification-history-response'

export function notificationsQueryKey(groupId: string | null) {
  return ['notifications', { groupId }]
}

export function useNotificationHistory({
  groupId,
  slug,
}: {
  groupId: string | null
  slug: string
}) {
  return useQuery<NotificationHistoryResponse, HTTPError>({
    queryKey: [...notificationsQueryKey(groupId), { slug }],
    queryFn: async () => {
      if (!groupId) throw new Error('groupId is required')
      return notifyService.getNotificationHistory(groupId, slug)
    },
    refetchOnWindowFocus: true,
    enabled: !!groupId,
  })
}

type MutationParams = {
  groupId: string
  notificationIds: string[]
}
export function useMarkNotificationAsRead({ groupId }: { groupId: string | null }) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ groupId, notificationIds }: MutationParams) => {
      return notifyService.markNotificationsAsRead(groupId, notificationIds)
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: notificationsQueryKey(groupId) })
    },
  })
}
