import { Dispatch, SetStateAction, useState } from 'react'
import { standaloneToast, useDisclosure, useToast } from 'src/components/designsystem'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { KyResponse } from 'ky'
import getClient from 'src/utils/clients/get-client'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'
import { logger } from 'src/utils'

export function getExportFilename(defaultBase: string, header?: string, fileType?: FileType) {
  return (
    header?.split('filename=')[1] ??
    `${defaultBase}_${new Date().toISOString()}.${fileType ?? FileType.CSV}`
  )
}

export function createDownloadLink(blob: Blob, filename: string) {
  const link = document.createElement('a')

  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute('download', filename)

  document.body.appendChild(link)

  return link
}

interface Filter {
  [key: string]: any
}

export enum FileType {
  PDF = 'pdf',
  CSV = 'csv',
}

export interface useDataExportArgs {
  handler: (filter: Filter) => Promise<Response>
  filter?: Filter
  defaultFilenameBase?: string
}

export interface UseDataExportReturnType {
  download: () => Promise<void>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export interface FileExportOption {
  key: string
  title: string
  message: string | JSX.Element
  handler: () => Promise<KyResponse>
  fileType: FileType
  analyticsAction: string
}

export interface UseFileExportReturnType {
  options: FileExportOption[]
  onOpen: Dispatch<SetStateAction<void>>
  isOpen: boolean
  onClose: Dispatch<SetStateAction<void>>
  fileExportMutation: UseMutationResult
}

export async function downloadFile({
  apiResponse,
  fileType = FileType.CSV,
  fileNameOverride = undefined,
  defaultFilenameBase = 'export',
}: {
  apiResponse: Response
  fileType?: FileType
  fileNameOverride?: string
  defaultFilenameBase?: string
}) {
  const isNativeApp = getClient().isNativeApp
  const dataBlob = await apiResponse.blob()
  const filename =
    fileNameOverride ??
    getExportFilename(
      defaultFilenameBase,
      apiResponse.headers.get('content-disposition') ?? undefined,
      fileType
    )

  if (isNativeApp) {
    return writeFileAndShare(dataBlob, filename).catch((error) => {
      logger.error({
        message: 'Error while exporting file in native Acadia app',
        error,
      })

      standaloneToast({
        status: 'error',
        title: 'Failed to export',
        isClosable: true,
      })
    })
  } else {
    createDownloadLink(dataBlob, filename).click()
  }
}

async function writeFileAndShare(blob: Blob, filename: string) {
  const fileAsBase64String = await blobToBase64(blob)

  const writeFileResult = await Filesystem.writeFile({
    path: filename,
    data: fileAsBase64String,
    directory: Directory.Cache,
  })

  await Share.share({
    title: filename,
    url: writeFileResult.uri,
  }).catch((error) => {
    if (error.message === 'Share canceled') return
    throw new Error(error)
  })
}

async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64WithDataUrlPrefix = reader.result as string
      // strips off the _data:${mimeType};base64_ prefix
      const base64String = base64WithDataUrlPrefix.split(',')[1]
      resolve(base64String)
    }
    reader.onerror = () => reject(new Error('Error occurred reading file'))
    reader.readAsDataURL(blob)
  })
}

export function useDataExport({
  handler,
  filter = {},
  defaultFilenameBase = 'export',
}: useDataExportArgs) {
  const toast = useToast()
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function download() {
    setIsLoading(true)

    try {
      const response = await handler(filter)
      await downloadFile({ apiResponse: response, defaultFilenameBase })
    } catch (err) {
      toast({
        status: 'error',
        title: err?.response?.payload?.error?.message ?? 'Failed to export',
      })
    }

    setIsLoading(false)
  }

  return {
    download,
    isLoading,
    setIsLoading,
    showModal,
    setShowModal,
  }
}

export function useFileExport({
  options,
}: {
  options: FileExportOption[]
}): UseFileExportReturnType {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const toast = useToast()

  const fileExportMutation = useMutation({
    mutationFn: async ({
      exportFileHandler,
      fileType,
    }: {
      exportFileHandler: () => Promise<KyResponse>
      fileType: FileType
    }) => {
      const response = await exportFileHandler()

      return downloadFile({
        apiResponse: response,
        defaultFilenameBase: 'export',
        fileType,
      })
    },
    onError: (error: any) => {
      toast({
        status: 'error',
        title: error?.response?.payload?.error?.message ?? 'Failed to export',
      })
    },
  })

  return {
    options,
    onOpen,
    isOpen,
    onClose,
    fileExportMutation,
  }
}

export function useAnalyticsReportsExport({ defaultFilenameBase }) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const toast = useToast()
  const [date, setDate] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<string | null | boolean>(null)

  async function download({
    loadingState,
    handler,
  }: {
    loadingState: string
    handler: (date) => Promise<Response>
  }) {
    setIsLoading(loadingState)

    try {
      const response = await handler(date)
      await downloadFile({ apiResponse: response, defaultFilenameBase })
    } catch (err) {
      toast({
        status: 'error',
        title: err?.response?.payload?.error?.message || 'Failed to export csv',
      })
    }

    setIsLoading(false)
  }

  return {
    isOpen,
    onClose,
    onOpen,
    date,
    setDate,
    download,
    setIsLoading,
    isLoading,
  }
}
