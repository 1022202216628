import { Box, Stat, StatArrow, StatHelpText, ResponsiveValue } from 'src/components/designsystem'

type TextAlign = 'left' | 'right'

function getChangeSymbolColor(changeSign: number): string {
  if (changeSign < 0) return 'red.500'
  return changeSign > 0 ? 'green.500' : ''
}

export function ChangeSymbol({
  changeSign,
  change,
  textAlign = 'left',
  fontSize,
  fontWeight = 700,
  wrap = false,
}: {
  changeSign: number | null
  change: number | string
  textAlign?: ResponsiveValue<TextAlign>
  fontSize?: string | number
  fontWeight?: string | number
  wrap?: boolean
}) {
  return (
    <Box textAlign={textAlign} aria-label="Change Symbol">
      <Stat>
        <StatHelpText
          color={changeSign !== null ? getChangeSymbolColor(changeSign) : undefined}
          fontSize={fontSize ?? { base: 'initial', md: '14px', xl: 'initial' }}
          fontWeight={fontWeight}
          opacity={1}
          alignItems="center"
          whiteSpace={!wrap ? 'nowrap' : 'initial'}
          data-testid="row-datapoint-change"
        >
          {changeSign !== null && changeSign !== 0 && (
            <StatArrow
              type={changeSign > 0 ? 'increase' : 'decrease'}
              data-testid={changeSign > 0 ? 'change-up' : 'change-down'}
            />
          )}
          {change.toString()}
        </StatHelpText>
      </Stat>
    </Box>
  )
}
