import { PropsWithChildren, useMemo } from 'react'
import {
  CloseIcon,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
} from 'src/components/designsystem'
import { useHeaderHeightStore } from 'src/components/layout/Header'
import { NotificationHistory } from 'src/components/push-notifications/history/NotificationHistory'
import { useNotificationGroupIdStore } from 'src/components/layout/header-buttons/NotificationHistoryHeaderButton'
import { Help } from 'src/components/help/Help'
import { DebugInformation } from 'src/components/utils'
import { useAuth } from 'src/auth'
import { Account } from 'src/components/account/Account'
import { AccountPlaceholder } from 'src/components/account/AccountPlaceholder'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'
import NoServerSideRendering from 'src/components/utils/NoServerSideRendering'

export function RightSidebar() {
  const { height: headerHeight } = useHeaderHeightStore()
  const { isOpen, feature, closeRightSidebar } = useRightSidebarStore()

  const renderContent = useMemo(() => {
    switch (feature) {
      case 'debug':
        return <DebugSidebar />
      case 'notifications':
        return (
          <NoServerSideRendering>
            <NotificationHistorySidebar />
          </NoServerSideRendering>
        )
      case 'help':
        return <HelpSidebar />
      case 'account':
        return <AccountSidebar />
      default:
        return null
    }
  }, [feature])

  return (
    <Drawer {...{ onClose: closeRightSidebar, isOpen }}>
      <DrawerOverlay top={headerHeight} bottom="0" />

      <DrawerContent
        maxW={['100%', '375px']}
        overflowY="scroll"
        overscrollBehavior="contain"
        style={{
          top: headerHeight,
          bottom: '0',
        }}
        shadow="-10px 10px 15px rgb(45 55 72 / 26%)" // shadow down and to the left to keep from shadowing over header
      >
        {renderContent}
      </DrawerContent>
    </Drawer>
  )
}

function DebugSidebar() {
  return (
    <SidebarContent title="Debug Drawer">
      <DebugInformation />
    </SidebarContent>
  )
}

function NotificationHistorySidebar() {
  const { groupId } = useNotificationGroupIdStore()

  return (
    <SidebarContent title="Notifications">
      <NotificationHistory groupId={groupId} />
    </SidebarContent>
  )
}

function HelpSidebar() {
  return (
    <SidebarContent title="Help & Support">
      <Help />
    </SidebarContent>
  )
}

function AccountSidebar() {
  const { authenticated } = useAuth()

  return (
    <SidebarContent title="My Account">
      {authenticated ? <Account /> : <AccountPlaceholder />}
    </SidebarContent>
  )
}

function SidebarContent({ title, children }: PropsWithChildren<{ title: string }>) {
  const { closeRightSidebar } = useRightSidebarStore()

  return (
    <>
      <DrawerHeader
        display="flex"
        borderBottomWidth="1px"
        justifyContent="space-between"
        alignItems="center"
        pr={2}
        position="sticky"
        top={0}
        bg="white"
      >
        <Heading type="h6" data-testid={`${title}-header`}>
          {title}
        </Heading>
        <IconButton
          variant="unstyled"
          onClick={closeRightSidebar}
          aria-label="Close Filter"
          w={10}
          minW="unset"
          borderRadius="full"
          icon={<CloseIcon fontSize="2xl" />}
          data-testid="close-button"
        />
      </DrawerHeader>
      <DrawerBody p={0} m={0} id="drawer-body" overscroll="contain">
        {children}
      </DrawerBody>
    </>
  )
}
