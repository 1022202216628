import type { ResourceListItemActionMenuProps } from 'src/components/resource/ResourceListItemActionMenu'
import { MobileFullscreenModal } from 'src/components/resource/MobileFullscreenModal'
import HeaderActionButtons from './HeaderActionButtons'
import { ExpandedListItem } from './expanded-list-item'

export default function ExpandedListItemModal({
  item,
  actionMenuConfig,
  onClose,
  onDelete,
}: Readonly<
  {
    onClose: () => void
    onDelete: (item: StaffCustomPageItem) => void
  } & ResourceListItemActionMenuProps<StaffCustomPageItem>
>) {
  return (
    <MobileFullscreenModal
      primaryTitle={item.title}
      onClose={onClose}
      secondaryTitle={
        <HeaderActionButtons item={item} actionMenuConfig={actionMenuConfig} onDelete={onDelete} />
      }
      contentMb={[20, 0]}
    >
      <ExpandedListItem pageId={item.id} onClose={onClose} />
    </MobileFullscreenModal>
  )
}
