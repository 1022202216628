import { Text } from '@bushelpowered/design-system'
import { MenuItem, TrashStandardIcon } from 'src/components/designsystem'

export default function DeleteMenuItem<T>({
  item,
  onDelete,
}: Readonly<{
  item: T
  onDelete: (item: T) => void
}>) {
  return (
    <MenuItem
      icon={<TrashStandardIcon fontSize="xl" />}
      onClick={(e) => {
        e.stopPropagation()
        onDelete(item)
      }}
      data-testid="delete-more-items-button"
    >
      <Text>Delete</Text>
    </MenuItem>
  )
}
