import { bidlClient } from 'src/api/bidl/client'
import { businessPagesBaseUrl } from 'src/api/aggregator/business-locations'

export const discountScheduleRequests = {
  // Staff
  getDiscountSchedulesStaff: (body: StaffGetDiscountSchedulesRequest) =>
    bidlClient
      .post(`${businessPagesBaseUrl}/v1/StaffGetDiscountSchedules`, { json: body })
      .json<StaffGetDiscountSchedulesResponse>(),
  updateDiscountScheduleStaff: (body: StaffUpdateDiscountScheduleRequest) =>
    bidlClient
      .post(`${businessPagesBaseUrl}/v1/StaffUpdateDiscountSchedule`, { json: body })
      .json<StaffUpdateDiscountScheduleResponse>(),
  getDiscountScheduleByIdStaff: (body: StaffGetDiscountScheduleByIdRequest) =>
    bidlClient
      .post(`${businessPagesBaseUrl}/v1/StaffGetDiscountScheduleById`, { json: body })
      .json<StaffGetDiscountScheduleByIdResponse>(),
  deleteDiscountScheduleStaff: (body: StaffDeleteDiscountScheduleRequest) =>
    bidlClient
      .post(`${businessPagesBaseUrl}/v1/StaffDeleteDiscountSchedule`, { json: body })
      .json<StaffDeleteDiscountScheduleResponse>(),
  createDiscountScheduleStaff: (body: StaffCreateDiscountScheduleRequest) =>
    bidlClient
      .post(`${businessPagesBaseUrl}/v1/StaffCreateDiscountSchedule`, { json: body })
      .json<StaffCreateDiscountScheduleResponse>(),

  // Grower
  getLocationsWithDiscountSchedules: (body?: GetLocationsWithDiscountSchedulesRequest) =>
    bidlClient
      .post(`${businessPagesBaseUrl}/v1/GetLocationsWithDiscountSchedules`, { json: body ?? {} })
      .json<GetLocationsWithDiscountSchedulesResponse>(),
}
