import { createIcon } from '@chakra-ui/react'

export const PersonIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
  },
  displayName: 'PersonIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12 12.5c-2.47968 0-4.66406-2.3297-4.875-5.19375-.10781-1.45313.34219-2.79844 1.26563-3.7875C9.3 2.53906 10.5844 2 12 2c1.4063 0 2.6859.54375 3.6 1.52812.9281.99844 1.3781 2.33907 1.2703 3.77813C16.6594 10.1703 14.475 12.5 12 12.5Zm0-9c-.9937 0-1.8891.37031-2.5125 1.04062-.63281.67969-.94219 1.62657-.86719 2.65782.15 2.0625 1.69689 3.80626 3.37499 3.80626s3.225-1.74376 3.375-3.80626c.075-1.01719-.2343-1.95938-.8765-2.64844C13.875 3.87031 12.9844 3.5 12 3.5ZM20.25 22.9999H3.75001c-.45 0-.85312-.1875-1.13437-.5203-.30469-.3656-.42657-.8625-.3375-1.3641.39375-2.1937 1.63125-4.0406 3.57187-5.3296 1.725-1.1485 3.90938-1.7813 6.14999-1.7813 2.2406 0 4.425.6328 6.15 1.7813 1.9406 1.2937 3.1781 3.1359 3.5719 5.3296.089.5016-.0328.9985-.3375 1.3641-.2813.3328-.6844.5203-1.1344.5203Zm-16.49061-1.5H20.2406c.0094-.0234.0141-.0609.0047-.1219-.7265-4.0359-4.7859-5.8781-8.2453-5.8781-3.45936 0-7.51874 1.8422-8.2453 5.8781-.00938.061-.00469.0985.00469.1219Z"
      fill="currentColor"
    />
  ),
})
