import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useCallback, useEffect, useRef, useState } from 'react'
import { UseInfiniteQueryResult } from '@tanstack/react-query'

dayjs.extend(utc)

/**
 * Function to make sure we are consistent with checks to display values and default to Em Dash otherwise
 * @param value - Value to check, also the default itemToRender
 * @param itemToRender - prim or element to render if value passes check
 */
export function renderOrEmDash({ value, itemToRender = value }) {
  //using explicit null, empty string, and undefined checks below for clarity, there are shorter ways to write it I'm sure.
  return value !== null && value !== '' && value !== undefined ? itemToRender : '—'
}

export function showDivider({
  index,
  configItemLength,
  breakpoint,
}: {
  index: number
  configItemLength: number
  breakpoint: ChakraBreakpoint
}) {
  // Do not show divider on desktop
  if (!['base', 'sm', 'md'].includes(breakpoint)) return false
  // mobile always shows divider except on last item
  if (['base', 'sm'].includes(breakpoint)) return index < configItemLength - 1
  // for tablet do not show divider below either column's last item
  return index < configItemLength - 2
}

export function FormattedDate({
  date,
  localize,
  color,
}: {
  date: string | undefined | null
  localize: boolean
  color?: string
}) {
  return (
    date && (
      <span style={{ color }}>
        {localize
          ? new Date(date).toLocaleDateString('en-US', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })
          : dayjs.utc(date).format('MM/DD/YY')}
      </span>
    )
  )
}

export function FormattedDateTime({
  date,
  localize,
}: {
  date: string | undefined | null
  localize: boolean
}) {
  return (
    date && (
      <>
        <span>
          {localize
            ? new Date(date).toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
            : dayjs.utc(date).format('MM/DD/YY')}
        </span>
        <span> </span>
        <span>
          {localize
            ? new Date(date).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
              })
            : dayjs.utc(date).format('h:mm A')}
        </span>
      </>
    )
  )
}

/**
 * Displays range of two dates OR either date alone if only one provided
 */
export function FormattedDateRange({
  start,
  end,
  localize,
}: {
  start: string | null | undefined
  end: string | null | undefined
  localize: boolean
}) {
  return localize ? (
    <>
      {start && (
        <span>
          {new Date(start).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })}
        </span>
      )}
      {start && end && <span>–</span>}
      {end && (
        <span>
          {new Date(end).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })}
        </span>
      )}
    </>
  ) : (
    <>
      {start && <span>{dayjs.utc(start).format('MM/DD/YY')}</span>}
      {start && end && <span>–</span>}
      {end && <span>{dayjs.utc(end).format('MM/DD/YY')}</span>}
    </>
  )
}

export function FormattedTime({ date, localize }: { date: string | undefined; localize: boolean }) {
  return (
    date && (
      <span>
        {localize
          ? new Date(date).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
            })
          : dayjs.utc(date).format('h:mm A')}
      </span>
    )
  )
}

/**
 * Checks height of a resource list against the height of the viewport to determine if more data should be initially loaded.
 */

interface PageHeightCheckArgs<Item> {
  itemQuery: UseInfiniteQueryResult<
    {
      data: Item[]
      meta: {
        pagination?: Pagination
        last_updated?: string
      }
    },
    unknown
  >
  hasResultsWithError: boolean
}

export function usePageHeightCheck<Item>({
  itemQuery,
  hasResultsWithError,
}: PageHeightCheckArgs<Item>) {
  const [hasSatisfied, setHasSatisfied] = useState(false)
  const { ref, setRef, isRefSet } = useTrackedRef()
  const { isFetching, fetchNextPage, hasNextPage } = itemQuery

  const shouldBail = hasSatisfied || isFetching || !isRefSet || !hasNextPage || hasResultsWithError

  useEffect(() => {
    if (shouldBail || !ref.current) return

    const { bottom } = ref.current.getBoundingClientRect()

    if (bottom > 0 && bottom <= window.innerHeight) {
      fetchNextPage()
    } else {
      setHasSatisfied(true)
    }
  }, [ref, shouldBail, fetchNextPage])

  return { itemListContainerRef: setRef }
}

/**
 * We need to be "notified" when the ref gets assigned.
 * Using a callback-ref allows us to track this in a state variable `isRefSet`.
 */
function useTrackedRef() {
  const [isRefSet, setIsRefSet] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const setRef = useCallback((node: HTMLDivElement) => {
    if (node) setIsRefSet(true)
    ref.current = node
  }, [])

  return { ref, setRef, isRefSet: isRefSet && !!ref.current }
}
