import { ResourceListItem } from 'src/components/resource'
import type { ResourceListItemProps } from 'src/components/resource/ResourceListItem'
import { useDisclosure } from 'src/components/designsystem'
import { ExpandedListItem } from './expanded-list-item'
import HeaderActionButtons from './HeaderActionButtons'

export default function ListItem({
  item,
  actionMenuConfig,
  onDelete,
  ...props
}: Readonly<
  ResourceListItemProps<StaffCustomPageItem> & {
    onDelete: (item: StaffCustomPageItem) => void
  }
>) {
  const detailsDisclosure = useDisclosure()
  return (
    <ResourceListItem
      {...detailsDisclosure}
      item={item}
      rightRowElement={
        detailsDisclosure.isOpen ? (
          <HeaderActionButtons
            item={item}
            actionMenuConfig={actionMenuConfig}
            onDelete={onDelete}
          />
        ) : undefined
      }
      actionMenuConfig={actionMenuConfig}
      {...props}
    >
      <ExpandedListItem pageId={item.id} onClose={detailsDisclosure.onClose} />
    </ResourceListItem>
  )
}
