import { AlertCircleIcon, CollapsableDetailTable, Tooltip } from 'src/components/designsystem'
import DisplayConfig, { DisplayConfigItem } from 'src/components/designsystem/display-config'
import { DetailGrid, FormattedDate, useStackedDetailModal } from 'src/components/resource'
import { useContractAssociatedTickets } from 'src/data/queries'

export const DISPLAY_CONFIG: DisplayConfigItem<ContractAssociatedTicket>[] = [
  {
    label: 'Scale Ticket',
    key: 'remote_ticket_id',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: () => true, // Don't want to ever em-dash this value
    renderValue: ({ data }) => {
      if (data.id) return data.display_id

      return (
        <>
          {data.display_id}
          <Tooltip label="Ticket unavailable" hasArrow placement="top">
            <AlertCircleIcon color="red.500" fontSize="lg" ml={1} mb={0.5} />
          </Tooltip>
        </>
      )
    },
  },
  {
    label: 'Application Date',
    key: 'application_date',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.application_date),
    renderValue: ({ data }) => <FormattedDate date={data.application_date} localize={false} />,
  },
  {
    label: 'Gross Qty',
    key: 'gross_quantity',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.gross_quantity),
  },
  {
    label: 'Net Qty',
    key: 'net_quantity',
    ValueCellComponent: DisplayConfig.BoldTextValue,
    getValue: ({ data }) => (!data.id ? null : data.net_quantity),
  },
]

type AssociatedTicketsProps = Readonly<{
  contractId?: number
}>

export default function AssociatedTickets({ contractId }: AssociatedTicketsProps) {
  const { setItem } = useStackedDetailModal()

  const { data: associatedTickets } = useContractAssociatedTickets({ id: contractId })

  if (!associatedTickets || associatedTickets.length < 1) return null

  return (
    <>
      <DetailGrid.Title>Associated Scale Tickets</DetailGrid.Title>
      <CollapsableDetailTable
        rowItems={associatedTickets}
        rowItemTitle="Tickets"
        displayConfig={DISPLAY_CONFIG}
        analyticsCategory="Contracts"
        analyticsAction="Associated Scale Tickets List"
        setItem={(item) => setItem({ type: 'ticket', item })}
        shouldShowViewButton={(rowItem) => !!rowItem.id}
      />
    </>
  )
}
