import { useMemo } from 'react'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { getNextPageParam } from 'src/data/queries/utils'
import { useMerchandiser } from 'src/data/merchandiser'
import * as api from 'src/api'
import {
  APIContractFilterOptions,
  ContractFiltersForQuery,
} from 'src/components/contracts/ContractFilters'

export function useContractFilterOptions() {
  const { selectedUserIdpId } = useMerchandiser()

  return useQuery<APIContractFilterOptions>({
    queryKey: ['contract-filter-options', { selectedUserIdpId }],
    queryFn: () => api.centre.contractFilterOptions(),
    staleTime: Infinity,
  })
}

export function useContracts({
  filter,
}: {
  filter?: ContractFiltersForQuery
} = {}) {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQuery({
    queryKey: ['contract-list', { selectedUserIdpId, filter }],
    queryFn: ({ pageParam }) => api.centre.contracts({ filter, page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve contracts, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo<Contract[]>(() => {
    // Infinite queries contain an array of page data
    // This reduces them down to just one big list of the items
    return (query.data?.pages || []).reduce((acc, page) => [...acc, ...page.data], [])
  }, [query.data?.pages])

  return { query, data: mergedPageData }
}

export function useContractDetail({
  id,
  enabled = true,
}: {
  id: string | number
  enabled?: boolean
}) {
  return useQuery<ContractDetail, api.HTTPError>({
    queryKey: ['contract-detail', { id }],
    queryFn: async () => {
      if (!id) throw new Error('id is required')
      return api.centre.contract(id)
    },
    enabled,
    staleTime: Infinity,
  })
}
