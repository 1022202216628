import { Children, isValidElement, ReactElement, ReactNode, ReactPortal } from 'react'
import {
  CheckboxFilterProps,
  DateFilterProps,
  RangeFilterProps,
  TextboxFilterProps,
} from 'src/components/designsystem'

export function getOpenFilterIndexes(
  allSelectedFilters: Record<string, any>,
  filtersWithProps: FiltersWithProps[]
) {
  const availableFiltersSet = [
    // Eliminate any duplicates:
    ...Array.from(new Set(getFiltersByKey(filtersWithProps, 'filterId'))),
  ]

  const openFilters: number[] = []

  for (const [selectedFilterKey, selectedFilterValue] of Object.entries(allSelectedFilters)) {
    // Support standard filter type:
    if (selectedFilterValue && !isDynamicFilterOptionItem(selectedFilterValue)) {
      const selectedFilterIndex = availableFiltersSet.findIndex(
        (availableFilterName) => availableFilterName === selectedFilterKey
      )
      if (selectedFilterIndex > -1) {
        openFilters.push(selectedFilterIndex)
      }
    } // Support addendum filter type:
    else if (isDynamicFilterOptionItem(selectedFilterValue)) {
      for (const filter of selectedFilterValue) {
        const selectedFilterIndex = availableFiltersSet.findIndex(
          (availableFilterName) => availableFilterName === filter.id
        )
        if (selectedFilterIndex > -1) {
          openFilters.push(selectedFilterIndex)
        }
      }
    }
  }

  return openFilters
}

function getFiltersByKey(filters: FiltersWithProps | FiltersWithProps[], filterKey: string) {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (!value) return acc
    if (key === filterKey) return acc.concat(value)

    const isNumericKey = !Number.isNaN(parseInt(key))

    if (isNumericKey || [filterKey, 'props', 'children'].includes(key)) {
      return acc.concat(getFiltersByKey(value, filterKey))
    }

    return acc
  }, [])
}

type AllFilterOptionTypes =
  | (FilterOptionItem | FilterOptionCollection | DynamicFilterOptionItem | string)[]
  | DateFilterOptionItem
  | RangeFilterOptionItem
  | TextboxFilterOptionItem

export function isStringArray(filterOption: AllFilterOptionTypes): filterOption is string[] {
  const optionStringArray = filterOption as string[]
  return (
    Array.isArray(optionStringArray) &&
    optionStringArray.length > 0 &&
    optionStringArray.every((item) => typeof item === 'string')
  )
}

export function stringArrayToFilterOptionItems(
  collection: undefined | null | string[]
): FilterOptionItem[] | undefined {
  return collection?.map((option) => ({ id: option, name: option }))
}

export function filterOptionItemsToStringArray(
  items: undefined | null | FilterOptionItem[]
): string[] | null {
  return items?.map((option) => option.id) ?? null
}
export interface FilterOptionItem {
  id: string
  name: string
}

export function isFilterOptionItem(
  filterOption: AllFilterOptionTypes
): filterOption is FilterOptionItem[] {
  const optionItem = filterOption as FilterOptionItem[]
  return (
    Array.isArray(optionItem) &&
    optionItem.length > 0 &&
    optionItem[0].id !== undefined &&
    optionItem[0].name !== undefined &&
    // this last check is to verify the option isn't a dynamic filter option
    optionItem[0]['options'] === undefined
  )
}

export interface FilterOptionCollection {
  ids: string[]
  name: string
}

export function isFilterOptionCollection(
  filterOption: AllFilterOptionTypes
): filterOption is FilterOptionCollection[] {
  const optionItem = filterOption as FilterOptionCollection[]
  return (
    Array.isArray(optionItem) &&
    optionItem.length > 0 &&
    optionItem[0].ids !== undefined &&
    optionItem[0].name !== undefined
  )
}

export function filterOptionCollectionsToFilterOptionItems(
  collection: FilterOptionCollection[] | null | undefined
): FilterOptionItem[] | undefined {
  return collection?.map((option) => ({ id: option.ids.join('_-_'), name: option.name }))
}

export function filterOptionItemsToFilterOptionCollections(
  items: FilterOptionItem[] | null | undefined
): FilterOptionCollection[] | null {
  return items?.map((item) => ({ ids: item.id.split('_-_'), name: item.name })) ?? null
}

export interface RangeFilterOptionItem {
  high?: number
  low?: number
}

export interface TextboxFilterOptionItem {
  value?: string
}

export function isRangeFilterOptionItem(
  filterOption: AllFilterOptionTypes
): filterOption is RangeFilterOptionItem {
  const optionItem = filterOption as RangeFilterOptionItem
  return optionItem?.high !== undefined || optionItem?.low !== undefined
}

export function isTextboxFilterOptionItem(
  filterOption: AllFilterOptionTypes
): filterOption is TextboxFilterOptionItem {
  const optionItem = filterOption as TextboxFilterOptionItem
  return optionItem?.value !== undefined
}

export interface DateFilterOptionItem {
  from?: string | null
  to?: string | null
}

export function isDateFilterOptionItem(
  filterOption: AllFilterOptionTypes
): filterOption is DateFilterOptionItem {
  const optionItem = filterOption as DateFilterOptionItem
  return optionItem?.from !== undefined && optionItem?.to !== undefined
}

export interface DynamicFilterOptionItem {
  id: string
  name: string
  options?: FilterOptionItem[]
  type?: string
}

export function isDynamicFilterOptionItem(
  filterOption: AllFilterOptionTypes
): filterOption is DynamicFilterOptionItem[] {
  const optionItem = filterOption as DynamicFilterOptionItem
  return (
    Array.isArray(optionItem) &&
    optionItem.length > 0 &&
    optionItem[0].id !== undefined &&
    optionItem[0].name !== undefined &&
    optionItem[0].options !== undefined &&
    optionItem[0].type !== undefined
  )
}

export interface BaseFilterProps<T> {
  label: string
  filterId: string
  values: T
  onChange: (values: T) => void
}

export interface FiltersProps<AllFiltersType extends Record<string, any>> {
  allSelectedFilters: AllFiltersType
  setAllSelectedFilters: (selected?: AllFiltersType) => void
  setFilterById: (
    filterId: keyof AllFiltersType,
    values:
      | FilterOptionItem[]
      | DateFilterOptionItem
      | RangeFilterOptionItem
      | TextboxFilterOptionItem
      | FilterOptionCollection[]
      | string[]
      | null,
    trackingDimensions?: string[]
  ) => void
  filterOptions: AllFiltersType | undefined
  isLoading?: boolean
  skeletonCount?: number
}

/**
 * A type that can be any one of the FilterProps
 */
export type AnyFilterProps =
  | DateFilterProps
  | RangeFilterProps
  | CheckboxFilterProps
  | MoreFiltersProps
  | TextboxFilterProps

/**
 * Return true if the incoming `filterProps` is a `CheckboxFilterProps`, otherwise return false
 * @param filterProps - the questionable `CheckboxFilterProps`
 */
export function isCheckboxFilterProps(
  filterProps: AnyFilterProps
): filterProps is CheckboxFilterProps {
  const checkboxProps = filterProps as CheckboxFilterProps
  return checkboxProps.filterId !== undefined && checkboxProps.values !== undefined
}

/**
 * Return true if the incoming `filterProps` is a `DateFilterProps`, otherwise return false
 * @param filterProps - the questionable `DateFilterProps`
 */
export function isDateFilterProps(filterProps: AnyFilterProps): filterProps is DateFilterProps {
  const dateProps = filterProps as DateFilterProps
  return dateProps.filterId !== undefined && dateProps.value !== undefined
}

/**
 * Return true if the incoming `filterProps` is a `RangeFilterProps`, otherwise return false
 * @param filterProps - the questionable `RangeFilterProps`
 */
export function isRangeFilterProps(filterProps: AnyFilterProps): filterProps is RangeFilterProps {
  const rangeProps = filterProps as RangeFilterProps
  return rangeProps.filterId !== undefined && rangeProps.value !== undefined
}

/**
 * Return true if the incoming `filterProps` is a `RangeFilterProps`, otherwise return false
 * @param filterProps - the questionable `RangeFilterProps`
 */
export function isTextboxFilterProps(
  filterProps: AnyFilterProps
): filterProps is TextboxFilterProps {
  const textboxProps = filterProps as TextboxFilterProps
  return textboxProps.value !== undefined
}

/**
 * Return true if the incoming `filterProps` is a `MoreFilterProps`, otherwise return false
 * @param filterProps - the questionable `MoreFilterProps`
 */
export function isMoreFilterProps(filterProps: AnyFilterProps): filterProps is MoreFiltersProps {
  const moreProps = filterProps as MoreFiltersProps
  return moreProps.isMore !== undefined && moreProps.isMore
}

export interface MoreFiltersProps {
  label?: string
  isMore: true
  children: ReactNode
}

// The following `PropType` and `forEachProp` generics were created using
// this post as a reference:
// https://stackoverflow.com/questions/45894524/getting-type-of-a-property-of-a-typescript-class-using-keyof-operator
export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp]

export function asPropArray<T extends object, K extends keyof T, RT extends PropType<T, K>>(
  obj: T
): RT[] {
  return Object.keys(obj).map<RT>((k) => obj[k])
}

/**
 * count the number of filters selected across all filter options
 *
 * @param allSelectedFilters - contains only selected filters, not all filter options
 */
export function countResponsiveSelectedFilters<FilterOptions extends object>(
  allSelectedFilters: FilterOptions
) {
  return asPropArray(allSelectedFilters).reduce<number>((count, obj) => {
    if (obj && isFilterOptionItem(obj) && obj.length > 0) return count + 1
    if (obj && isDateFilterOptionItem(obj) && 'to' in obj && 'from' in obj) return count + 1
    if (obj && isRangeFilterOptionItem(obj) && ('low' in obj || 'high' in obj)) return count + 1
    if (obj && isTextboxFilterOptionItem(obj) && 'value' in obj) return count + 1
    if (obj && isFilterOptionCollection(obj) && obj.length > 0) return count + 1
    if (obj && isStringArray(obj)) return count + 1
    if (obj && isDynamicFilterOptionItem(obj)) {
      return count + obj.map((o) => o.options && o.options.length > 0).length
    }
    return count
  }, 0)
}

export interface FiltersWithProps {
  filter: React.ReactNode
  props: AnyFilterProps
}

export interface FiltersComponentProps<T extends Record<string, any>> extends FiltersProps<T> {
  anyIsSelected: boolean
  filtersWithProps: FiltersWithProps[]
  resetSelectedFilters: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function countSelectedFilters(filtersWithProps: FiltersWithProps[]) {
  return !filtersWithProps
    ? 0
    : filtersWithProps.reduce<number>((count, next) => {
        if (
          isDateFilterProps(next.props) &&
          next.props.value &&
          next.props.value.to &&
          next.props.value.from
        ) {
          return count + 1
        }
        if (
          isRangeFilterProps(next.props) &&
          next.props.value &&
          (next.props.value.low || next.props.value.high)
        ) {
          return count + 1
        }
        if (
          isCheckboxFilterProps(next.props) &&
          next.props?.values &&
          next.props.values.length > 0
        ) {
          return count + 1
        }

        return count
      }, 0)
}

export function getFiltersWithProps(filters: ReactNode) {
  const cleaned: Array<{
    filter: ReactPortal | ReactElement<unknown>
    props: AnyFilterProps
  }> = []
  Children.forEach(filters, (filter) => {
    if (isValidElement(filter)) {
      cleaned.push({ filter, props: filter.props as AnyFilterProps })
    }
  })
  return cleaned
}
