import { useQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { customPagesQueryKeys } from './customPagesQueryKey'

export default function useGetStaffCustomPagesList() {
  return useQuery({
    queryKey: customPagesQueryKeys.listStaff(),
    queryFn: aggregatorService.listStaffCustomPages,
    select: (data) => data.data,
  })
}
