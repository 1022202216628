import { ReactNode } from 'react'
import { FeatureName } from 'src/__fixtures__/featureConfig'
import { AUTHZ_PERMISSIONS } from 'src/api/bidl/iam/authz-permissions'
import StaffPageCheck from 'src/components/layout/StaffPageCheck'
import StaffPermissionCheck from 'src/components/layout/StaffPermissionCheck'
import { AggregatorConfigCheck } from 'src/components/resource/AggregatorConfigCheck'
import { NoAccountsPageCheck } from 'src/components/resource/NoAccountsPageCheck'

export const CUSTOM_PAGES_FEATURE_NAME: FeatureName = 'customPages'

export default function StaffCustomPagesPageChecks({
  children,
}: Readonly<{ children: ReactNode }>) {
  return (
    <NoAccountsPageCheck>
      <StaffPageCheck>
        <StaffPermissionCheck permission={AUTHZ_PERMISSIONS.CUSTOM_PAGES_MANAGE}>
          <AggregatorConfigCheck
            featureName="Custom Pages"
            configKeyName={CUSTOM_PAGES_FEATURE_NAME}
          >
            {children}
          </AggregatorConfigCheck>
        </StaffPermissionCheck>
      </StaffPageCheck>
    </NoAccountsPageCheck>
  )
}
