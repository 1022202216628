import { Checkbox } from '@bushelpowered/design-system'
import { CheckboxGroup, OverflowStack } from 'src/components/designsystem'
import { BaseFilterProps, FilterOptionItem } from 'src/utils/filters'

export function getCheckboxFilterLabel(selectedValues: string[] | null, defaultLabel: string) {
  const filterCharLimit = 30

  if (!selectedValues || selectedValues.length === 0) return defaultLabel

  if (selectedValues.length === 1) {
    return selectedValues[0]
  } else {
    // the goal is to keep the total under the char limit without cutting
    // in the middle of a value and then, if there are more, add "..."
    return selectedValues.reduce((acc, value, i) => {
      const newDisplay = acc + ` \u2022 ${value}`
      if (newDisplay.length < filterCharLimit) {
        return newDisplay
      } else if (i === selectedValues.length - 1) {
        return acc + ' ...'
      }
      return acc
    })
  }
}

export interface CheckboxFilterProps<FOI = FilterOptionItem[] | undefined>
  extends BaseFilterProps<FOI> {
  options: FOI
  isScrollable?: boolean
}

export function CheckboxFilter({
  filterId,
  onChange,
  options,
  values = [],
  isScrollable,
}: CheckboxFilterProps) {
  return (
    <CheckboxGroup
      defaultValue={[]}
      key={filterId.toString()}
      onChange={(value) =>
        onChange(
          value
            .map((item) => options?.find((option) => option.id === item))
            .filter((v) => v !== undefined)
        )
      }
      value={values?.map ? values.map((nsv) => nsv.id) : undefined}
    >
      <OverflowStack p={4} isScrollable={isScrollable}>
        {options?.map((option) => {
          return (
            <Checkbox
              key={`${option.id}`}
              value={option.id}
              width="full"
              data-testid={`${filterId}-checkbox-${option.name}`}
              _notLast={{ mb: 2 }}
            >
              {option.name}
            </Checkbox>
          )
        })}
      </OverflowStack>
    </CheckboxGroup>
  )
}
