import ky from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

const { slug, token } = getWindowNextDataProps()

export let analyticsServiceClient = ky.create({
  prefixUrl: ENV.ANALYTICS_SERVICE_API,
  retry: 0,
  timeout: 120000,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
    'App-Company': slug ?? undefined,
  },

  hooks: {
    beforeRequest: [handleRefreshTokenIfExpired],
    afterResponse: [
      async (_request, _options, response) => {
        if (!response.ok) {
          try {
            const body = await response.text()

            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
              body,
            })

            response.payload = JSON.parse(body)
          } catch (err) {
            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
            })
          }
        }

        return response
      },
    ],
  },
})

export const updateAnalyticsClient = (config = {}) => {
  analyticsServiceClient = analyticsServiceClient.extend(config)
}

export const updateAnalyticsToken = (token: string | null | undefined) =>
  updateAnalyticsClient({ headers: { Authorization: token ? `Bearer ${token}` : undefined } })
