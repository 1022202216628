import { FilterFooter } from 'src/components/designsystem/filters/FilterFooter'

interface FooterProps {
  handleClear?: () => void
  handleDone?: () => void
}

export default function Footer({ handleClear, handleDone }: FooterProps) {
  return <FilterFooter onClear={() => handleClear?.()} onSet={() => handleDone?.()} />
}
