import { useEffect } from 'react'
import { SimpleGrid, Stack } from 'src/components/designsystem'
import { useGetStaffCustomPageById } from 'src/data/queries/custom-pages'
import FileListSection from './FileListSection'
import HeaderImageSection from './HeaderImageSection'
import SectionHeading from './SectionHeading'
import MetaSection from './MetaSection'
import ParagraphsSection from './ParagraphsSection'

export default function ExpandedListItem({
  pageId,
  onClose,
}: Readonly<{ pageId: string; onClose: () => void }>) {
  const { data, isLoading, isError } = useGetStaffCustomPageById(pageId)

  useEffect(() => {
    if (isError) {
      onClose()
    }
  }, [isError, onClose])

  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={{ base: 6, lg: 8 }} pt={6}>
      <Stack gap={6}>
        <SectionHeading isLoading={isLoading}>Setup</SectionHeading>
        <MetaSection
          isLoading={isLoading}
          isPublic={data?.isPublic}
          updatedAt={data?.updatedAt}
          updatedBy={data?.updatedBy}
          publishedAt={data?.publishedAt}
        />
        <SectionHeading isLoading={isLoading}>Content</SectionHeading>
        <HeaderImageSection isLoading={isLoading} headerImageFileLink={data?.headerImageFileLink} />
        <ParagraphsSection isLoading={isLoading} paragraphs={data?.paragraphs} />
      </Stack>
      <Stack gap={6}>
        <FileListSection isLoading={isLoading} files={data?.files} />
      </Stack>
    </SimpleGrid>
  )
}
