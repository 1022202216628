import useStaffUserSearchPage from 'src/components/admin/staff-user-search/useStaffUserSearchPage'
import { useAuth } from 'src/auth'
import { useConfig } from './config'
import { usePaymentCompanyWalletInfo, usePaymentWalletStatus } from './queries'
import { useIsAnyPaymentsFeatureEnabled } from './useIsAnyPaymentsFeatureEnabled'
import useBushelId from './useBushelId'

function useUserWalletInfo(
  bushelId: string | undefined,
  { enabled = true }: { enabled?: boolean } = {}
) {
  const { data, ...query } = usePaymentWalletStatus(
    { bushelId: bushelId ?? '' },
    { enabled: !!bushelId && enabled }
  )
  const userWalletInfo = data?.userWalletInfo
  const isOnboarded = data?.isOnboarded ?? false
  const isTransactable = data?.isTransactable ?? false

  return {
    isOnboarded,
    isTransactable,
    userWalletInfo,
    ...query,
  }
}

export function useSelectedUserWalletInfo() {
  const { selectedUser } = useStaffUserSearchPage()
  const bushelId = selectedUser?.accounts?.[0]?.bushel_id
  return useUserWalletInfo(bushelId)
}

export function useSessionUserWalletInfo(queryOptions?: { enabled: boolean }) {
  const bushelId = useBushelId()
  const isAnyPaymentsFeatureEnabled = useIsAnyPaymentsFeatureEnabled()

  return useUserWalletInfo(bushelId, { enabled: isAnyPaymentsFeatureEnabled, ...queryOptions })
}

export function useCurrentCompanyWalletInfo() {
  const { slug } = useAuth()
  const { isFeatureEnabled } = useConfig()
  const isPaymentsReceivableFeatureEnabled = isFeatureEnabled('payments_receivable')
  const isPaymentsPayableFeatureEnabled = isFeatureEnabled('payments_payable')
  const isReceivableOrPayableEnabled =
    isPaymentsReceivableFeatureEnabled || isPaymentsPayableFeatureEnabled

  return usePaymentCompanyWalletInfo({
    isPaymentsEnabled: isReceivableOrPayableEnabled,
    slug,
  })
}
