import { useCallback, useMemo } from 'react'
import { getServerSession as getServerSessionReal } from 'next-auth'
import {
  SignInAuthorizationParams,
  signIn,
  signOut as nextAuthSignOut,
  useSession,
} from 'next-auth/react'
import { useAuthContext } from 'src/auth/auth-context'
import { AuthProviders as AuthProvidersReal, parseJwt } from 'src/auth/keycloak'
import {
  AuthProviders as AuthProvidersMock,
  getServerSession as getServerSessionMock,
} from 'src/auth/mocks'
import ENV from 'src/utils/env'
import { logger } from 'src/utils/logger'
import { useConfig } from 'src/data/config'
import getClient from 'src/utils/clients/get-client'
import { useToast } from '@chakra-ui/react'
import { keycloakService } from 'src/api/keycloak/KeycloakService'

/* istanbul ignore next */
const getServerSession = ENV.MOCK_MODE ? getServerSessionMock : getServerSessionReal
/* istanbul ignore next */
const AuthProviders = ENV.MOCK_MODE ? AuthProvidersMock : AuthProvidersReal

export function isValidPersistableSlug(slug: string) {
  return ['auth', 'welcome', '_next'].includes(slug) === false
}

const useAuth = () => {
  const authCtx = useAuthContext()
  const { getConfigValue } = useConfig()
  const { status, data: session, update: refreshSession } = useSession()

  const loading = status === 'loading'
  const initialized = status !== 'loading'
  const authenticated = status === 'authenticated' && !!session?.accessToken
  const token = session?.accessToken ?? ''
  const user = authCtx?.user
  const slug = authCtx?.slug
  const showRegisterOptionOnLogin = getConfigValue('show_register_option_on_login')
  const isNativeApp = getClient().isNativeApp
  const toast = useToast()

  const signOutOfThisDevice = useCallback(() => {
    return nextAuthSignOut({
      callbackUrl: [window?.location.origin, slug, 'auth', 'check'].join('/'),
    })
  }, [slug])

  return {
    loading,
    initialized,
    authenticated,
    slug,
    token,
    user,
    setUser: authCtx?.setUser,
    session,
    refreshSession,
    hasPermission: useCallback(
      (permission: UserPermission | UserPermission[]) => {
        // Need to manually check ENV.MOCK_MERCH_MODE due to MSW2 issue preventing server side context hydration
        return (
          ENV.MOCK_MERCH_MODE ||
          (Array.isArray(permission)
            ? permission.some((perm) => user?.permissions?.includes(perm))
            : user?.permissions?.includes(permission))
        )
      },
      [user]
    ),
    isStaff: useMemo(() => user?.user_type === 'staff', [user]),
    // clearAuth: useCallback(() => clearToken(), [clearToken]),
    clearAuth: useCallback(() => {
      logger.debug({ message: '[auth/index] clearAuth - idk what this needs to do now' })
    }, []),

    handleNextAuthSignIn: useCallback(
      (callbackUrl?: string) => {
        const loginCallbackUrl =
          callbackUrl ?? [window.location.origin, slug, 'auth', 'check'].join('/') + `?post_login=1`

        signIn('keycloak', { callbackUrl: loginCallbackUrl }, {
          company_slug: slug,
          ...(showRegisterOptionOnLogin && { scope: 'openid register' }),
          show_cancel_button: isNativeApp ? 'true' : 'false',
        } as SignInAuthorizationParams)
      },
      [slug, showRegisterOptionOnLogin, isNativeApp]
    ),

    handleNextAuthSignOut: async () => {
      try {
        await signOutOfThisDevice()
        toast({ title: 'This device has been signed out.', status: 'success' })
      } catch (error) {
        toast({ title: 'Unable to sign out.', status: 'error' })
      }
    },

    signOutOfAllDevices: async () => {
      try {
        await keycloakService.clearAllSessions()
        await signOutOfThisDevice()
        toast({ title: 'All devices have been signed out.', status: 'success' })
      } catch (error) {
        toast({ title: 'Unable to sign out.', status: 'error' })
      }
    },
  }
}

export { AuthProviders, useAuth, getServerSession, parseJwt }
