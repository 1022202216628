import { Button, Fade, Flex, HStack, ShareIcon, useBreakpoint } from 'src/components/designsystem'
import { ContractFilters } from './ContractFilters'
import { useFilters } from 'src/data/filters'
import { useContractFilterOptions } from 'src/data/queries'
import { UseExpandableSearchButtonProps } from 'src/components/shared/useExpandableSearchButton'
import ExpandableSearchButton from 'src/components/shared/ExpandableSearchButton'

type ContractsFilterQuery = ReturnType<typeof useContractFilterOptions>
type FiltersHook = ReturnType<typeof useFilters>

type ContractHeaderProps = {
  filterOptions: ContractsFilterQuery['data']
  selectedFilters: FiltersHook['selectedFilters']
  setAllSelectedFilters: FiltersHook['setAllSelectedFilters']
  setFilterById: FiltersHook['setFilterById']
  showModal: () => void
  isExportDisabled: boolean
  isFiltersLoading: boolean
  searchProps?: {
    onSearchChange: (search: string) => void
    expandableSearchButtonProps: UseExpandableSearchButtonProps
  }
}

export default function ContractHeader({
  filterOptions,
  selectedFilters,
  setAllSelectedFilters,
  setFilterById,
  showModal,
  isExportDisabled,
  isFiltersLoading,
  searchProps,
}: ContractHeaderProps) {
  const { isMobile } = useBreakpoint()

  const shouldShowFilters = !searchProps?.expandableSearchButtonProps.isExpanded

  return (
    <Flex
      h={10}
      mt={[2, null, 4, 8]}
      justify="space-between"
      alignItems="center"
      gap={{ base: 1, md: 4 }}
    >
      <HStack position="relative" flexGrow={1}>
        {searchProps !== undefined && (
          <ExpandableSearchButton
            placeholder="Search by contract number"
            isLoading={isFiltersLoading}
            onChange={searchProps.onSearchChange}
            {...searchProps.expandableSearchButtonProps}
          />
        )}

        {shouldShowFilters && (
          <Fade
            in={shouldShowFilters}
            delay={{
              enter: searchProps !== undefined ? 0.3 : 0,
            }}
          >
            <ContractFilters
              filterOptions={filterOptions}
              allSelectedFilters={selectedFilters}
              setAllSelectedFilters={setAllSelectedFilters}
              setFilterById={setFilterById}
              isLoading={isFiltersLoading}
            />
          </Fade>
        )}
      </HStack>

      <Button
        variant={isMobile ? 'ghost' : 'primary'}
        isDisabled={isExportDisabled}
        onClick={showModal}
        leftIcon={<ShareIcon fontSize="xl" />}
      >
        Export
      </Button>
    </Flex>
  )
}
