import { useMemo } from 'react'
import * as api from 'src/api'
import { SettlementsResponse } from 'src/api/settlements'
import useStaffUserSearchPage from 'src/components/admin/staff-user-search/useStaffUserSearchPage'
import { SettlementFiltersForQuery } from 'src/components/settlements/SettlementFilters'
import { useMerchandiser } from 'src/data/merchandiser'
import {
  UseInfiniteQueryOptionsTyped,
  flattenPages,
  getNextPageParam,
  useInfiniteQueryTyped,
  useQueryTyped,
} from 'src/data/queries/utils'

export function useSettlementFilterOptions() {
  const { selectedUserIdpId } = useMerchandiser()

  return useQueryTyped({
    queryKey: ['settlement-filter-options', { selectedUserIdpId }],
    queryFn: () => api.centre.settlementFilterOptions(),
    staleTime: Infinity,
  })
}

export function useSettlements({
  filter,
}: {
  filter?: SettlementFiltersForQuery
} = {}) {
  const { selectedUserIdpId } = useMerchandiser()

  const query = useInfiniteQueryTyped({
    queryKey: ['settlement-list', { selectedUserIdpId, filter }],
    queryFn: ({ pageParam }) => api.centre.settlements({ filter, page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage: 'Unable to retrieve settlements, please refresh the page to try again.',
    },
    getNextPageParam,
  })

  const mergedPageData = useMemo(() => flattenPages(query.data), [query.data])

  return { query, data: mergedPageData }
}

export function useSettlementDetail({
  id,
  enabled,
}: {
  id: string | number | undefined
  enabled: boolean
}) {
  return useQueryTyped({
    queryKey: ['settlement-detail', { id }],
    queryFn: async () => (id ? api.centre.settlement(id) : null),
    enabled,
    staleTime: Infinity,
  })
}

export function useSettlementAssociatedContracts({ id }: { id: number }) {
  return useQueryTyped({
    queryKey: ['settlement-associated-contracts', { id }],
    queryFn: async () => (id ? api.centre.settlementAssociatedContracts(id) : null),
    staleTime: Infinity,
  })
}

export function useSettlementAssociatedTickets({ id }: { id: number }) {
  return useQueryTyped({
    queryKey: ['settlement-associated-tickets', { id }],
    queryFn: async () => (id ? api.centre.settlementAssociatedTickets(id) : null),
    staleTime: Infinity,
  })
}

/**
 * Returns Settlements scoped to a specific user. An optional userId is provided, otherwise it
 * will use the currently selected user from the customer search compoennt.
 * @param filter - Optional filter to apply to the settlements
 * @param userId - Optional userId to scope the settlements to
 * @param options - Optional options to pass to the useInfiniteQuery hook
 * @returns InfiniteQueryResult
 */
export function useStaffSettlements(
  {
    filter,
    userId,
  }: {
    filter?: SettlementFiltersForQuery
    userId?: string | number
  } = {},
  options: UseInfiniteQueryOptionsTyped<SettlementsResponse, SettlementFiltersForQuery> = {}
) {
  const { selectedUser } = useStaffUserSearchPage()
  const selectedUserId = userId ?? selectedUser?.id

  if (!selectedUserId) {
    throw new Error('No user selected for staff settlements query.')
  }

  const query = useInfiniteQueryTyped({
    queryKey: ['staff-settlements-list', { selectedUserId, filter }],
    queryFn: ({ pageParam }) => api.centre.settlements({ filter, page: pageParam }),
    initialPageParam: 1,
    meta: {
      errorMessage:
        'Unable to retrieve settlements for that customer, please refresh the page to try again.',
    },
    getNextPageParam,
    staleTime: 1000 * 60 * 5, // 15 minutes
    ...options,
  })

  const mergedPageData = useMemo(() => flattenPages(query.data), [query.data])

  return {
    ...query,
    data: mergedPageData,
  }
}
