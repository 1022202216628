import { KyInstance } from 'ky'
import { parseCookies } from 'nookies'
import * as api from 'src/api'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { isForceLogoutResponse } from 'src/auth/keycloak'
import { ENV, flagr, logger } from 'src/utils'
import { isTempDeniedSlug } from 'src/utils/temp-denied-slug'

export enum InitStatus {
  OK,
  NO_AUTH,
  NO_COMPANY,
  ERROR,
  FORCE_LOGOUT,
}

const ERROR_MESSAGES = {
  [InitStatus.NO_AUTH]: 'Not Authenticated',
  [InitStatus.NO_COMPANY]: 'Unknown Company',
  [InitStatus.ERROR]: 'There was a problem',
  [InitStatus.FORCE_LOGOUT]: 'Authentication expired, please log in',
}

export interface InitData {
  status: InitStatus
  error: string
  user: Me
  config: AppConfig
  flags: flagr.GlobalFlagsResults
}

interface GetInitDataArgs {
  hasAccessCodeBypass?: boolean
  slug: string
  token?: string | null
  isServerSideClientIsolationEnabled: boolean
  selectedUserId?: string
}

export async function getInitData({
  token,
  hasAccessCodeBypass = false,
  slug,
  isServerSideClientIsolationEnabled,
  selectedUserId,
}: GetInitDataArgs): Promise<InitData> {
  console.log('isServerSideClientIsolationEnabled :>> ', isServerSideClientIsolationEnabled)
  let resultsArray: Awaited<ReturnType<typeof fetchInitDataUsingGoodIsolatedClient>>
  if (isServerSideClientIsolationEnabled) {
    resultsArray = await fetchInitDataUsingGoodIsolatedClient({
      token,
      slug,
      selectedUserId,
    })
  } else {
    resultsArray = await fetchInitDataUsingBadSharedClient({
      hasToken: !!token,
      slug,
    })
  }

  const [
    //
    [user, userError],
    [config, configError],
    [flags, flagrError],
  ] = resultsArray

  console.log('resultsArray :>> ', resultsArray)
  const status = await getInitStatus({
    slug,
    token,
    hasAccessCodeBypass,
    userError,
    configError,
    flagrError,
  })

  const error = ERROR_MESSAGES[status]

  return { status, error, user, config, flags }
}

type GetInitStatusArgs = Omit<GetInitDataArgs, 'isServerSideClientIsolationEnabled'> & {
  userError?: api.HTTPError
  configError?: api.HTTPError
  flagrError?: api.HTTPError
}

async function getInitStatus({
  slug,
  token,
  hasAccessCodeBypass,
  userError,
  configError,
  flagrError,
}: GetInitStatusArgs) {
  if (userError || configError || flagrError) {
    // One or more init requests failed

    if (isUnknownCompany(configError)) {
      // Config request returned 404 - unknown slug
      return InitStatus.NO_COMPANY
    }

    if (hasAuthExpired(!!token, userError)) {
      // Supplied access token no longer valid
      return InitStatus.NO_AUTH
    }

    const isForceLogout = await hasForceLogoutError(
      [userError?.response, configError?.response].filter(Boolean)
    )

    if (isForceLogout) {
      return InitStatus.FORCE_LOGOUT
    }

    // Generic unexpected error
    return InitStatus.ERROR
  }

  if (hasDeniedSlug(slug, !!hasAccessCodeBypass)) {
    return InitStatus.NO_COMPANY
  }

  return InitStatus.OK
}

function isUnknownCompany(configError: api.HTTPError | undefined) {
  return configError?.response?.status === 404
}

function hasAuthExpired(hasToken: boolean, userError: api.HTTPError | undefined) {
  return hasToken && userError?.response?.status === 401
}

/**
 * There are still some slug we want to disallow into the portal
 * We removed the flagr app-enablement check, and we do not respect "web app enabled" company config
 * This is here as a temporary stop-gap until we come up with a long-term proper mechanism
 * See: https://bushelpowered.slack.com/archives/G013T1DFXRA/p1662498159110169
 */
function hasDeniedSlug(slug: string, hasAccessCodeBypass: boolean) {
  return ENV.BUSHEL_ENVIRONMENT === 'prod' && isTempDeniedSlug(slug) && !hasAccessCodeBypass
}

/**
 * This fetches up to three things in parallel, which are needed to set up the app.
 *  - The logged-in user's auth/me call, which validates their access token and account(s)
 *  - The given slug's company config, which validates the slug and supplies app config data
 *  - A Flagr batch request for all globally-known flags the app may use
 *
 * This function should never throw, so we use tuple-style error handling which allows the errors
 * to be returned for examination.
 */
async function fetchInitDataUsingBadSharedClient({
  hasToken,
  slug,
}: {
  hasToken: boolean
  slug: string
}) {
  console.log('starting fetchInitDataUsingBadSharedClient ')
  return Promise.all([
    hasToken
      ? // Don't try to load auth/me if there's no token
        api.centre
          .me()
          .then((data) => [data])
          .catch((err) => [null, err])
      : [null, null],

    api.centre
      .config()
      .then((data) => [data])
      .catch((err) => [null, err]),

    flagr
      .loadGlobalFlags({ companySlug: slug })
      .then((data) => [data])
      .catch((err) => [null, err]),
  ])
}

async function fetchInitDataUsingGoodIsolatedClient({
  token,
  slug,
  selectedUserId,
}: {
  token?: string | null
  slug: string
  selectedUserId?: string
}) {
  const installationId = parseCookies(null)?.['bushel-web-installation-id']
  const centreServerClient = api.centreClientFactory({
    slug,
    token,
    installationId,
    queryUserId: selectedUserId,
  })

  console.log('starting fetchInitDataUsingGoodIsolatedClient>>> ', {
    installationId,
    slug,
    hasToken: !!token,
    selectedUserId,
  })

  return Promise.all([
    token
      ? centreServerClient
          .get('api/v1/auth/me')
          .json<{ data: Me }>()
          .then((data) => [data.data])
          .catch((err) => [null, err])
      : [null, null],

    centreServerClient
      .get('api/v1/app-config')
      .json()
      .then((data: any) => [data.data])
      .catch((err) => [null, err]),

    flagr
      .loadGlobalFlags({ companySlug: slug })
      .then((data) => [data])
      .catch((err) => [null, err]),
  ])
}

async function hasForceLogoutError(responses: any[]) {
  for (const response of responses) {
    if (await isForceLogoutResponse(response)) return true
  }

  return false
}

/**
 * Special handling of auth me, using isolated client if necessary.
 * Detects changes in the auth header and report them to datadog.
 * Logging here is a temporary measure to help us understand the impact of client isolation.
 * @param shouldIsolate - whether to use the isolated client
 * @param centreServerClient - the shared client
 * @param token - the auth token
 * @returns the auth me data
 */
async function getAuthMeData(shouldIsolate: boolean, centreServerClient: KyInstance, slug: string) {
  const sharedClientAuthMeRes = await api.centreClient.get('api/v1/auth/me')

  if (!shouldIsolate) return sharedClientAuthMeRes.json<{ data: Me }>()

  const isolatedClientAuthMeRes = await centreServerClient.get('api/v1/auth/me')
  const isolatedClientUserData = await isolatedClientAuthMeRes.json<{ data: Me }>()
  const sharedClientUserData = await sharedClientAuthMeRes.json()

  if (
    sharedClientAuthMeRes.headers.get('Authorization') !==
    isolatedClientAuthMeRes.headers.get('Authorization')
  ) {
    logger.warn({
      message: '[Auth Mismatch]: Shared and isolated client headers differ server-side',
      context: {
        isolatedClientSlug: isolatedClientAuthMeRes.headers.get('App-Company'),
        sharedClientSlug: sharedClientAuthMeRes.headers.get('App-Company'),
        sharedClientUserData,
        isolatedClientUserData,
      },
    })
  }

  if (sharedClientAuthMeRes.headers.get('App-Company') !== slug) {
    logger.warn({
      message: '[Slug Mismatch]: Shared and isolated client slugs differ server-side',
      context: {
        slug,
        sharedClientSlug: sharedClientAuthMeRes.headers.get('App-Company'),
        sharedClientUserData,
        isolatedClientUserData,
      },
    })
  }

  return isolatedClientUserData
}

function fetchAppConfig(shouldIsolate: boolean, centreServerClient: KyInstance) {
  if (!shouldIsolate) return api.centre.config()
  return centreServerClient.get('api/v1/app-config').json()
}
