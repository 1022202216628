import { Heading } from '@bushelpowered/design-system'
import { useFieldArray } from 'react-hook-form'
import { Button, CircleAddIcon, Stack, Text } from 'src/components/designsystem'
import HeaderImageFormCard from './HeaderImageFormCard'
import ParagraphFormCard from './ParagraphFormCard'
import { type CustomPageFormValues, emptyParagraph, MAX_PARAGRAPHS } from './useStaffCustomPageForm'

export default function ContentSection() {
  const paragraphs = useFieldArray<CustomPageFormValues, 'paragraphs'>({ name: 'paragraphs' })
  return (
    <Stack gap={6}>
      <Heading type="h6">Content</Heading>
      <Stack gap={4}>
        <Text textStyle="preTitle">Header Image (Optional)</Text>
        <HeaderImageFormCard />
      </Stack>
      <Stack gap={2}>
        <Text textStyle="preTitle">Paragraphs</Text>
        <Text>Five paragraph max. Paragraphs will display in the order they are added.</Text>
      </Stack>
      <Stack>
        {paragraphs.fields.map((item, index) => (
          <ParagraphFormCard
            key={item.id}
            index={index}
            onClear={() =>
              index === 0 ? paragraphs.update(index, emptyParagraph) : paragraphs.remove(index)
            }
          />
        ))}
      </Stack>
      <Button
        leftIcon={<CircleAddIcon />}
        variant="link"
        ml={4}
        alignSelf="flex-start"
        isDisabled={paragraphs.fields.length >= MAX_PARAGRAPHS}
        onClick={() => paragraphs.append(emptyParagraph)}
      >
        Add paragraph
      </Button>
    </Stack>
  )
}
