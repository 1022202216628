import { bidlClient } from 'src/api/bidl/client'

export const tenantServiceRequests = {
  getAllStaffUsers: (tenantId: string, options?: GetAllStaffUserOptions) =>
    bidlClient
      .post('api/platform/user/v1/GetAllStaffUsers', {
        json: options,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetAllStaffUserResponse>(),

  getTenantUserByEmail: ({ tenantId, email }: GetTenantUserByEmailRequest) =>
    bidlClient
      .post('api/platform/user/v1/GetTenantUserByEmail', {
        json: { email },
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetTenantUserByEmailResponse>(),

  createOrUpdateStaffUsers: ({ tenantId, users }: CreateOrUpdateStaffUsersRequest) =>
    bidlClient
      .post('api/platform/user/v1/CreateOrUpdateStaffUsers', {
        json: { users },
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<CreateOrUpdateStaffUsersResponse>(),

  getAllLocations: (tenantId: string, request?: GetAllLocationsRequest) =>
    bidlClient
      .post('api/platform/location/v1/GetAllLocations', {
        json: request,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetAllLocationsResponse>(),
}
