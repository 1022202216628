import { createIcon } from '@chakra-ui/react'

export const PaperclipIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
  },
  displayName: 'PaperclipIcon',
  viewBox: '0 0 12 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.19 1.236A3.935 3.935 0 0 1 8.641 4.91l-.006 8.84v.005a2.629 2.629 0 0 1-5.257 0V7a.75.75 0 1 1 1.5 0v6.737a1.129 1.129 0 0 0 2.257 0L7.141 4.9v-.004a2.435 2.435 0 1 0-4.869 0v8.918a3.727 3.727 0 1 0 7.454 0V5.488a.75.75 0 0 1 1.5 0v8.31a5.229 5.229 0 0 1-8.95 3.739 5.227 5.227 0 0 1-1.504-3.74V4.91A3.935 3.935 0 0 1 3.19 1.236Z"
      fill="currentColor"
    />
  ),
})
