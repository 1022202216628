import { useQuery } from '@tanstack/react-query'
import { centreClient, type HTTPError, centre } from 'src/api'
import { useAuth } from 'src/auth'
import { CashBidResponse } from 'src/api/cash-bids/types/CashBidResponse'

export const CASH_BID_REFETCH_INTERVAL = 30000

export default function useCashBidsLocations() {
  const { token } = useAuth()
  const centreClientExtended = centreClient.extend({
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })
  return useQuery<CashBidResponse, HTTPError>({
    queryKey: ['cash-bid-locations', centreClientExtended],
    queryFn: () => {
      return centre.cashBidsLocations(centreClientExtended)
    },
    staleTime: CASH_BID_REFETCH_INTERVAL,
    refetchInterval: CASH_BID_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    meta: {
      errorMessage: 'Unable to retrieve locations, please refresh the page to try again.',
    },
  })
}
