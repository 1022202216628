import { ReactNode } from 'react'
import { GenericNonIdealState } from 'src/components/designsystem'
import { LoadingState } from 'src/components/resource/NonIdealStates'
import useAggregatorConfigCheck from './useAggregatorConfigCheck'

type AggregatorConfigCheckProps = Readonly<{
  configKeyName: string
  featureName: string
  version?: string
  children: ReactNode
}>

export function AggregatorConfigCheck({
  configKeyName,
  featureName,
  version,
  children,
}: AggregatorConfigCheckProps) {
  const { isLoading, isConfigEnabled, isVersionMatching } = useAggregatorConfigCheck(
    configKeyName,
    version
  )

  if (isLoading) {
    return <LoadingState />
  }

  if (!isConfigEnabled || !isVersionMatching) {
    return (
      <GenericNonIdealState
        isVerticallyCentered
        illustration="tumbleweed"
        illustrationAltText={`${featureName} feature not enabled`}
        header={`${featureName} feature not enabled`}
        subHeader={`Unsure why ${featureName} is disabled? Contact your company for more information.`}
      />
    )
  }

  return children
}
