import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { create } from 'zustand'
import { useGetAccountsByUserId, useUserSearch } from 'src/data/queries'

import { trackEvent } from 'src/utils/analytics'
import * as api from 'src/api'

type MerchandiserSearchStore = {
  isSearching: boolean
  openSearch: () => void
  closeSearch: () => void
}

const useSearch = create<MerchandiserSearchStore>()((set) => ({
  isSearching: false,
  openSearch: () => {
    set({ isSearching: true })
    trackEvent('Merchandiser', 'Start Search')
  },
  closeSearch: () => {
    set({ isSearching: false })
    trackEvent('Merchandiser', 'End Search')
  },
}))

///////////////////////////////////////////////////////////////////////////////////////////////////

export function useMerchandiser() {
  const { pathname, query, push } = useRouter()
  const queryClient = useQueryClient()
  const { isSearching, openSearch, closeSearch } = useSearch()

  //this is very fragile/crappy, maybe we could search the pathname for a match in our list of viable features instead?
  //leaving it the way it was for now as it works for most situations
  const featureFromPathname = pathname
    .replace('/[company]/', '')
    .replace('[userId]/', '')
    .replace('/[id]', '')
  const comingFromCRM = featureFromPathname === 'staff/crm'

  const { company, userId, feature } = {
    company: undefined,
    userId: undefined,
    feature: featureFromPathname,
    // Actual query params should override ^^ defaults
    ...query,
  }

  const resourceId = comingFromCRM ? null : query.id
  const selectedUserQuery = useUserSearch({ userId, enabled: !!userId })
  const selectedUser = getSelectedUser(selectedUserQuery)
  const selectedAccountsQuery = useGetAccountsByUserId({ userId })

  return {
    userId,
    selectedUserId: userId,
    selectedUser,
    isUnknownId: selectedUserQuery.isSuccess && !selectedUser,
    hasSelectedUser: !!selectedUser,
    selectedUserIdpId: selectedUser?.idp_id ?? selectedUser?.phone,
    isSearching,
    openSearch,
    closeSearch,

    selectedAccounts: selectedAccountsQuery.data ?? [],

    handleSelectUser: useCallback(
      (searchUser: SearchUser, specifiedFeature?: string) => {
        api.clearCentreAppHeaderImpersonation()

        if (searchUser.userId) {
          api.updateCentreAppHeader('X-Impersonate-Id', String(searchUser.userId))
          api.updatePaymentsServiceHeader('X-Impersonate-Id', String(searchUser.userId))
        }

        // Preload react-query cache with this user's data
        queryClient.setQueryData(makeUserQueryKey(searchUser), { data: [searchUser] })
        updateUrlForSelectUser({
          push,
          company,
          userId: searchUser.userId,
          feature: specifiedFeature ?? feature,
          resourceId,
        })
        trackEvent('Merchandiser', 'Select User')
        closeSearch()
      },
      [queryClient, push, company, feature, closeSearch]
    ),

    handleClearUser: useCallback(() => {
      api.clearCentreAppHeaderImpersonation()
      api.clearPaymentsServiceHeaderImpersonation()
      updateUrlForClearUser({ push, company, feature, resourceId })
      trackEvent('Merchandiser', 'Clear User')
    }, [push, company, feature]),

    selectedUserQuery: selectedUserQuery,
    selectedAccountsQuery,
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function makeUserQueryKey({ userId }: SearchUser) {
  return ['user-search', { userId: String(userId) }]
}

function getSelectedUser(query: ReturnType<typeof useUserSearch>) {
  return query?.data?.data?.[0] || null
}

function updateUrlForSelectUser({ push, company, userId, feature, resourceId }) {
  push(['', company, encodeURIComponent(userId), feature, resourceId].join('/'), undefined, {
    shallow: true,
  })
}

function updateUrlForClearUser({ push, company, feature, resourceId }) {
  push(['', company, feature, resourceId].join('/'))
}
